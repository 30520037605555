import { useEffect, useState } from 'react';

// Will not be re-created because it is declared out of Component function
let globalState = {};
let listeners = [];
let actions = {};

export const useStore = function () {
    const setState = useState(globalState)[1];

    // this function will get actionIdentifier as argument and create newState
    const dispatch = async function (actionIdentifier, payload) {
        const newState = await actions[actionIdentifier](globalState, payload);
        globalState = { ...globalState, ...newState };

        for (const listener of listeners) {
            listener(globalState);
        }
    };

    useEffect(() => {
        // every component that uses this Custom hook gets its own function to setState that is added to listeners[]
        listeners.push(setState);

        // This cleanup function will remove any listeneres that are not our setState
        return () => {
            listeners = listeners.filter((li) => li !== setState);
        };
    }, [setState]);

    return [globalState, dispatch];
};

export const initStore = (userActions, initialState) => {
    if (initialState) {
        globalState = { ...globalState, ...initialState };
    }
    actions = { ...actions, ...userActions };
};
