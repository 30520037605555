import { initStore } from './useStore';

const configureStore = function () {
    const actions = {
        STORE_RSS: function (curState, rssData) {
            const changedRssData = { ...curState.widgetData };
            const rssDataArr = [];

            const formatDate = function (date) {
                return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
            };

            for (let item of rssData) {
                rssDataArr.push(
                    <div key={item.getElementsByTagName('description')[0].innerHTML} className="widget-block">
                        <div className="news-item">
                            <div
                                className="news-widget-image"
                                style={{
                                    background: `url(${item
                                        .getElementsByTagName('media:thumbnail')[0]
                                        .getAttribute('url')}) no-repeat center center/cover`,
                                }}
                            />
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={item.getElementsByTagName('link')[0].innerHTML}
                                style={{ width: '100%', color: 'inherit' }}
                            >
                                <div className="news-text-box">
                                    <b>{item.getElementsByTagName('title')[0].innerHTML}</b>
                                    <p>{item.getElementsByTagName('description')[0].innerHTML}</p>
                                    <small>
                                        {formatDate(new Date(item.getElementsByTagName('pubDate')[0].innerHTML))}
                                    </small>
                                </div>
                            </a>
                        </div>
                    </div>,
                );
            }

            changedRssData.rssData = rssDataArr;
            changedRssData.isRssData = true;
            changedRssData.rssUpdate = new Date().getTime() + 1000 * 60 * 60 * 3;
            console.log('STORE_RSS');
            return { widgetData: changedRssData };
        },
    };
    initStore(actions, {
        widgetData: {
            rssData: null,
            isRssData: false,
            rssUpdate: null,
        },
    });
};

export default configureStore;
