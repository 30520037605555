import { createContext, useState, useEffect, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import { useStore } from '../floor-app/store/useStore';
import _ from 'lodash';



const userSettingsEndpoints = {
    getUserSettings: `https://25uhu3mgnf.execute-api.eu-west-1.amazonaws.com/dev/getusersettings`,
    setUserSettings: `https://25uhu3mgnf.execute-api.eu-west-1.amazonaws.com/dev/setusersettings`,
};



let canSaveToLS = false;
let canLoadDB = true;

const UserSettingsContext = createContext();

export const UserSettingsProvider = ({ children }) => {
    // globalUser
    const isGlobalUser = useStore()[0].userSettings.isGlobal;



    //state
    const [userSettings, setUserSettings] = useState(
        localStorage.getItem('userSettings') !== null && !isGlobalUser
        ? 
        JSON.parse(localStorage.getItem('userSettings')) 
        : 
        {
            email: null,
            activePoster: false,
            activeNote: false,
            backgroundRotationTime: 10,
            activeBackgrounds: [],
            widgets: [],
            isDarkTheme: false,
            presentSettings: { presentTime: { time: 30 }, playStarted: false },
        });

    

    //set LS
    localStorage.getItem('userSettings') === null && localStorage.setItem('userSettings', JSON.stringify(userSettings));
    


    //change settings function
    const changeUserSettings = useCallback((settingKeyValuePair) => {
        setUserSettings({ ...userSettings, ...settingKeyValuePair });
    }, [userSettings]);



    //get user's data from DB call
    const fetchSettingsFromDB = useCallback(async () => {
        try {
            let res = await fetch(userSettingsEndpoints.getUserSettings, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: userSettings.email }),
            });
            if (!res.ok) throw new Error('User Settings from DB failed to fetch');
            let data = await res.json();
            data = {
                email: data.id,
                activePoster: data.activePoster,
                activeNote: data.activeNote,
                backgroundRotationTime: data.backgroundRotationTime,
                activeBackgrounds: data.activeBackgrounds,
                widgets: data.widgets,
                isDarkTheme: data.isDarkTheme,
                presentSettings: data.presentSettings,
            }
            return new Promise((resolve, reject) => {
                resolve({...data});
            })
            
        } catch (error) {
            console.log(error);
        }
    }, [userSettings.email]);



    //compare if settings are different
    const isDataSame = useCallback((oldData, newData) => {
        return _.isEqual(oldData, newData);
    }, []);


    //get user's email
    useEffect(() => {
        if (userSettings.email === null) {
            Auth.currentAuthenticatedUser()
            .then((data) => {
                if (data?.attributes?.email) {changeUserSettings({email: data.attributes.email})}
            });
        }
    }, [changeUserSettings, userSettings.email]);



    //check user's data in DB
    useEffect(() => {
        //global User
        let checkInterval;
        if (userSettings.email && isGlobalUser) {
            canLoadDB && fetchSettingsFromDB()
                .then(dbSettings => {
                    dbSettings && !isDataSame(userSettings, dbSettings) && setUserSettings({...userSettings, ...dbSettings});
                });
            
            !canLoadDB && fetch(userSettingsEndpoints.setUserSettings, { //only save newSettings after initial load
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ...userSettings }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (!data?.id) console.log('Updating Settings failed');
                })
                .catch((error) => {
                    console.log('Updating Settings failed');
                    console.log(error);
                });

            checkInterval = setInterval(() => {
                fetchSettingsFromDB()
                .then(dbSettings => {
                    dbSettings && !isDataSame(userSettings, dbSettings) && setUserSettings({...userSettings, ...dbSettings});
                });
            }, 1000 * 60 * 1)

            canSaveToLS = false;
            canLoadDB = false;

        //local user    
        } else if (userSettings.email && !isGlobalUser) {
            if (!canSaveToLS) {
                !isDataSame(userSettings, JSON.parse(localStorage.getItem('userSetting')))
                &&
                setUserSettings(JSON.parse(localStorage.getItem('userSettings')))
            }
            canSaveToLS && localStorage.setItem('userSettings', JSON.stringify(userSettings));
            canSaveToLS = true;
            canLoadDB = true;
        }

        return () => clearInterval(checkInterval);
    }, [userSettings, isGlobalUser, fetchSettingsFromDB, isDataSame]);



    //return provider
    return (
        <UserSettingsContext.Provider value={{ userSettings, changeUserSettings }}>
            {children}
        </UserSettingsContext.Provider>
    );
};

export default UserSettingsContext;