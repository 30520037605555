import { initStore } from './useStore';

const configureStore = function () {
    const actions = {
        TOGGLE_USERTYPE: function (curState) {
            const updatedUserSettings = { ...curState.userSettings };
            updatedUserSettings.isGlobal = !updatedUserSettings.isGlobal;
            console.log('TOGGLE_USERTYPE');
            return { userSettings: updatedUserSettings };
        },
    };

    initStore(actions, {
        userSettings: {
            isGlobal: false,
        },
    });
};

export default configureStore;
