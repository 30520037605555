import React from 'react';

function AlertScreen(props) {
    return (
        <div className="floor-alert-screen">
            <div className="floor-alert-screen-message">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus auctor vehicula neque, at tempor sem
                mollis ut. Pellentesque laoreet finibus nibh, id consectetur nisl scelerisque ut.
            </div>
        </div>
    );
}

export default AlertScreen;
