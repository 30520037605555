import React, { useState, useContext } from 'react';
import { Dialog, Classes, Button, Label, NumericInput, FormGroup } from '@blueprintjs/core';
import UserSettingsContext from '../../context/userSettingsContext';



const AutoPresent = function (props) {
    const { userSettings, changeUserSettings } = useContext(UserSettingsContext);
    const { presentSettings } = userSettings;
    const [inputNumber, setInputNumber] = useState(presentSettings.presentTime.time);

    const inputValueChangeHandler = function (value) {
        setInputNumber(value);
    };

    const onFormSubmitHandler = function () {
        // await dispatch('UPDATE_TIME', inputNumber);
        const newPresentSettings = { ...presentSettings };
        newPresentSettings.presentTime.time = inputNumber;
        changeUserSettings({ presentSettings: newPresentSettings });
    };

    const onCloseHandler = function () {
        props.toggleAutoPresent();
        // await dispatch('TOGGLE_PLAY');
        const newPresentSettings = { ...presentSettings };
        newPresentSettings.playStarted = !presentSettings.playStarted;
        changeUserSettings({ presentSettings: newPresentSettings });
    };

    return (
        <Dialog
            className={props.isDarkTheme && 'bp3-dark'}
            isOpen={props.isOpen}
            onClose={props.toggleAutoPresent}
            title="Present Options"
        >
            <FormGroup>
                <Label className={Classes.DIALOG_BODY}>
                    <div style={{ marginTop: '1rem' }}>Present slide control(more than 10 sec)</div>
                    <NumericInput
                        placeholder="Enter number(sec)"
                        min={0}
                        value={inputNumber}
                        intent={inputNumber < 10 ? 'warning' : 'none'}
                        minorStepSize={null}
                        stepSize={10}
                        onValueChange={inputValueChangeHandler}
                    />
                    <Button
                        style={{ marginTop: '0.5rem' }}
                        disabled={inputNumber < 10}
                        type="submit"
                        onClick={onFormSubmitHandler}
                    >
                        Submit Time
                    </Button>
                </Label>
            </FormGroup>

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                        className={Classes.DIALOG_CLOSE_BUTTON}
                        disabled={inputNumber < 10}
                        onClick={onCloseHandler}
                    >
                        {presentSettings.playStarted ? 'Stop Presentation' : 'Start Presentation'}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};
export default React.memo(AutoPresent);
