import { Card, Elevation, H2, H6 } from '@blueprintjs/core';
import React from 'react';

const SENSORS = [
    {
        category: 'Temperature',
        location: {
            floor: 'BTV01 / Floor2',
            area: 'Office Area 1',
        },
        value: '22 ℃',
    },
    {
        category: 'Temperature',
        location: {
            floor: 'BTV01 / Floor2',
            area: 'Office Area 2',
        },
        value: '21 ℃',
    },
    {
        category: 'Temperature',
        location: {
            floor: 'BTV01 / Floor2',
            area: 'Office Area 3',
        },
        value: '22 ℃',
    },
    {
        category: 'Humidity',
        location: {
            floor: 'BTV01 / Floor2',
            area: 'Office Area 1',
        },
        value: '41%',
    },
    {
        category: 'Humidity',
        location: {
            floor: 'BTV01 / Floor2',
            area: 'Office Area 2',
        },
        value: '53%',
    },
    {
        category: 'Humidity',
        location: {
            floor: 'BTV01 / Floor2',
            area: 'Office Area 3',
        },
        value: '48%',
    },
    {
        category: 'Pressure',
        location: {
            floor: 'BTV01 / Floor2',
            area: 'Office Area 1',
        },
        value: '1050 hPa',
    },
    {
        category: 'Pressure',
        location: {
            floor: 'BTV01 / Floor2',
            area: 'Office Area 2',
        },
        value: '1069 hPa',
    },
    {
        category: 'Pressure',
        location: {
            floor: 'BTV01 / Floor2',
            area: 'Office Area 3',
        },
        value: '1053 hPa',
    },
    {
        category: 'CO2',
        location: {
            floor: 'BTV01 / Floor2',
            area: 'Office Area 1',
        },
        value: '669 ppm',
    },
    {
        category: 'CO2',
        location: {
            floor: 'BTV01 / Floor2',
            area: 'Office Area 2',
        },
        value: '673 ppm',
    },
    {
        category: 'CO2',
        location: {
            floor: 'BTV01 / Floor2',
            area: 'Office Area 3',
        },
        value: '684 ppm',
    },
    {
        category: 'Lux',
        location: {
            floor: 'BTV01 / Floor2',
            area: 'Office Area 1',
        },
        value: '302 lux',
    },
    {
        category: 'Lux',
        location: {
            floor: 'BTV01 / Floor2',
            area: 'Office Area 2',
        },
        value: '357 lux',
    },
    {
        category: 'Lux',
        location: {
            floor: 'BTV01 / Floor2',
            area: 'Office Area 3',
        },
        value: '584 lux',
    },
    {
        category: 'Temperature',
        location: {
            floor: 'BTV01 / Floor2',
            area: 'Storage 1',
        },
        value: '21 ℃',
    },
];

function SingleSensorWidget() {
    return (
        <>
            {SENSORS.map((sensor) => (
                <div key={sensor.category + sensor.location.area + sensor.location.floor} className="single-sensor-widget">
                    <Card
                        className="single-sensor-widget-card"
                        elevation={Elevation.TWO}
                    >
                        <div className="single-sensor-container">
                            <H6>
                                {sensor.location.floor} | {sensor.location.area} | {sensor.category}
                            </H6>
                            <H2 className="single-sensor-value">{sensor.value}</H2>
                        </div>
                    </Card>
                </div>
            ))}
        </>
    );
}

export default SingleSensorWidget;
