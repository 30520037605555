import { Divider } from '@blueprintjs/core';

import { useEffect } from 'react';

const FILTER_BUTTONS = {
    categories: ['All', 'Temperature', 'Humidity', 'Pressure', 'CO2', 'Lux'],
    floors: ['All', 'BTV01 / Floor2', 'BTV01 / Floor3', 'BTV02 / Floor3'],
    areas: [
        'All',
        'Office Area 1',
        'Office Area 2',
        'Office Area 3',
        'Storage 1',
        'Storage 2',
        'Storage 3',
        'Storage 4',
        'Meeting room 1',
        'Meeting room 2',
        'Meeting room 3',
        'Meeting room 4',
    ],
};

const WidgetSensorsFilter = function () {
    const onClickHandler = function (e) {
        //for now this will set active class on all buttons that were clicked
        e.target.className = 'bp3-button bp3-active';
    };

    // Select all Buttons with All name attribute and make them active. Happens only on Component Load
    useEffect(() => {
        const allButtons = document.getElementsByName('All');
        allButtons.forEach((button) => console.log((button.className = 'bp3-button bp3-active')));
    }, []);

    return (
        <div className="sensor-filter-container">
            <h1>Sensors Filter</h1>
            <div className="sensor-filters">
                <div className="sensor-filter-category">
                    <h2>Category</h2>
                    <div className="sensor-category-button-group">
                        {FILTER_BUTTONS.categories.map((category) => (
                            <button
                                style={{ margin: '2px' }}
                                className={'bp3-button'}
                                name={category}
                                key={category}
                                onClick={onClickHandler}
                            >
                                {category}
                            </button>
                        ))}
                    </div>
                </div>
                <Divider />
                <div className="sensor-filter-location">
                    <h2>Location</h2>
                    <div className="sensor-filter-floor">
                        <h3>Floor</h3>
                        <div className="sensor-floor-button-group">
                            {FILTER_BUTTONS.floors.map((floor) => (
                                <button
                                    style={{ margin: '2px' }}
                                    className={'bp3-button'}
                                    name={floor}
                                    key={floor}
                                    onClick={onClickHandler}
                                >
                                    {floor}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="sensor-filter-area">
                        <h3>Area</h3>
                        <div className="sensor-area-button-group">
                            {FILTER_BUTTONS.areas.map((area) => (
                                <button
                                    style={{ margin: '2px' }}
                                    className={'bp3-button'}
                                    name={area}
                                    key={area}
                                    onClick={onClickHandler}
                                >
                                    {area}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WidgetSensorsFilter;
