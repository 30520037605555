import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';




export const AdminTreeContext = React.createContext();

const AdminPage = (props) => {
    return (
        <div>

        </div>
    )
};

export default AdminPage;



/* BACKUP

import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import PageLoadingSpinner from '../../../common/components/page-loading-spinner/PageLoadingSpinner';
import Tree from '../../../common/components/tree';
import { getLocations, getCustomers, getAreas, getReactors } from '../../../common/utils/API_calls';
import _ from 'lodash';
import * as KSUID from 'ksuid';
import './style.css';
import Header from '../../../common/components/header/Header';
import AdminContent from '../../components/admin-content';
import { formOperationObject } from '../../../common/utils/GeneralUtils';
import Menu from '../../../common/components/menu/Menu';
import { Position } from '@blueprintjs/core';
import ReactLoading from 'react-loading';

export const AdminTreeContext = React.createContext();

const AdminPage = (props) => {
    const [customersData, setCustomersData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [nodes, setNodes] = useState([
        {
            id: KSUID.randomSync(),
            isExpanded: true,
            hasCaret: true,
            label: <div className="clickable">Customers</div>,
            icon: 'mugshot',
            childNodes: [],
        },
    ]);
    const [adminContent, setAdminContent] = useState(null);

    const getDevicesLabel = useCallback((isLoadingP, areaId) => {
        let menu_items = [
            {
                item: {
                    text: 'Create new device',
                    icon: 'add',
                    on_click: createNewDevice,
                    on_click_parameter: areaId,
                },
            },
        ];
        return (
            <Menu
                fill
                menu_items={menu_items}
                menu_position={Position.BOTTOM_LEFT}
                menu_component={
                    isLoadingP ? (
                        <div className="main-node clickable">
                            Devices <ReactLoading className="loading-node" type="spin" height={20} width={20} />
                        </div>
                    ) : (
                        <div className="main-node clickable">Devices</div>
                    )
                }
            />
        );
    }, []);

    const getNewAreaNode = useCallback(
        (areaObj, customerId) => {
            return {
                id: areaObj.id,
                label: areaObj.name,
                hasCaret: true,
                isExpanded: false,
                type: 'AREA',
                locationId: areaObj.locationId,
                customerId: customerId,
                childNodes: [
                    {
                        id: KSUID.randomSync(),
                        label: getDevicesLabel(false, areaObj.id),
                        icon: 'cell-tower',
                        hasCaret: true,
                        isExpanded: false,
                        type: 'DEVICES',
                        isLoading: false,
                        locationId: areaObj.locationId,
                        customerId: customerId,
                        areaId: areaObj.id,
                    },
                ],
            };
        },
        [getDevicesLabel],
    );

    const createNewArea = useCallback(
        (data) => {
            setAdminContent(formOperationObject('CREATE_NEW_AREA', { ...data, nodeCreationFunction: getNewAreaNode }));
        },
        [getNewAreaNode],
    );

    const getAreasLabel = useCallback(
        (isLoadingP, locationId, locationName) => {
            let menu_items = [
                {
                    item: {
                        text: 'Create new area',
                        icon: 'add',
                        on_click: createNewArea,
                        on_click_parameter: {
                            locationId: locationId,
                            locationName: locationName,
                        },
                    },
                },
                {
                    item: {
                        text: 'Show areas',
                        icon: 'list-columns',
                        on_click: showAreas,
                        on_click_parameter: {
                            locationId: locationId,
                            locationName: locationName,
                        },
                    },
                },
            ];
            return (
                <Menu
                    fill
                    menu_items={menu_items}
                    menu_position={Position.BOTTOM_LEFT}
                    menu_component={
                        isLoadingP ? (
                            <div className="main-node clickable">
                                Areas <ReactLoading className="loading-node" type="spin" height={20} width={20} />
                            </div>
                        ) : (
                            <div className="main-node clickable">Areas</div>
                        )
                    }
                />
            );
        },
        [createNewArea],
    );

    const getReactorsLabel = useCallback((isLoadingP, locationId, locationName) => {
        let menu_items = [
            {
                item: {
                    text: 'Create new reactor',
                    icon: 'add',
                    on_click: createNewReactor,
                    on_click_parameter: {
                        locationId: locationId,
                        locationName: locationName,
                    },
                },
            },
            {
                item: {
                    text: 'Show reactors',
                    icon: 'list-columns',
                    on_click: showReactors,
                    on_click_parameter: {
                        locationId: locationId,
                        locationName: locationName,
                    },
                },
            },
        ];
        return (
            <Menu
                fill
                menu_items={menu_items}
                menu_position={Position.BOTTOM_LEFT}
                menu_component={
                    isLoadingP ? (
                        <div className="main-node clickable">
                            Reactors <ReactLoading className="loading-node" type="spin" height={20} width={20} />
                        </div>
                    ) : (
                        <div className="main-node clickable">Reactors</div>
                    )
                }
            />
        );
    }, []);

    const getNewLocationNode = useCallback(
        (locationObj, customerId) => {
            let label = `${locationObj.street} ${locationObj.streetNo}`;
            return {
                id: locationObj.id,
                label: label,
                hasCaret: true,
                isExpanded: false,
                type: 'LOCATION',
                customerId: customerId,
                childNodes: [
                    {
                        id: KSUID.randomSync(),
                        label: getAreasLabel(false, locationObj.id, label),
                        icon: 'map-marker',
                        hasCaret: true,
                        isExpanded: false,
                        type: 'AREAS',
                        isLoading: false,
                        locationId: locationObj.id,
                        locationName: label,
                        customerId: customerId,
                    },
                    {
                        id: KSUID.randomSync(),
                        label: getReactorsLabel(false, locationObj.id, label),
                        icon: 'exchange',
                        hasCaret: true,
                        isExpanded: false,
                        type: 'REACTORS',
                        isLoading: false,
                        locationId: locationObj.id,
                        locationName: label,
                        customerId: customerId,
                    },
                ],
            };
        },
        [getAreasLabel, getReactorsLabel],
    );

    const createNewLocation = useCallback(
        (data) => {
            setAdminContent(
                formOperationObject('CREATE_NEW_LOCATION', { ...data, nodeCreationFunction: getNewLocationNode }),
            );
        },
        [getNewLocationNode],
    );

    const getLocationsLabel = useCallback(
        (isLoadingP, customerID) => {
            let customer = _.find(customersData, (o) => {
                return o.id === customerID;
            });

            let menu_items = [
                {
                    item: {
                        text: 'Create new location',
                        icon: 'add',
                        on_click: createNewLocation,
                        on_click_parameter: { customerId: customerID, customerName: customer?.name },
                    },
                },
                {
                    item: {
                        text: 'Show locations',
                        icon: 'list-columns',
                        on_click: showLocations,
                        on_click_parameter: customer,
                    },
                },
            ];
            return (
                <Menu
                    fill
                    menu_items={menu_items}
                    menu_position={Position.BOTTOM_LEFT}
                    menu_component={
                        isLoadingP ? (
                            <div className="main-node clickable">
                                Locations <ReactLoading className="loading-node" type="spin" height={20} width={20} />
                            </div>
                        ) : (
                            <div className="main-node clickable">Locations</div>
                        )
                    }
                />
            );
        },
        [createNewLocation, customersData],
    );

    const getNewCustomerNode = useCallback(
        (customerObj) => {
            return {
                id: customerObj.id,
                label: customerObj.name,
                hasCaret: true,
                isExpanded: false,
                type: 'CUSTOMER',
                childNodes: [
                    {
                        id: KSUID.randomSync(),
                        label: getLocationsLabel(false, customerObj.id),
                        icon: 'geosearch',
                        hasCaret: true,
                        isExpanded: false,
                        type: 'LOCATIONS',
                        isLoading: false,
                        customerId: customerObj.id,
                    },
                ],
            };
        },
        [getLocationsLabel],
    );

    const showCustomers = useCallback(() => {
        setAdminContent(formOperationObject('SHOW_CUSTOMERS', { data: customersData }));
    }, [customersData]);

    const createNewCustomer = useCallback(() => {
        setAdminContent(formOperationObject('CREATE_NEW_CUSTOMER', { nodeCreationFunction: getNewCustomerNode }));
    }, [getNewCustomerNode]);

    const getCustomersLabel = useCallback(() => {
        let menu_items = [
            {
                item: {
                    text: 'Create new customer',
                    icon: 'add',
                    on_click: createNewCustomer,
                },
            },
            {
                item: {
                    text: 'Show list of customers',
                    icon: 'list-columns',
                    on_click: showCustomers,
                },
            },
        ];

        return (
            <Menu
                fill
                menu_items={menu_items}
                menu_position={Position.BOTTOM_LEFT}
                menu_component={<div className="main-node clickable">Customers</div>}
            />
        );
    }, [createNewCustomer, showCustomers]);

    // Functions for filling parental tree nodes like Customers, Locations, atc.
    const formCustomerNodes = useCallback(() => {
        let updatedNodes = _.cloneDeep(nodes);
        updatedNodes[0].label = getCustomersLabel(createNewCustomer);
        _.forEach(customersData, (customer, id) => {
            updatedNodes[0].childNodes.push(getNewCustomerNode(customer));
        });
        return updatedNodes;
    }, [createNewCustomer, customersData, getCustomersLabel, getNewCustomerNode, nodes]);

    useEffect(() => {
        if (customersData == null) {
            getCustomers()
                .then((customers) => {
                    setCustomersData(customers);
                    //Setting default content here
                    setAdminContent(formOperationObject('SHOW_CUSTOMERS', { data: customers }));
                })
                .catch((e) => {
                    console.log(e);
                    setCustomersData([]);
                });
        } else {
            console.warn('We are here');
            //setNodes(formCustomerNodes());
            setIsLoading(false);
        }
    }, [customersData, formCustomerNodes]);

    // Functions for creating node labels

    const formLocationsNodes = (locations, nodes, customerId) => {
        let customerNode = _.find(nodes[0].childNodes, (o) => {
            return o.id === customerId;
        });
        if (customerNode && customerNode.childNodes && customerNode.childNodes.length > 0) {
            customerNode.childNodes[0].childNodes = [];
            _.forEach(locations, (location, id) => {
                customerNode.childNodes[0].childNodes.push(getNewLocationNode(location, customerId));
            });
        }
        return nodes;
    };

    const formAreasNodes = (areas, nodes, customerId, locationId) => {
        let customerNode = _.find(nodes[0].childNodes, (o) => {
            return o.id === customerId;
        });
        if (customerNode && customerNode.childNodes && customerNode.childNodes.length > 0) {
            let locationNode = _.find(customerNode.childNodes[0].childNodes, (y) => {
                return y.id === locationId;
            });
            if (locationNode && locationNode.childNodes && locationNode.childNodes.length > 0) {
                locationNode.childNodes[0].childNodes = [];
                _.forEach(areas, (area, id) => {
                    locationNode.childNodes[0].childNodes.push(getNewAreaNode(area, customerId));
                });
            }
        }
        return nodes;
    };

    const formReactorsNodes = (reactors, nodes, customerId, locationId) => {
        let customerNode = _.find(nodes[0].childNodes, (o) => {
            return o.id === customerId;
        });
        if (customerNode && customerNode.childNodes && customerNode.childNodes.length > 0) {
            let locationNode = _.find(customerNode.childNodes[0].childNodes, (y) => {
                return y.id === locationId;
            });
            if (locationNode && locationNode.childNodes && locationNode.childNodes.length > 0) {
                locationNode.childNodes[1].childNodes = [];
                _.forEach(reactors, (reactor, id) => {
                    locationNode.childNodes[1].childNodes.push(getNewReactorNode(reactor, customerId));
                });
            }
        }
        return nodes;
    };

    const getNewReactorNode = (reactorObj, customerId) => {
        return {
            id: reactorObj.id,
            label: reactorObj.name,
            hasCaret: true,
            isExpanded: false,
            type: 'REACTOR',
            locationId: reactorObj.location,
            customerId: customerId,
        };
    };

    // Functions that modifies adminContent (popover menu buttons onClick event functions)

    const showLocations = (customer) => {
        setAdminContent(formOperationObject('SHOW_LOADING'));
        getLocations(customer.id)
            .then((locations) => {
                setAdminContent(
                    formOperationObject('SHOW_LOCATIONS', { customerName: customer.name, locations: locations }),
                );
            })
            .catch((e) => {
                setAdminContent(formOperationObject('SHOW_LOCATIONS', { customerName: customer.name, locations: [] }));
            });
    };

    const showAreas = (data) => {
        setAdminContent(formOperationObject('SHOW_LOADING'));
        getAreas(data.locationId)
            .then((areas) => {
                setAdminContent(formOperationObject('SHOW_AREAS', { ...data, areas: areas }));
            })
            .catch((e) => {
                setAdminContent(formOperationObject('SHOW_AREAS', { ...data, areas: [] }));
            });
    };

    const showReactors = (data) => {
        setAdminContent(formOperationObject('SHOW_LOADING'));
        getReactors(data.locationId)
            .then((reactors) => {
                setAdminContent(formOperationObject('SHOW_REACTORS', { ...data, reactors: reactors }));
            })
            .catch((e) => {
                setAdminContent(formOperationObject('SHOW_REACTORS', { ...data, reactors: [] }));
            });
    };

    const createNewReactor = (locationId) => {
        setAdminContent(formOperationObject('CREATE_NEW_REACTOR', { locationId: locationId }));
    };

    const createNewDevice = (areaId) => {
        setAdminContent(formOperationObject('CREATE_NEW_AREA', { areaId: areaId }));
    };

    // Tree event handlers
    const handleNodeCollapse = (nodeData) => {
        nodeData.isExpanded = false;
        if (nodeData.isLoading) {
            switch (nodeData.type) {
                case 'LOCATIONS':
                    nodeData.label = getLocationsLabel(false, nodeData.customerId);
                    nodeData.isLoading = false;
                    break;
                case 'AREAS':
                    nodeData.label = getAreasLabel(false, nodeData.locationId, nodeData.locationName);
                    nodeData.isLoading = false;
                    break;
                case 'REACTORS':
                    nodeData.label = getReactorsLabel(false, nodeData.locationId, nodeData.locationName);
                    nodeData.isLoading = false;
                    break;
                default:
                    break;
            }
        }
        let clonedNodes = _.cloneDeep(nodes);
        setNodes(clonedNodes);
    };

    const handleNodeExpand = (nodeData) => {
        if (nodeData.type) {
            switch (nodeData.type) {
                case 'LOCATIONS':
                    nodeData.label = getLocationsLabel(true, nodeData.customerId);
                    nodeData.isLoading = true;

                    getLocations(nodeData.customerId)
                        .then((locations) => {
                            nodeData.isLoading = false;
                            nodeData.label = getLocationsLabel(false, nodeData.customerId);
                            let clonedNodes = _.cloneDeep(nodes);
                            setNodes(formLocationsNodes(locations, clonedNodes, nodeData.customerId));
                        })
                        .catch((e) => {
                            nodeData.isLoading = false;
                            nodeData.label = getLocationsLabel(false, nodeData.customerId);
                            let clonedNodes = _.cloneDeep(nodes);
                            setNodes(clonedNodes);
                        });
                    break;
                case 'AREAS':
                    nodeData.label = getAreasLabel(true, nodeData.locationId, nodeData.locationName);
                    nodeData.isLoading = true;
                    getAreas(nodeData.locationId)
                        .then((areas) => {
                            nodeData.isLoading = false;
                            nodeData.label = getAreasLabel(false, nodeData.locationId, nodeData.locationName);
                            let clonedNodes = _.cloneDeep(nodes);
                            setNodes(formAreasNodes(areas, clonedNodes, nodeData.customerId, nodeData.locationId));
                        })
                        .catch((e) => {
                            nodeData.isLoading = false;
                            nodeData.label = getAreasLabel(false, nodeData.locationId, nodeData.locationName);
                            let clonedNodes = _.cloneDeep(nodes);
                            setNodes(clonedNodes);
                        });
                    break;
                case 'REACTORS':
                    nodeData.label = getReactorsLabel(true, nodeData.locationId, nodeData.locationName);
                    nodeData.isLoading = true;

                    getReactors(nodeData.locationId)
                        .then((reactors) => {
                            nodeData.isLoading = false;
                            nodeData.label = getReactorsLabel(false, nodeData.locationId, nodeData.locationName);
                            let clonedNodes = _.cloneDeep(nodes);
                            setNodes(
                                formReactorsNodes(reactors, clonedNodes, nodeData.customerId, nodeData.locationId),
                            );
                        })
                        .catch((e) => {
                            nodeData.isLoading = false;
                            nodeData.label = getReactorsLabel(false, nodeData.locationId, nodeData.locationName);
                            let clonedNodes = _.cloneDeep(nodes);
                            setNodes(clonedNodes);
                        });
                    break;
                default:
                    break;
            }
        }
        nodeData.isExpanded = true;
        let clonedNodes = _.cloneDeep(nodes);
        setNodes(clonedNodes);
    };

    const context = { nodes, setNodes };

    if (isLoading) {
        return <PageLoadingSpinner />;
    } else {
        return (
            <Container>
                <Row>
                    <Header level={3}>Control panel</Header>
                </Row>
                <Row justify="start" style={{ marginTop: '20px' }}>
                    <Col xl={3} className="admin-tree">
                        <Tree
                            contents={nodes}
                            onNodeCollapse={(nodeData) => handleNodeCollapse(nodeData)}
                            onNodeExpand={(nodeData) => handleNodeExpand(nodeData)}
                        />
                    </Col>
                    <Col xl={9}>
                        <AdminTreeContext.Provider value={context}>
                            <AdminContent data={adminContent} />
                        </AdminTreeContext.Provider>
                    </Col>
                </Row>
            </Container>
        );
    }
};

export default AdminPage;


*/
