import React, { useContext, useEffect } from 'react';
import CustomToastContext from '../../context/customToastProvider';
import { Card, Icon, ProgressBar } from '@blueprintjs/core';
import './CustomToast.scss';



const apiEndpoints = {abortUpload: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/abortUpload`};



const CustomToast = ({ isDarkTheme }) => {
    //STATE
    const { toastState, changeToastState } = useContext(CustomToastContext);
    const {toastShown, progressShown, progressValue, toastText } = toastState;



    //CLOSE TOAST
    const closeToast = () => {
        document.querySelector('.custom-toast-box').style.opacity = '0';
        setTimeout(() => {changeToastState({toastShown: false})}, 400);
    }



    //ABORT UPLOAD
    const abortUpload = async () => {
        if (sessionStorage.getItem('uploadId') && sessionStorage.getItem('fileName')) {
            const fileName = sessionStorage.getItem('fileName');
            const uploadId = sessionStorage.getItem('uploadId');
            const abortRes = await fetch(apiEndpoints.abortUpload, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({fileName, uploadId})
            })
            if (abortRes.ok) {
                changeToastState({toastText: 'Aborting upload. One moment please...'});
                changeToastState({progressShown: false})
                sessionStorage.removeItem('uploadId');
                sessionStorage.removeItem('fileName');
            } else {
                changeToastState({toastText: 'Upload abort failed'});
                changeToastState({progressShown: true});
                setTimeout(() => {changeToastState({toastText: ''})}, 2000);
            }
        } else {
            changeToastState({toastText: 'Could not retrieve uploadId, cannot abort upload'});
            setTimeout(() => {changeToastState({toastText: ''})}, 2000);
        }
    }


    
    return (
        <div className='custom-toast-overlay'>
            {
                toastShown
                &&
                <div className='custom-toast-box' style={{opacity: '1'}}>
                    <Card className='toast-card'>
                        {toastText && <p className='toast-text'>{toastText}</p>}
                        {progressShown && <ProgressBar value={progressValue} className='toast-progressbar' />}
                        {
                            progressShown 
                            && 
                            <span className='toast-abort-btn' onClick={abortUpload}>
                                <Icon icon="cross" style={{transform: 'scale(0.6) translateY(3px)' }} />ABORT
                            </span>
                        }
                    </Card>
                    <Icon icon="cross" className='toast-close-btn' onClick={closeToast} />
                </div>
            }
        </div>
    )
}

export default CustomToast