import React, { useEffect } from 'react';
import { Card, Elevation } from '@blueprintjs/core';

import { useStore } from '../../store/useStore';

import './WidgetRSS.scss';

function WidgetRSS(props) {
    const [globalStore, dispatch] = useStore();
    const rssData = globalStore.widgetData.rssData;
    const isRssData = globalStore.widgetData.isRssData;
    const rssUpdate = globalStore.widgetData.rssUpdate;
    let widgetStyle = {};
    if (Object.keys(props).length > 1) {
        widgetStyle = {
            gridColumnStart: props.positionx,
            gridColumnEnd: props.positionx + props.sizex,
            gridRowStart: props.positiony,
            gridRowEnd: props.positiony + props.sizey,
            overflowY: 'scroll',
            flexDirection: 'column',
        };
    } else {
        widgetStyle = {
            visibility: 'hidden',
        };
    }
    useEffect(() => {
        if (!isRssData | (new Date().getTime() >= rssUpdate)) {
            const fetchRSS = function () {
                try {
                    let xhr = new XMLHttpRequest();
                    xhr.open('GET', 'https://www.wired.com/feed/rss', true);
                    xhr.send();
                    xhr.onload = function () {
                        if (xhr.status !== 200) {
                            throw new Error('brekeke');
                        } else {
                            const response = xhr.response;
                            const domParser = new DOMParser();
                            const fetchDocument = domParser.parseFromString(response, 'text/xml');
                            dispatch('STORE_RSS', fetchDocument.getElementsByTagName('item'));
                        }
                    };
                } catch (error) {
                    console.log(error);
                }
            };
            fetchRSS();
        }
    }, [isRssData, rssUpdate]);

    return (
        <Card className="floor-widget" elevation={Elevation.THREE} style={widgetStyle}>
            {rssData}
        </Card>
    );
}

export default WidgetRSS;
