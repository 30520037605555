import React, { useEffect, useState, useRef } from 'react';
import { Icon } from '@blueprintjs/core';

const VideoCarousel = ({ videos, currentVideo, setCurrentVideo, getVideoDuration }) => {
    //WARN IF NO VIDEOS/CURRENT VIDEO
    if (!videos || typeof currentVideo !== 'number')
        console.log('VideoCarousel.js did not receive videos or currentVideo props');

    //CAROUSEL SIZE
    let navbarHeight = document.querySelector('.floor-navbar').getBoundingClientRect().height;
    const carouselMarginY = 10;
    const carouselMarginX = 10;
    const [videoSize, setVideoSize] = useState({
        width: window.innerWidth - 2 * carouselMarginX,
        height: window.innerHeight - navbarHeight * 2 - carouselMarginY * 2,
    });

    const calculateVideoSize = () => {
        navbarHeight = document.querySelector('.floor-navbar').getBoundingClientRect().height;
        setVideoSize({
            width: window.innerWidth - 2 * carouselMarginX,
            height: window.innerHeight - navbarHeight * 2 - carouselMarginY * 2,
        });
    };

    //RE-CALCULATE VIDEO SIZE ON WINDOW RESIZE
    useEffect(() => {
        window.addEventListener('resize', calculateVideoSize);
        return () => window.removeEventListener('resize', calculateVideoSize);
    }, []);

    //PLAY NEXT/PREV VIDEO METHODS
    const playPrevious = () => {
        if (currentVideo === 0) {
            setCurrentVideo(videos.length - 1);
        } else {
            setCurrentVideo((prev) => prev - 1);
        }
        //screenBackground.current.click(); //to remove an ugly blue border from video that html puts there when video is clicked
    };

    const playNext = () => {
        if (currentVideo === videos.length - 1) {
            setCurrentVideo(0);
        } else {
            setCurrentVideo((prev) => prev + 1);
        }
        //screenBackground.current.click(); //to remove an ugly blue border from video that html puts there when video is clicked
    };

    //MOVE TO NEXT VIDEO WHEN CURRENTLY PLAYING VIDEO ENDS
    const playingVideo = useRef();

    const onVideoEnd = (e) => {
        if (e.target.currentTime + 0.5 > e.target.duration) {
            // 0.5sec before end
            e.target.removeEventListener('timeupdate', onVideoEnd);
            if (currentVideo === videos.length - 1) {
                setCurrentVideo(0);
            } else {
                setCurrentVideo((prev) => {
                    return prev + 1;
                });
            }
            //screenBackground.current.click(); //to remove an ugly blue border from video that html puts there when video is clicked
        }
    };

    useEffect(() => {
        getVideoDuration(playingVideo.current);
    }, [playingVideo.current]);

    useEffect(() => {
        if (playingVideo.current) {
            playingVideo.current.addEventListener('timeupdate', onVideoEnd);
        }

        return () => {
            if (playingVideo.current) playingVideo.current.removeEventListener('timeupdate', onVideoEnd);
        };
    }, [playingVideo.current, videos, currentVideo]);

    //RENDER CAROUSEL
    const renderCarousel = () => {
        if (!videos || videos.length === 0 || typeof currentVideo !== 'number') return <div />;
        return (
            <div
                className="carousel-container"
                style={{
                    position: 'relative',
                    width: `${videoSize.width}px`,
                    minWidth: `${videoSize.width}px`,
                    height: `${videoSize.height}px`,
                }}
            >
                <video
                    ref={playingVideo}
                    key={videos.length === 1 ? `${videos[0].videoUrl}` : `${videos[currentVideo].videoUrl}`}
                    loop={true}
                    id="presentation-video"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    muted
                    controls
                    autoPlay
                >
                    <source
                        src={videos.length === 1 ? videos[0].videoUrl : videos[currentVideo].videoUrl}
                        type="video/mp4"
                    ></source>
                </video>

                <div
                    onClick={() => playPrevious()}
                    className="left-arrow-container"
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '20px',
                        height: '100%',
                        cursor: 'pointer',
                        background: 'rgba(0, 0, 0, 0.7)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                    }}
                >
                    <Icon icon="chevron-left"></Icon>
                </div>

                <div
                    onClick={() => playNext()}
                    className="right-arrow-container"
                    style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        width: '20px',
                        height: '100%',
                        cursor: 'pointer',
                        background: 'rgba(0, 0, 0, 0.7)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                    }}
                >
                    <Icon icon="chevron-right"></Icon>
                </div>
            </div>
        );
    };

    //RENDER
    return (
        <React.Fragment>
            {videos && videos.length > 0 && (currentVideo || currentVideo === 0) && renderCarousel()}
        </React.Fragment>
    );
};

export default VideoCarousel;
