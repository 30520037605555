import React from 'react';
import './style.css';
import { Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Container, Row, Col } from 'react-grid-system';

const Footer = (props) => {
    return (
        <Container>
            <Row
                style={{
                    bottom: 0,
                    left: 0,
                    position: 'fixed',
                    height: '40px',
                    width: '100%',
                    background: '#ffffff',
                }}
                nogutter
            >
                <Col xl={6} style={{ paddingLeft: '10px', paddingTop: '10px' }}>
                    <div>
                        <p style={{ width: '100%' }}>
                            <small className="footer-text">
                                SCOOT was made with <Icon icon={IconNames.HEART} iconSize={12} intent={Intent.DANGER} />{' '}
                                @ GIDC Bratislava Innovation Team, © 2020 DXC.technology
                            </small>
                            <small className="footer-contact">
                                <a href="mailto:support@scoot.dxc.com">Contact us</a>
                            </small>
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Footer;
