import React from 'react';
import { Card, Elevation } from '@blueprintjs/core';

function Widget(props) {
    let widgetStyle = { backgroundColor: 'var(--app-black-transparent)', color: 'white' };
    if (Object.keys(props).length > 1) {
        //TODO: should be more generic
        widgetStyle = {
            gridColumnStart: props.positionx,
            gridColumnEnd: props.positionx + props.sizex,
            gridRowStart: props.positiony,
            gridRowEnd: props.positiony + props.sizey,
            visibility: 'hidden',
        };
    } else {
        widgetStyle = {
            visibility: 'hidden',
        };
    }
    return (
        <Card className="floor-widget" elevation={Elevation.THREE} style={widgetStyle}>
            <span className="floor-icon-weather-main floor-pointer-none" />

            <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
            </div>
            {/* <p>
                {props.text}|{props.positionx}|{props.positiony}|{props.sizex}|{props.sizey}
            </p> */}
        </Card>
    );
}

export default Widget;
