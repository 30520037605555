import React, { useState, useEffect, useContext } from 'react';
import UserSettingsContext from '../../../context/userSettingsContext';
import { useStore } from '../../store/useStore';
import { Button, Collapse, Dialog, FormGroup, Icon, InputGroup, TextArea } from '@blueprintjs/core';
import './ManageNotesScreen.scss';
import ReactQuill from 'react-quill';
import renderHtml from 'react-render-html';
import 'react-quill/dist/quill.snow.css';




const ManageNotesScreen = ({ isDarkTheme }) => {
    //ENDPOINTS
    const notesEndpoints = {
        createNote: `https://vgs7fr3czb.execute-api.eu-west-1.amazonaws.com/dev/createnote`, //POST {title, body, user}
        getNotes: `https://vgs7fr3czb.execute-api.eu-west-1.amazonaws.com/dev/getnotes`, //POST {user}
        updateNote: `https://vgs7fr3czb.execute-api.eu-west-1.amazonaws.com/dev/update`, //PUT {id, title, body, user}
        deleteNote: `https://vgs7fr3czb.execute-api.eu-west-1.amazonaws.com/dev/deletenote`, //DELETE {id, user}
    };

    const userSettingsEndpoints = {
        getUserSettings: `https://25uhu3mgnf.execute-api.eu-west-1.amazonaws.com/dev/getusersettings`,
        setUserSettings: `https://25uhu3mgnf.execute-api.eu-west-1.amazonaws.com/dev/setusersettings`,
    };




    //GLOBAL STATE
    const isGlobalUser = useStore()[0].userSettings.isGlobal;
    const { userSettings, changeUserSettings } = useContext(UserSettingsContext);
    const { activeNote, email } = userSettings;



    //MESSAGE
    const [message, setMessage] = useState('');
    const showMessage = (text) => {
        setMessage(text);
        setTimeout(() => setMessage(''), 2000);
    };



    //GET ALL NOTES
    const [notes, setNotes] = useState([]);

    useEffect(() => {
        if (isGlobalUser) {
            setCollapseOpen(false);
            setUpdateOnSubmit(false);
            setValues({ id: '', title: '', body: '', isActive: 'false' });
            setMessage('Getting notes...');
            fetch(notesEndpoints.getNotes, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({user: email})
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data && data.error) {
                        setNotes([]);
                        showMessage('Notes failed to fetch');
                    } else {
                        setNotes(data.notes);
                        setMessage('');
                    }
                })
                .catch((error) => {
                    setNotes([]);
                    showMessage(JSON.stringify(error));
                });
        } else {
            setCollapseOpen(false);
            setUpdateOnSubmit(false);
            setValues({ id: '', title: '', body: '', isActive: 'false' });
            setNotes(localStorage.getItem('localUsersNotes') ? JSON.parse(localStorage.getItem('localUsersNotes')) : []);
        }   
    }, [isGlobalUser]);



    //CREATE NOTE
    const [formDisabled, setFormDisabled] = useState(false);
    const [formMessage, setFormMessage] = useState('');
    const [collapseOpen, setCollapseOpen] = useState(false);
    const [values, setValues] = useState({ title: '', body: '', id: '', user: email });
    const { title, body } = values;

    const handleChange = (e) => {
        setMessage('');
        setFormMessage('');
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handleReactQuill = e => {
        setMessage('');
        setFormMessage('');
        setValues({...values, body: e});
    }

    const createNote = () => {
        //validate
        if (!title || title.trim() === '') {
            setFormMessage('Title is required');
            setTimeout(() => {
                setFormMessage('');
                setFormDisabled(false);
            }, 2000);
            return;
        }
        if (!body || body.trim() === '') {
            setFormMessage('Note is required');
            setTimeout(() => {
                setFormMessage('');
                setFormDisabled(false);
            }, 2000);
            return;
        }

        //if global user
        if (isGlobalUser) {
            fetch(notesEndpoints.createNote, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({...values, user: email}),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data && data.error) {
                        setFormMessage(data.error);
                        setTimeout(() => {
                            setFormMessage('');
                        }, 2000);
                    } else {
                        setFormMessage('Note created');
                        setNotes([{ ...data }, ...notes]);
                        setValues({ title: '', body: '', id: '', user: email });
                        setTimeout(() => {
                            setCollapseOpen(false);
                            setFormMessage('');
                        }, 2000);
                    }
                    setFormDisabled(false);
                })
                .catch((error) => {
                    setFormMessage(JSON.stringify(error));
                    setFormDisabled(false);
                    setTimeout(() => {
                        setFormMessage('');
                    }, 2000);
                });
        //if local user
        } else {
            let existingNotes = localStorage.getItem('localUsersNotes') ? JSON.parse(localStorage.getItem('localUsersNotes')) : [];
            existingNotes.push({...values, user: email, id: Math.random()});
            localStorage.setItem('localUsersNotes', JSON.stringify(existingNotes));
            setNotes(existingNotes);
            setFormMessage('Local note created');
            setValues({ title: '', body: '', id: '', user: email });
            setFormDisabled(false);
            setTimeout(() => {
                setCollapseOpen(false);
                setFormMessage('');
            }, 2000);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formDisabled) return;
        setFormDisabled(true);

        if (updateOnSubmit) {
            setFormMessage('Updating note...');
            updateNote();
        } else {
            setFormMessage('Saving note...');
            createNote();
        }
    };

    const renderNoteForm = () => {
        return (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <FormGroup label={'Title'}>
                    <InputGroup
                        placeholder="Title (required)"
                        name="title"
                        value={title}
                        onChange={handleChange}
                        disabled={formDisabled}
                    />
                </FormGroup>

                <div className='react-quill-wrapper'>
                    <ReactQuill 
                        value={values.body}
                        onChange={handleReactQuill}
                        theme='snow'
                        modules={ManageNotesScreen.modules}
                        formats={ManageNotesScreen.formats}
                        style={{
                            minHeight: '6rem',
                            marginBottom: '0.25rem'
                        }}
                    />
                </div>

                {formMessage ? (
                    <p style={{ textAlign: 'center', color: '#d33d17' }}>{formMessage}</p>
                ) : (
                    <Button text="Submit" style={{ width: '100%' }} type="submit" />
                )}
                <br />
            </form>
        );
    };



    //DELETE NOTE
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [toDeleteNoteId, setToDeleteNoteId] = useState(null);

    const confirmDelete = (noteId) => {
        setToDeleteNoteId(noteId);
        setDeleteConfirmOpen(true);
    };

    const deleteNote = (noteId) => {
        //checks
        if (formDisabled) return;
        if (!noteId) return console.log('No noteId passed to deleteNote()');

        //if user wants to delete active note
        if (activeNote && activeNote.id.toString() === noteId.toString()) {
            setDeleteConfirmOpen(false)
            setMessage('This note is active and cannot be deleted. Please deactivate the note first.');
            setTimeout(() => {setMessage('');}, 2000);
            return
        }

        //if user deletes while update form is open
        setUpdateOnSubmit(false);
        setCollapseOpen(false);
        setValues({ title: '', body: '', id: '', user: email })

        //start deleting
        setFormDisabled(true);
        setToDeleteNoteId(null);
        setMessage('Deleting Note...');
        setDeleteConfirmOpen(false);

        //if global user
        if (isGlobalUser) {
            fetch(notesEndpoints.deleteNote, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: noteId, user: email }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data && data.error) {
                        showMessage(data.error);
                        setFormDisabled(false);
                    } else {
                        let allNotes = [...notes];
                        let noteIdx = allNotes.findIndex((item) => item.id === noteId);
                        allNotes.splice(noteIdx, 1);
                        setNotes([...allNotes]);
                        showMessage('Note deleted');
                        setFormDisabled(false);
                    }
                })
                .catch((error) => {
                    showMessage(JSON.stringify(error));
                    setFormDisabled(false);
                });
        } 
        //if local user
        else {
            let allNotes = [...notes];
            let noteIdx = allNotes.findIndex((item) => item.id === noteId);
            allNotes.splice(noteIdx, 1);
            localStorage.setItem('localUsersNotes', JSON.stringify(allNotes))
            setNotes([...allNotes]);
            showMessage('Note deleted');
            setFormDisabled(false);
        }
    };



    //UPDATE NOTE
    const [updateOnSubmit, setUpdateOnSubmit] = useState(false);

    const startUpdating = (note) => {
        if (formDisabled) return;
        if (!note) return console.log('startUpdating() got no note');
        if (activeNote && activeNote.id.toString() === note.id.toString()) {
            showMessage('Active note cannot be edited. Please deactivate the note first');
            return
        }
        setValues({ ...note });
        setUpdateOnSubmit(true);
        setCollapseOpen(true);
    };

    const updateNote = () => {
        //validate
        if (!title || title.trim() === '') {
            setFormMessage('Title is required');
            setTimeout(() => {
                setFormMessage('');
                setFormDisabled(false);
            }, 2000);
            return;
        }

        if (!body || body.trim() === '') {
            setFormMessage('Note is required');
            setTimeout(() => {
                setFormMessage('');
                setFormDisabled(false);
            }, 2000);
            return;
        }

        //if global user
        if (isGlobalUser) {
            fetch(notesEndpoints.updateNote, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({...values, user: email}),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data && data.error) {
                        setFormMessage(data.error);
                        setTimeout(() => {
                            setFormMessage('');
                        }, 2000);
                    } else {
                        setFormMessage('Note updated');
                        let allNotes = [...notes];
                        let noteIdx = allNotes.findIndex((item) => item.id === values.id);
                        allNotes.splice(noteIdx, 1, values);
                        setNotes(allNotes);
                        setValues({ title: '', body: '', id: '', user: email });
                        setTimeout(() => {
                            setCollapseOpen(false);
                            setFormMessage('');
                            setUpdateOnSubmit(false);
                        }, 2000);
                    }
                    setFormDisabled(false);
                })
                .catch((error) => {
                    setFormMessage(JSON.stringify(error));
                    setFormDisabled(false);
                    setTimeout(() => {
                        setFormMessage('');
                    }, 2000);
                });
        } 
        //if local user
        else {
            setFormMessage('Note updated');
            let allNotes = [...notes];
            let noteIdx = allNotes.findIndex((item) => item.id === values.id);
            allNotes.splice(noteIdx, 1, {...values});
            localStorage.setItem('localUsersNotes', JSON.stringify(allNotes));
            setNotes(allNotes);
            setValues({ title: '', body: '', id: '', user: email });
            setFormDisabled(false);
            setTimeout(() => {
                setCollapseOpen(false);
                setFormMessage('');
                setUpdateOnSubmit(false);
            }, 2000);
        }
    }; //triggered in handleSubmit() if updateOnSubmit = true



    //DE/ACTIVATE NOTE
    const changeNoteStatus = async (note) => { //only one note can be active
        if (formDisabled) return;
        if (!note) return console.log('activateNote() got no note argument');
        setMessage('Activating note...');
        setFormDisabled(true);

        //globalUser
        if (isGlobalUser) {
            if (activeNote && activeNote.id.toString() === note.id.toString()) { //note was active
                changeUserSettings({activeNote: false});
                setMessage('Note deactivated');
                setTimeout(() => {setMessage(''); setFormDisabled(false);}, 2000);
            } else { //note wasn't active
                changeUserSettings({activeNote: note});
                setMessage('Note activated');
                setTimeout(() => {setMessage(''); setFormDisabled(false);}, 2000);
            }
        }
        //local user
        else {
            if (activeNote && activeNote.id.toString() === note.id.toString()) { //note was active
                changeUserSettings({activeNote: false});
                setMessage('Note deactivated');
                setTimeout(() => {setMessage(''); setFormDisabled(false);}, 2000);
            } else { //note wasn't active
                changeUserSettings({activeNote: note});
                setMessage('Note activated');
                setTimeout(() => {setMessage(''); setFormDisabled(false);}, 2000);
            }
        }
    };



    //NOTE ITEM RENDERER
    const renderNote = (note) => {
        if (!note && !note.id && !note.title && !note.body && !note.isActive)
            return console.log('renderNote() needs a note with id, isActive, title, and body');
        return (
            <div className="note-item" key={note.id}>
                <p className="note-item-title">{note.title.substring(0, 30)}</p>
                <p className="note-item-excerpt">
                    {note.body.substring(0, 100).replace(/(<([^>]+)>)/gi, "") + '...'} {/* removes html tags only leaving text */}
                </p>
                <div className="note-item-buttons" style={{ color: activeNote && activeNote.id.toString() === note.id.toString() ? 'green' : '' }}>
                    <small
                        className="note-item-controls activate-btn"
                        onClick={() => {
                            changeNoteStatus(note);
                        }}
                    >
                        {activeNote && activeNote.id.toString() === note.id.toString() ? 'DEACTIVATE' : 'ACTIVATE'}
                    </small>
                    <Icon className="note-item-controls icon" icon="edit" onClick={() => startUpdating(note)} />
                    <Icon className="note-item-controls icon" icon="trash" onClick={() => confirmDelete(note.id)} />
                </div>
            </div>
        );
    };



    //RENDER
    return (
        <div
            style={{
                minHeight: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}
        >
            <h2 style={{ padding: 0, margin: 0 }}>NOTES</h2> <br />
            {message && <p style={{ color: '#d33d17', textAlign: 'center' }}>{message}</p>}
            <Button
                text={collapseOpen ? 'Close' : 'Add Note'}
                style={{ width: '260px' }}
                onClick={() => {
                    setCollapseOpen(!collapseOpen);
                    if (collapseOpen === true) {
                        setValues({ title: '', body: '', id: '', user: email });
                        setUpdateOnSubmit(false);
                    }
                }}
            />
            <br />
            <div style={{ width: '100%' }}>
                <Collapse isOpen={collapseOpen}>
                    {renderNoteForm()} <br />
                </Collapse>
            </div>
            <div className="notes-wrapper">
                {!notes || 
                    (notes && notes.length === 0 && !message && (
                        <p
                            style={{
                                padding: '0',
                                margin: '0',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            No notes
                        </p>
                    ))}
                {notes && notes.length > 0 && notes.map((note) => renderNote(note))}
            </div>
            <Dialog
                isOpen={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
                style={{ background: '#db3737', color: 'white' }}
            >
                <div
                    style={{
                        marginTop: '1.25rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <p style={{ textAlign: 'center', padding: 0, margin: 0 }}>
                        Are you sure you want to delete this note?
                    </p>
                    <div style={{ marginTop: '1rem' }}>
                        <span
                            onClick={() => deleteNote(toDeleteNoteId)}
                            style={{ margin: '1rem 1rem 1rem 0rem', cursor: 'pointer' }}
                        >
                            DELETE
                        </span>
                        <span
                            onClick={() => {
                                setToDeleteNoteId(null);
                                setDeleteConfirmOpen(false);
                            }}
                            style={{ margin: '1rem 1rem 1rem 0rem', cursor: 'pointer' }}
                        >
                            CANCEL
                        </span>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};



ManageNotesScreen.modules = { ////////////
    toolbar: [
        [{ header: '1' }, { header: '2' }, { header: [3, 4, 5, 6] }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
        ['code-block']
    ]
};
 
ManageNotesScreen.formats = [ //////////
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
    'code-block'
];




export default ManageNotesScreen;







/* before react quill

import React, { useState, useEffect, useContext } from 'react';
import UserSettingsContext from '../../../context/userSettingsContext';
import { useStore } from '../../store/useStore';
import { Button, Collapse, Dialog, FormGroup, Icon, InputGroup, TextArea } from '@blueprintjs/core';
import './ManageNotesScreen.scss';





const ManageNotesScreen = ({ isDarkTheme }) => {
    //ENDPOINTS
    const notesEndpoints = {
        createNote: `https://vgs7fr3czb.execute-api.eu-west-1.amazonaws.com/dev/createnote`, //POST {title, body, user}
        getNotes: `https://vgs7fr3czb.execute-api.eu-west-1.amazonaws.com/dev/getnotes`, //POST {user}
        updateNote: `https://vgs7fr3czb.execute-api.eu-west-1.amazonaws.com/dev/update`, //PUT {id, title, body, user}
        deleteNote: `https://vgs7fr3czb.execute-api.eu-west-1.amazonaws.com/dev/deletenote`, //DELETE {id, user}
    };

    const userSettingsEndpoints = {
        getUserSettings: `https://25uhu3mgnf.execute-api.eu-west-1.amazonaws.com/dev/getusersettings`,
        setUserSettings: `https://25uhu3mgnf.execute-api.eu-west-1.amazonaws.com/dev/setusersettings`,
    };




    //GLOBAL STATE
    const isGlobalUser = useStore()[0].userSettings.isGlobal;
    const { userSettings, changeUserSettings } = useContext(UserSettingsContext);
    const { activeNote, email } = userSettings;



    //MESSAGE
    const [message, setMessage] = useState('');
    const showMessage = (text) => {
        setMessage(text);
        setTimeout(() => setMessage(''), 2000);
    };



    //GET ALL NOTES
    const [notes, setNotes] = useState([]);

    useEffect(() => {
        if (isGlobalUser) {
            setCollapseOpen(false);
            setUpdateOnSubmit(false);
            setValues({ id: '', title: '', body: '', isActive: 'false' });
            setMessage('Getting notes...');
            fetch(notesEndpoints.getNotes, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({user: email})
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data && data.error) {
                        setNotes([]);
                        showMessage('Notes failed to fetch');
                    } else {
                        setNotes(data.notes);
                        setMessage('');
                    }
                })
                .catch((error) => {
                    setNotes([]);
                    showMessage(JSON.stringify(error));
                });
        } else {
            setCollapseOpen(false);
            setUpdateOnSubmit(false);
            setValues({ id: '', title: '', body: '', isActive: 'false' });
            setNotes(localStorage.getItem('localUsersNotes') ? JSON.parse(localStorage.getItem('localUsersNotes')) : []);
        }   
    }, [isGlobalUser]);



    //CREATE NOTE
    const [formDisabled, setFormDisabled] = useState(false);
    const [formMessage, setFormMessage] = useState('');
    const [collapseOpen, setCollapseOpen] = useState(false);
    const [values, setValues] = useState({ title: '', body: '', id: '', user: email });
    const { title, body } = values;

    const handleChange = (e) => {
        setMessage('');
        setFormMessage('');
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const createNote = () => {
        //validate
        if (!title || title.trim() === '') {
            setFormMessage('Title is required');
            setTimeout(() => {
                setFormMessage('');
                setFormDisabled(false);
            }, 2000);
            return;
        }
        if (!body || body.trim() === '') {
            setFormMessage('Note is required');
            setTimeout(() => {
                setFormMessage('');
                setFormDisabled(false);
            }, 2000);
            return;
        }

        //if global user
        if (isGlobalUser) {
            fetch(notesEndpoints.createNote, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({...values, user: email}),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data && data.error) {
                        setFormMessage(data.error);
                        setTimeout(() => {
                            setFormMessage('');
                        }, 2000);
                    } else {
                        setFormMessage('Note created');
                        setNotes([{ ...data }, ...notes]);
                        setValues({ title: '', body: '', id: '', user: email });
                        setTimeout(() => {
                            setCollapseOpen(false);
                            setFormMessage('');
                        }, 2000);
                    }
                    setFormDisabled(false);
                })
                .catch((error) => {
                    setFormMessage(JSON.stringify(error));
                    setFormDisabled(false);
                    setTimeout(() => {
                        setFormMessage('');
                    }, 2000);
                });
        //if local user
        } else {
            let existingNotes = localStorage.getItem('localUsersNotes') ? JSON.parse(localStorage.getItem('localUsersNotes')) : [];
            existingNotes.push({...values, user: email, id: Math.random()});
            localStorage.setItem('localUsersNotes', JSON.stringify(existingNotes));
            setNotes(existingNotes);
            setFormMessage('Local note created');
            setValues({ title: '', body: '', id: '', user: email });
            setFormDisabled(false);
            setTimeout(() => {
                setCollapseOpen(false);
                setFormMessage('');
            }, 2000);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formDisabled) return;
        setFormDisabled(true);

        if (updateOnSubmit) {
            setFormMessage('Updating note...');
            updateNote();
        } else {
            setFormMessage('Saving note...');
            createNote();
        }
    };

    const renderNoteForm = () => {
        return (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <FormGroup label={'Title'}>
                    <InputGroup
                        placeholder="Title (required)"
                        name="title"
                        value={title}
                        onChange={handleChange}
                        disabled={formDisabled}
                    />
                </FormGroup>

                <FormGroup label={'Note'}>
                    <TextArea
                        placeholder="Note (required)"
                        name="body"
                        value={body}
                        onChange={handleChange}
                        style={{ width: '100%', minHeight: '6rem' }}
                        disabled={formDisabled}
                    />
                </FormGroup>

                {formMessage ? (
                    <p style={{ textAlign: 'center', color: '#d33d17' }}>{formMessage}</p>
                ) : (
                    <Button text="Submit" style={{ width: '100%' }} type="submit" />
                )}
                <br />
            </form>
        );
    };



    //DELETE NOTE
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [toDeleteNoteId, setToDeleteNoteId] = useState(null);

    const confirmDelete = (noteId) => {
        setToDeleteNoteId(noteId);
        setDeleteConfirmOpen(true);
    };

    const deleteNote = (noteId) => {
        //checks
        if (formDisabled) return;
        if (!noteId) return console.log('No noteId passed to deleteNote()');

        //if user wants to delete active note
        if (activeNote && activeNote.id.toString() === noteId.toString()) {
            setDeleteConfirmOpen(false)
            setMessage('This note is active and cannot be deleted. Please deactivate the note first.');
            setTimeout(() => {setMessage('');}, 2000);
            return
        }

        //if user deletes while update form is open
        setUpdateOnSubmit(false);
        setCollapseOpen(false);
        setValues({ title: '', body: '', id: '', user: email })

        //start deleting
        setFormDisabled(true);
        setToDeleteNoteId(null);
        setMessage('Deleting Note...');
        setDeleteConfirmOpen(false);

        //if global user
        if (isGlobalUser) {
            fetch(notesEndpoints.deleteNote, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: noteId, user: email }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data && data.error) {
                        showMessage(data.error);
                        setFormDisabled(false);
                    } else {
                        let allNotes = [...notes];
                        let noteIdx = allNotes.findIndex((item) => item.id === noteId);
                        allNotes.splice(noteIdx, 1);
                        setNotes([...allNotes]);
                        showMessage('Note deleted');
                        setFormDisabled(false);
                    }
                })
                .catch((error) => {
                    showMessage(JSON.stringify(error));
                    setFormDisabled(false);
                });
        } 
        //if local user
        else {
            let allNotes = [...notes];
            let noteIdx = allNotes.findIndex((item) => item.id === noteId);
            allNotes.splice(noteIdx, 1);
            setNotes([...allNotes]);
            showMessage('Note deleted');
            setFormDisabled(false);
        }
    };



    //UPDATE NOTE
    const [updateOnSubmit, setUpdateOnSubmit] = useState(false);

    const startUpdating = (note) => {
        if (formDisabled) return;
        if (!note) return console.log('startUpdating() got no note');
        if (activeNote && activeNote.id.toString() === note.id.toString()) {
            showMessage('Active note cannot be edited. Please deactivate the note first');
            return
        }
        setValues({ ...note });
        setUpdateOnSubmit(true);
        setCollapseOpen(true);
    };

    const updateNote = () => {
        //validate
        if (!title || title.trim() === '') {
            setFormMessage('Title is required');
            setTimeout(() => {
                setFormMessage('');
                setFormDisabled(false);
            }, 2000);
            return;
        }

        if (!body || body.trim() === '') {
            setFormMessage('Note is required');
            setTimeout(() => {
                setFormMessage('');
                setFormDisabled(false);
            }, 2000);
            return;
        }

        //if global user
        if (isGlobalUser) {
            fetch(notesEndpoints.updateNote, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({...values, user: email}),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data && data.error) {
                        setFormMessage(data.error);
                        setTimeout(() => {
                            setFormMessage('');
                        }, 2000);
                    } else {
                        setFormMessage('Note updated');
                        let allNotes = [...notes];
                        let noteIdx = allNotes.findIndex((item) => item.id === values.id);
                        allNotes.splice(noteIdx, 1, values);
                        setNotes(allNotes);
                        setValues({ title: '', body: '', id: '', user: email });
                        setTimeout(() => {
                            setCollapseOpen(false);
                            setFormMessage('');
                            setUpdateOnSubmit(false);
                        }, 2000);
                    }
                    setFormDisabled(false);
                })
                .catch((error) => {
                    setFormMessage(JSON.stringify(error));
                    setFormDisabled(false);
                    setTimeout(() => {
                        setFormMessage('');
                    }, 2000);
                });
        } 
        //if local user
        else {
            setFormMessage('Note updated');
            let allNotes = [...notes];
            let noteIdx = allNotes.findIndex((item) => item.id === values.id);
            allNotes.splice(noteIdx, 1, values);
            setNotes(allNotes);
            setValues({ title: '', body: '', id: '', user: email });
            setFormDisabled(false);
            setTimeout(() => {
                setCollapseOpen(false);
                setFormMessage('');
                setUpdateOnSubmit(false);
            }, 2000);
        }
    }; //triggered in handleSubmit() if updateOnSubmit = true



    //DE/ACTIVATE NOTE
    const changeNoteStatus = async (note) => { //only one note can be active
        if (formDisabled) return;
        if (!note) return console.log('activateNote() got no note argument');
        setMessage('Activating note...');
        setFormDisabled(true);

        //globalUser
        if (isGlobalUser) {
            if (activeNote && activeNote.id.toString() === note.id.toString()) { //note was active
                changeUserSettings({activeNote: false});
                setMessage('Note deactivated');
                setTimeout(() => {setMessage(''); setFormDisabled(false);}, 2000);
            } else { //note wasn't active
                changeUserSettings({activeNote: note});
                setMessage('Note activated');
                setTimeout(() => {setMessage(''); setFormDisabled(false);}, 2000);
            }
        }
        //local user
        else {
            if (activeNote && activeNote.id.toString() === note.id.toString()) { //note was active
                changeUserSettings({activeNote: false});
                setMessage('Note deactivated');
                setTimeout(() => {setMessage(''); setFormDisabled(false);}, 2000);
            } else { //note wasn't active
                changeUserSettings({activeNote: note});
                setMessage('Note activated');
                setTimeout(() => {setMessage(''); setFormDisabled(false);}, 2000);
            }
        }
    };



    //NOTE ITEM RENDERER
    const renderNote = (note) => {
        if (!note && !note.id && !note.title && !note.body && !note.isActive)
            return console.log('renderNote() needs a note with id, isActive, title, and body');
        return (
            <div className="note-item" key={note.id}>
                <p className="note-item-title">{note.title.substring(0, 30)}</p>
                <p className="note-item-excerpt">{note.body.substring(0, 100)}...</p>
                <div className="note-item-buttons" style={{ color: activeNote && activeNote.id.toString() === note.id.toString() ? 'green' : '' }}>
                    <small
                        className="note-item-controls activate-btn"
                        onClick={() => {
                            changeNoteStatus(note);
                        }}
                    >
                        {activeNote && activeNote.id.toString() === note.id.toString() ? 'DEACTIVATE' : 'ACTIVATE'}
                    </small>
                    <Icon className="note-item-controls icon" icon="edit" onClick={() => startUpdating(note)} />
                    <Icon className="note-item-controls icon" icon="trash" onClick={() => confirmDelete(note.id)} />
                </div>
            </div>
        );
    };



    //RENDER
    return (
        <div
            style={{
                minHeight: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}
        >
            <h2 style={{ padding: 0, margin: 0 }}>NOTES</h2> <br />
            {message && <p style={{ color: '#d33d17', textAlign: 'center' }}>{message}</p>}
            <Button
                text={collapseOpen ? 'Close' : 'Add Note'}
                style={{ width: '260px' }}
                onClick={() => {
                    setCollapseOpen(!collapseOpen);
                    if (collapseOpen === true) {
                        setValues({ title: '', body: '', id: '', user: email });
                        setUpdateOnSubmit(false);
                    }
                }}
            />
            <br />
            <div style={{ width: '100%' }}>
                <Collapse isOpen={collapseOpen}>
                    {renderNoteForm()} <br />
                </Collapse>
            </div>
            <div className="notes-wrapper">
                {!notes || 
                    (notes && notes.length === 0 && !message && (
                        <p
                            style={{
                                padding: '0',
                                margin: '0',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            No notes
                        </p>
                    ))}
                {notes && notes.length > 0 && notes.map((note) => renderNote(note))}
            </div>
            <Dialog
                isOpen={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
                style={{ background: '#db3737', color: 'white' }}
            >
                <div
                    style={{
                        marginTop: '1.25rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <p style={{ textAlign: 'center', padding: 0, margin: 0 }}>
                        Are you sure you want to delete this note?
                    </p>
                    <div style={{ marginTop: '1rem' }}>
                        <span
                            onClick={() => deleteNote(toDeleteNoteId)}
                            style={{ margin: '1rem 1rem 1rem 0rem', cursor: 'pointer' }}
                        >
                            DELETE
                        </span>
                        <span
                            onClick={() => {
                                setToDeleteNoteId(null);
                                setDeleteConfirmOpen(false);
                            }}
                            style={{ margin: '1rem 1rem 1rem 0rem', cursor: 'pointer' }}
                        >
                            CANCEL
                        </span>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ManageNotesScreen;


*/