import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserData } from './utils/AmplifyUtils';

export const AuthContext = React.createContext();

const Auth = ({ children }) => {
    const [initialLoading, setInitialLoading] = useState(true);
    const [user, setUser] = useState(null);
    const location = useLocation();

    const setCurrentUser = (newUser) => {
        setUser(newUser);
    };

    const authenticate = async () => {
        getUserData()
            .then((data) => {
                setUser(data);
                setInitialLoading(false);
            })
            .catch((e) => {
                if (e === 'DISABLED') {
                    // TODO: SIGN OUT?
                }
                setUser(null);
                setInitialLoading(false);
            });
    };

    useEffect(() => {
        authenticate();
    }, []);

    if (initialLoading) {
        return <h4>Loading...</h4>;
    }

    const context = { user, setCurrentUser };

    return <AuthContext.Provider value={context}>{children(context.user, location.pathname)}</AuthContext.Provider>;
};

export default Auth;
