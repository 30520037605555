import { initStore } from './useStore';
import _ from 'lodash';

const configureStore = () => {
    const actions = {
        TOGGLE_LAYER: (curState, layerId) => {
            const isLayerPresent = _.indexOf(curState.layers, layerId);
            console.log(isLayerPresent);
            if (isLayerPresent === -1) {
                curState.layers = [...curState.layers, layerId];
            } else {
                curState.layers.splice(isLayerPresent, 1);
            }
        },
        CLEAR_LAYERS: () => {
            return { layers: [] };
        },
        CHANGE_TEXT_STYLE: (curState, isDarkTheme) => {
            const changedLayerStyles = { ...curState.layerStyles };

            if (isDarkTheme) {
                changedLayerStyles.busLayerStyle.paint['text-color'] = '#62442d';
                changedLayerStyles.busLayerStyle.paint['text-halo-color'] = 'rgba(255, 255, 255, 100)';

                changedLayerStyles.restaurantsLayerStyle.paint['text-color'] = '#62442d';
                changedLayerStyles.restaurantsLayerStyle.paint['text-halo-color'] = 'rgba(255, 255, 255, 100)';
            } else {
                changedLayerStyles.busLayerStyle.paint['text-color'] = '#ffffff';
                changedLayerStyles.busLayerStyle.paint['text-halo-color'] = 'rgba(0, 0, 0, 100)';

                changedLayerStyles.restaurantsLayerStyle.paint['text-color'] = '#ffffff';
                changedLayerStyles.restaurantsLayerStyle.paint['text-halo-color'] = 'rgba(0, 0, 0, 100)';
            }
            return { layerStyles: changedLayerStyles };
        },
    };

    initStore(actions, {
        layers: ['Home', 'poi'],
        layerStyles: {
            busLayerStyle: {
                id: 'bus',
                type: 'symbol',
                layout: {
                    'icon-image': 'dxc-bus',
                    'icon-size': 0.65,
                    'text-field': ['get', 'stop_name'],
                    'text-offset': [0, 1.4],
                    'text-justify': 'center',
                    'text-font': ['Open Sans Regular'],
                    'text-size': 14,
                },
                paint: {
                    'text-color': '#62442d',
                    'text-halo-color': 'rgba(255, 255, 255, 100)',
                    'text-halo-width': 0.5,
                    'text-halo-blur': 0.5,
                },
            },
            scootersLayerStyle: {
                id: 'scooters',
                type: 'symbol',
                layout: {
                    'icon-image': 'dxc-scooter',
                    'icon-size': 1,
                    'icon-allow-overlap': true,
                    'icon-anchor': 'bottom',
                },
            },
            restaurantsLayerStyle: {
                id: 'restaurants',
                type: 'symbol',
                layout: {
                    'icon-image': 'restaurant',
                    'icon-size': 1,
                    'text-field': ['get', 'name'],
                    'text-offset': [0, 1.4],
                    'text-justify': 'center',
                    'text-font': ['Open Sans Regular'],
                    'text-size': 14,
                },
                paint: {
                    'text-color': '#62442d',
                    'text-halo-color': 'rgba(255, 255, 255, 100)',
                    'text-halo-width': 0.5,
                    'text-halo-blur': 0.5,
                },
            },
        },
    });
};

export default configureStore;
