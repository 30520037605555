import * as KSUID from 'ksuid';
import { API, graphqlOperation } from 'aws-amplify';
import { upsertArea, upsertCustomer, upsertEntity, upsertLocation } from '../../../graphql/mutations';
import {
    getAreaById,
    getAreasByLocationId,
    getCustomerById,
    getCustomers as getCustomersSchema,
    getLocationByLocationId,
    getLocationsByCustomerId,
    getReactorsByLocationId,
    getEntitiesByLocationId,
} from '../../../graphql/queries';
// import dayjs from 'dayjs';
import { updatedEntity } from '../../../graphql/subscriptions';
import _ from 'lodash';

// Customer related calls
export const crateCustomer = (formValues, userId) => {
    return new Promise(function (resolve, reject) {
        const ksuidFromSync = KSUID.randomSync();

        API.graphql(
            graphqlOperation(upsertCustomer, {
                input: {
                    address: formValues.address,
                    admins: [formValues.admins],
                    email: formValues.email,
                    id: ksuidFromSync.string,
                    name: formValues.name,
                    // rowHistory: {
                    //     createdAt: dayjs().unix(),
                    //     updatedAt: null,
                    //     createdBy: userId,
                    //     updatedBy: null,
                    // },
                },
            }),
        )
            .then((res) => {
                return resolve(res?.data?.upsertCustomer);
            })
            .catch((e) => {
                return reject(e);
            });
    });
};

export const getCustomer = (customerId) => {
    return new Promise(function (resolve, reject) {
        console.log(customerId);
        API.graphql(graphqlOperation(getCustomerById, { CustomerId: customerId }))
            .then((res) => {
                return resolve(res);
            })
            .catch((e) => {
                return reject(e);
            });
    });
};

export const getCustomers = () => {
    return new Promise(function (resolve, reject) {
        API.graphql(graphqlOperation(getCustomersSchema))
            .then((res) => {
                return resolve(res.data.getCustomers);
            })
            .catch((e) => {
                return reject(e);
            });
    });
};

// Location related calls
export const createLocation = (formValues, customerId) => {
    return new Promise(function (resolve, reject) {
        const ksuidFromSync = KSUID.randomSync();

        API.graphql(
            graphqlOperation(upsertLocation, {
                input: {
                    coordinates: [formValues.latitude, formValues.longitude],
                    country: formValues.country,
                    city: formValues.city,
                    customer: customerId,
                    id: ksuidFromSync.string,
                    postcode: formValues.postcode,
                    shortcode: formValues.shortcode,
                    state: formValues.state,
                    street: formValues.street,
                    streetNo: formValues.streetNo,
                },
            }),
        )
            .then((res) => {
                return resolve(res?.data?.upsertLocation);
            })
            .catch((e) => {
                return reject(e);
            });
    });
};

export const getLocations = (customerId) => {
    return new Promise(function (resolve, reject) {
        API.graphql(graphqlOperation(getLocationsByCustomerId, { CustomerId: customerId }))
            .then((res) => {
                return resolve(res.data.getLocationsByCustomerId.locations);
            })
            .catch((e) => {
                return reject(e);
            });
    });
};

export const getLocationById = (locationId) => {
    return new Promise(function (resolve, reject) {
        API.graphql(graphqlOperation(getLocationByLocationId, { LocationId: locationId }))
            .then((res) => {
                return resolve(res.data.getLocationByLocationId);
            })
            .catch((e) => {
                return reject(e);
            });
    });
};

// Area related calls
export const createArea = (formValues, locationId) => {
    return new Promise(function (resolve, reject) {
        const ksuidFromSync = KSUID.randomSync();

        API.graphql(
            graphqlOperation(upsertArea, {
                input: {
                    code: formValues.code,
                    floor: formValues.floor,
                    id: ksuidFromSync.string,
                    locationId: locationId,
                    misc: formValues.misc,
                    name: formValues.name,
                    pic: formValues.pic,
                },
            }),
        )
            .then((res) => {
                return resolve(res?.data?.upsertArea);
            })
            .catch((e) => {
                return reject(e);
            });
    });
};

export const getAreas = (locationId) => {
    return new Promise(function (resolve, reject) {
        API.graphql(graphqlOperation(getAreasByLocationId, { LocationId: locationId }))
            .then((res) => {
                return resolve(res.data.getAreasByLocationId);
            })
            .catch((e) => {
                return reject(e);
            });
    });
};

export const getAreaByAreaId = (areaId) => {
    return new Promise(function (resolve, reject) {
        API.graphql(graphqlOperation(getAreaById, { AreaId: areaId }))
            .then((res) => {
                return resolve(res.data.getAreaById);
            })
            .catch((e) => {
                return reject(e);
            });
    });
};

// Reactor related calls
export const getReactors = (locationId) => {
    return new Promise(function (resolve, reject) {
        API.graphql(graphqlOperation(getReactorsByLocationId, { LocationId: locationId }))
            .then((res) => {
                return resolve(res.data.getReactorsByLocationId);
            })
            .catch((e) => {
                return reject(e);
            });
    });
};

// Entity related calls
export const getEntitiesByLocation = (locationId) => {
    return new Promise(function (resolve, reject) {
        API.graphql(graphqlOperation(getEntitiesByLocationId, { LocationId: locationId }))
            .then((res) => {
                return resolve(res.data.getEntitiesByLocationId);
            })
            .catch((e) => {
                return reject(e);
            });
    });
};

export const updateEntity = (num) => {
    //Only for test purposes so far
    return new Promise(function (resolve, reject) {
        let test = {
            dataType: 'double',
            id: num === 1 ? 'temp5284693' : 'mot5284693',
            isActive: true,
            lastValue: _.random(0, 40),
            measure: 'temperature',
            unit: 'celsius',
            device: JSON.stringify({
                status: 'online',
                area: 'Dedicated_05',
                hwProfile: 'equalis',
                id: 'EQB-5284693',
                serialNumber: '5284693',
            }),
        };
        API.graphql(graphqlOperation(upsertEntity, { input: test }))
            .then((res) => {
                return resolve(res);
            })
            .catch((e) => {
                return reject(e);
            });
    });
};

export const subscribeToEntity = (entityId) => {
    const subscription = API.graphql(graphqlOperation(updatedEntity, { id: entityId })).subscribe({
        next: ({ provider, value }) => console.log({ provider, value }),
        error: (error) => console.warn(error),
    });
    return subscription;
};
