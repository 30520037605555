import React, { useEffect, useContext, useState } from 'react';
//import '../../../node_modules/mapbox-gl/dist/mapbox-gl.css'; // this import is for mapbox-gl to work properly
//import 'mapbox-gl/dist/mapbox-gl.css';
import './style.scss';
import './icons.css';

import Main from './pages/Main';
import { AuthContext } from '../common/Auth';
import { getAreaByAreaId, getLocationById } from '../common/utils/API_calls';
export const AreaContext = React.createContext();

const IndexFloor = (props) => {
    const { user } = useContext(AuthContext);
    // Area object is also enriched by coordinates from current location.
    const [areaInfo, setAreaInfo] = useState(null);

    const setArea = (newArea) => {
        setAreaInfo(newArea);
    };

    useEffect(() => {
        //locationId will need to be changed to areaId in the future due to cognito pool changes.
        getAreaByAreaId(user.attributes['custom:areaId'])
            .then((areaData) => {
                getLocationById(areaData.locationId)
                    .then((locationData) => {
                        setAreaInfo({ ...areaData, coordinates: locationData.coordinates });
                    })
                    .catch((e) => {
                        setAreaInfo({ ...areaData });
                        console.log(e);
                    });
            })
            .catch((e) => {
                console.log(e);
            });
    }, [user.attributes]);

    const context = { areaInfo, setArea };

    return (
        <AreaContext.Provider value={context}>
            {
                <div>
                    <Main></Main>
                </div>
            }
        </AreaContext.Provider>
    );
};

export default IndexFloor;
