import PanelCard from './PanelCard'
import React from 'react'

const AreasPanel = () => {
  return (
    <PanelCard>
        Areas Panel
    </PanelCard>
  )
}

export default AreasPanel