import React from 'react';
import { Card, Elevation  } from '@blueprintjs/core';



const SampleWidget1 = (props) => {
    let widgetStyle = { backgroundColor: 'var(--app-black-transparent)', color: 'white' };
    if (Object.keys(props).length > 1) {
        widgetStyle = {
            gridColumnStart: props.positionx,
            gridColumnEnd: props.positionx + props.sizex,
            gridRowStart: props.positiony,
            gridRowEnd: props.positiony + props.sizey,
            overflowY: 'scroll',
            flexDirection: 'column',
        };
    } else {
        widgetStyle = {
            visibility: 'hidden',
        };
    }

    return (
        <Card className="floor-widget" elevation={Elevation.THREE} style={widgetStyle}>
            Widget Sample 1
        </Card>
    )
}

export default SampleWidget1