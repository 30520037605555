import React, { useState, useContext, useEffect, useRef } from 'react';
import UserSettingsContext from '../../../context/userSettingsContext';
import CustomToastContext from '../../../context/customToastProvider';
import { useStore } from '../../store/useStore';
import { Button, Collapse, Dialog, FormGroup, Icon, InputGroup, TextArea } from '@blueprintjs/core';
import './ManageVideosScreen.scss';



const apiEndpoints = {
    saveVideo: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/savevideo`,
    getVideos: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/getvideos`,
    deleteVideo: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/deletevideo`,
    updateVideo: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/updatevideo`,
    getUniqueId: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/getUniqueId`,
    getUploadPart: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/getUploadPart`,
    completeUpload: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/completeUpload`,
    abortUpload: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/abortUpload`
};



const ManageVideosScreen = ({ isDarkTheme }) => {
    //GLOBAL STATE
    const isGlobalUser = useStore()[0].userSettings.isGlobal;
    const { userSettings } = useContext(UserSettingsContext);
    const { toastState, changeToastState } = useContext(CustomToastContext);
    const { email } = userSettings;
    const {toastShown, progressShown, progressValue, toastText } = toastState;



    //GET ALL VIDEOS
    const [videos, setVideos] = useState([]);

    const getAllVideos = async () => {
        const videosRes = await fetch(apiEndpoints.getVideos, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({user: email})
        });
        const videosData = await videosRes.json();
        if (!videosData.videos) {
            console.log(videosData);
            setVideos([]);
            return
        }
        setVideos(videosData.videos);
    }
    
    useEffect(() => {
        getAllVideos();
    }, []);



    //VIDEOS LIST RENDERER
    const renderVideoList = () => {
        return (
            <React.Fragment>
                {videos 
                &&
                videos.map((v) => (
                    <div className="videos-list-line" key={v.id}>
                        <p className="video-name">{v.name}</p>
                        <p className="video-description">{v.description || 'No description provided'}</p>
                        {
                            isGlobalUser 
                            && 
                            <div className='video-buttons'>
                                <Icon
                                    icon="edit"
                                    className="video-controls-icon"
                                    onClick={() => openEditDialog(v)}
                                ></Icon>
                                <Icon
                                    icon="trash"
                                    className="video-controls-icon"
                                    onClick={() => confirmDelete(v.id)}
                                ></Icon>
                            </div>
                        }
                    </div>
                ))}
                {
                    videos && videos.length < 1 
                    && 
                    <p style={{
                        padding: '0',
                        margin: '0',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}>
                        No videos
                    </p>
                }
            </React.Fragment>
        )
    }



    //DELETE VIDEO
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [toDeleteVideoId, setToDeleteVideoId] = useState(null);
    const [message, setMessage] = useState('');
    const messageRef = useRef();

    const confirmDelete = (videoId) => {
        if (formDisabled) return;
        setToDeleteVideoId(videoId);
        setDeleteConfirmOpen(true);
    };

    const deleteVideo = (videoId) => {
        if (formDisabled) return;
        setFormDisabled(true);
        setToDeleteVideoId(null);
        setDeleteConfirmOpen(false);
        setCollapseOpen(false);
        setMessage('Deleting video...');

        fetch(apiEndpoints.deleteVideo, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: videoId, user: email }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (data && data.error) {
                    setMessage(data.error);
                    messageRef.current.scrollIntoView();
                    setFormDisabled(false);
                    setTimeout(() => {
                        setMessage('');
                    }, 2000);
                } else {
                    setMessage('Video deleted');
                    messageRef.current.scrollIntoView();
                    const videosBefore = [...videos];
                    const filteredVideos = videosBefore.filter((item) => item.id !== videoId);
                    const videoDeletedEvent = new CustomEvent('videodeleted', {detail: {videoId}}); //so Video.js knows of the deleted video
                    document.querySelector('body').dispatchEvent(videoDeletedEvent);
                    setVideos([...filteredVideos]);
                    setFormDisabled(false);
                    setTimeout(() => {
                        setMessage('');
                    }, 2000);
                }
            })
            .catch((error) => {
                console.log(error);
                setMessage(JSON.stringify(error));
                messageRef.current.scrollIntoView();
                setFormDisabled(false);
                setTimeout(() => {
                    setMessage('');
                }, 2000);
            });
    };
    
    
    
    //EDIT VIDEO
    const [editedVideo, setEditedVideo] = useState({ id: '', name: '', description: '', user: email });
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [videoUpdateMessage, setVideoUpdateMessage] = useState('');

    const openEditDialog = (videoToEdit) => {
        setEditedVideo({ ...videoToEdit });
        setEditDialogOpen(true);
    };

    const closeEditDialogue = () => {
        setEditedVideo({ id: '', name: '', description: '' });
        setEditDialogOpen(false);
    };

    const handleEditChange = (e) => {
        setEditedVideo({ ...editedVideo, [e.target.name]: e.target.value });
    };

    const updateVideo = () => {
        setVideoUpdateMessage('Updating video...');

        fetch(apiEndpoints.updateVideo, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user: email, id: editedVideo.id, name: editedVideo.name, description: editedVideo.description }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (data && data.error) {
                    setVideoUpdateMessage(data.error);
                    setTimeout(() => {
                        setVideoUpdateMessage('');
                    }, 2000);
                } else {
                    setVideoUpdateMessage('Video updated');
                    const videosBefore = [...videos];
                    const updatedVideoIndex = videosBefore.findIndex((item) => item.id === editedVideo.id);
                    videosBefore[updatedVideoIndex] = { ...editedVideo };
                    setVideos([...videosBefore]);
                    setTimeout(() => {
                        setVideoUpdateMessage('');
                        closeEditDialogue();
                    }, 2000);
                }
            })
            .catch((error) => {
                setVideoUpdateMessage(JSON.stringify(error));
                setTimeout(() => {
                    setVideoUpdateMessage('');
                }, 2000);
            });
    };



    //UPLOAD VIDEO
    const [values, setValues] = useState({name: '', description: '', user: email});
    const [formDisabled, setFormDisabled] = useState(false);
    const [collapseOpen, setCollapseOpen] = useState(false);
    const [formMessage, setFormMessage] = useState('');
    const [abortBtnShown, setAbortBtnShown] = useState('');

    const handleChange = e => {
        setFormMessage('');
        setValues({...values, [e.target.name]: e.target.value});
    }

    const readVideo = () => {
        //grab file and disable form
        const fileInput = document.getElementById('fileInput');
        if (formDisabled) return;
        if (!fileInput.files.length) return;

        //reset toast
        setFormDisabled(true);
        changeToastState({toastShown: false});
        changeToastState({progressShown: false});
        changeToastState({toastText: ''});
        changeToastState({progressValue: 0});
        
        //check the file is an .mp4
        setFormMessage('Checking file...');
        let reader = new FileReader();
        reader.onload = (e) => {
            if (!e.target.result.includes('data:video/mp4')) {
                setFormMessage('Please upload a supported .mp4 video file');
                setFormDisabled(false);
                setTimeout(() => {
                    setFormMessage('');
                }, 2000);
                return;
            }

            //if .mp4 start uploading
            uploadVideo(fileInput.files[0]);
        }
        reader.readAsDataURL(fileInput.files[0]);
    }

    const uploadVideo = async (file) => {
        const fileName = file.name;
        const fileSize = file.size;
        
        try {
            //get uploadId
            setFormMessage('Initializing upload...');
            const uploadIdRes = await fetch(apiEndpoints.getUniqueId, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({fileName})
            });
            const data = await uploadIdRes.json();
            if (!data.uploadId) throw new Error('Generating uploadId failed');
            const uploadId = data.uploadId;
            sessionStorage.setItem('uploadId', uploadId);
            sessionStorage.setItem('fileName', fileName);
            
            //split file into chunks
            setFormMessage('Uploading video...');
            setTimeout(()  => {setFormMessage(''); setCollapseOpen(false)}, 2000);
            changeToastState({toastShown: true});
            changeToastState({toastText: 'Uploading video...'});
            changeToastState({progressShown: true});
            setAbortBtnShown(true);
            const chunkSize = 10 * 1024 * 1024;
            const chunkCount = Math.floor(fileSize/chunkSize) + 1;
            let multiUploadArray = [];

            for (let uploadCount = 1; uploadCount < chunkCount+1; uploadCount++) {
                if (sessionStorage.getItem('uploadAborted')) throw new Error('Upload aborted');
                let start = (uploadCount - 1) * chunkSize;
                let end = uploadCount * chunkSize;
                let fileBlob = uploadCount < chunkCount ? file.slice(start, end) : file.slice(start);

                //get presigned url for each chunk
                let getSignedUrlRes = await fetch(apiEndpoints.getUploadPart, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({fileName, partNumber: uploadCount, uploadId})
                });
                if (!getSignedUrlRes.ok) throw new Error(`Getting preSignedUrl failed at part ${uploadCount}`);
                let preSignedUrlData = await getSignedUrlRes.json();
                if (!preSignedUrlData.preSignedUrl) throw new Error(`Getting preSignedUrl failed at part ${uploadCount}`);
                let preSignedUrl = preSignedUrlData.preSignedUrl;

                //put chunk into presigned url
                let uploadChunk = await fetch(preSignedUrl, {method: 'PUT', body: fileBlob});

                //get aws Etag header for each chunk
                let EtagHeader = uploadChunk.headers.get('ETag');
                let uploadDetails = {
                    ETag: EtagHeader,
                    PartNumber: uploadCount
                };
                multiUploadArray.push(uploadDetails);
                changeToastState({progressValue: uploadCount/chunkCount});
            }

            //complete upload
            changeToastState({toastText: 'Completing upload...'});
            changeToastState({progressShown: false});
            changeToastState({progressValue: 0});
            const completeUploadRes = await fetch(apiEndpoints.completeUpload, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({fileName, parts: multiUploadArray, uploadId})
            });
            const completeUpload = await completeUploadRes.json();
            if (!completeUpload.completeUpload?.Location) throw new Error('Completing upload failed');
            const videoUrl = completeUpload.completeUpload.Location;
             changeToastState({toastText: 'Upload completed. Saving...'})
            setAbortBtnShown(false);
            sessionStorage.removeItem('uploadId');

            //save {name, description, videoUrl, user} to DB
            const savedVideoRes = await fetch(apiEndpoints.saveVideo, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    name: values.name,
                    videoUrl,
                    description: values.description,
                    user: email
                }),
            });

            const savedVideo = await savedVideoRes.json();
            if (!savedVideo.id) throw new Error('Saving video failed');
            //setFormMessage('Video saved');
            changeToastState({toastShown: true});
            changeToastState({toastText: 'Video saved'});
            setValues({name: '', description: '', user: email});
            const videoUploadedEvent = new CustomEvent('videouploaded', {detail: {...savedVideo}}); //so Video.js knows of the new video
            document.querySelector('body').dispatchEvent(videoUploadedEvent);
            setVideos([{...savedVideo}, ...videos])
            setTimeout(() => {
                setFormMessage('');
                changeToastState({toastShown: false});
                changeToastState({toastText: ''});
                setFormDisabled(false);
                setCollapseOpen(false);
            }, 2000);

        } catch (error) {
            console.log(error);
            changeToastState({progressShown: false});
            changeToastState({toastShown: true});
            changeToastState({toastText: typeof error === 'string' ? error : 'Upload went wrong. Aborted.'});
            setAbortBtnShown(false);
            setFormDisabled(false);
            sessionStorage.removeItem('uploadId');
            sessionStorage.removeItem('fileName');
        }
    }



    //ABORT UPLOAD
    const abortUpload = async () => {
        if (sessionStorage.getItem('uploadId') && sessionStorage.getItem('fileName')) {
            const fileName = sessionStorage.getItem('fileName');
            const uploadId = sessionStorage.getItem('uploadId');
            const abortRes = await fetch(apiEndpoints.abortUpload, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({fileName, uploadId})
            })
            if (abortRes.ok) {
                changeToastState({toastText: 'Aborting upload. One moment please...'});
                changeToastState({progressShown: false})
                setAbortBtnShown(false);
                sessionStorage.removeItem('uploadId');
                sessionStorage.removeItem('fileName');
                setValues({name: '', description: '', user: email});
                setTimeout(() => {
                    setFormDisabled(false);
                    setCollapseOpen(false);
                }, 2000);
            } else {
                changeToastState({toastText: 'Upload abort failed'});
                setTimeout(() => {changeToastState({toastText: ''})}, 2000);
            }
            

        } else {
            changeToastState({toastText: 'Could not retrieve uploadId, cannot abort upload'});
            setTimeout(() => {changeToastState({toastText: ''})}, 2000);
        }
    }



    //UPLOAD FORM RENDERER
    const renderUploadForm = () => {
        if (!isGlobalUser) return;
        return (
            <form style={{ width: '100%' }}>
                <FormGroup label={'Name'}>
                    <InputGroup
                        placeholder="Name (required)"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        disabled={formDisabled}
                    />
                </FormGroup>

                <FormGroup label={'Description'}>
                    <TextArea
                        placeholder="Description (optional)"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        style={{ width: '100%', minHeight: '6rem' }}
                        disabled={formDisabled}
                    />
                </FormGroup>

                {values.name && (
                    <Button style={{ width: '100%' }}>
                        <label className="bp4-file-input .modifier" htmlFor="fileInput">
                            <input
                                disabled={formDisabled}
                                type="file"
                                id="fileInput"
                                name="fileInput"
                                onChange={readVideo}
                                hidden
                                style={{width: '100%', height: '100%', cursor: 'pointer'}}
                            />
                            <span className="bp4-file-upload-input">Choose video...</span>
                        </label>
                    </Button>
                )}

                {abortBtnShown && <Button text="Abort Upload" style={{width: '100%', marginTop: '0.5rem'}} onClick={abortUpload} />}
                {formMessage ? <p style={{ marginTop: '1rem', color: '#d33d17', textAlign: 'center' }}>{formMessage}</p> : <p style={{ marginTop: '1rem', color: '#d33d17', textAlign: 'center' }}>{' '}</p>}
            </form>
        );
    };



    //RENDER
    return (
        //LAYOUT
        <div
            style={{
                minHeight: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}
        >
            <h2 style={{ padding: 0, margin: 0 }}>VIDEOS</h2> <br />
            


            {/* UPLOAD FORM */}
            {
                isGlobalUser
                &&
                <React.Fragment>
                    <Button
                        text={formDisabled ? 'Uploading Video...' : collapseOpen ? 'Close' : 'Upload Video'}
                        style={{ width: '260px' }}
                        onClick={() => {
                            setCollapseOpen(!collapseOpen);
                            if (collapseOpen === true) {
                                setValues({ name: '', description: '', user: email });
                            }
                        }}
                        disabled={formDisabled}
                    />
                    <br />
                    <div style={{ width: '100%' }}>
                        <Collapse isOpen={collapseOpen}>
                            {renderUploadForm()} <br />
                        </Collapse>
                    </div>
                </React.Fragment>
            }



            {/* VIDEO LIST */}
            <p ref={messageRef} style={{ color: '#d33d17', width: '100%', textAlign: 'center', margin: '0.5rem 0', padding: '0' }}>{message || ' '}</p>
            <div className='videos-list-wrapper'>
                {renderVideoList()}
            </div>



            {/* CONFIRM DELETE DIALOG */}
            <Dialog
                isOpen={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
                style={{ background: '#db3737', color: 'white' }}
            >
                <div
                    style={{
                        marginTop: '1.25rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <p style={{ textAlign: 'center', padding: 0, margin: 0 }}>
                        Are you sure you want to delete the video?
                    </p>
                    <div style={{ marginTop: '1rem' }}>
                        <span
                            onClick={() => deleteVideo(toDeleteVideoId)}
                            style={{ margin: '1rem 1rem 1rem 0rem', cursor: 'pointer' }}
                        >
                            DELETE
                        </span>
                        <span
                            onClick={() => {
                                setToDeleteVideoId(null);
                                setDeleteConfirmOpen(false);
                            }}
                            style={{ margin: '1rem 1rem 1rem 0rem', cursor: 'pointer' }}
                        >
                            CANCEL
                        </span>
                    </div>
                </div>
            </Dialog>



            {/* EDIT VIDEO DIALOG */}
            <Dialog className={isDarkTheme && 'bp3-dark'} isOpen={editDialogOpen} onClose={closeEditDialogue}>
                <div style={{ padding: '1rem' }}>
                    <FormGroup label={'New Name'}>
                        <InputGroup
                            placeholder="Name (required)"
                            name="name"
                            value={editedVideo.name}
                            onChange={handleEditChange}
                            disabled={formDisabled}
                        />
                    </FormGroup>

                    <FormGroup label={'New Description'}>
                        <TextArea
                            placeholder="Description (optional)"
                            name="description"
                            value={editedVideo.description}
                            onChange={handleEditChange}
                            style={{ width: '100%' }}
                            disabled={formDisabled}
                        />
                    </FormGroup>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Button onClick={updateVideo} style={{ margin: '0.5rem' }}>
                            Update
                        </Button>
                        <Button onClick={closeEditDialogue} style={{ margin: '0.5rem' }}>
                            Cancel
                        </Button>
                    </div>

                    {videoUpdateMessage && (
                        <p style={{ color: '#d33d17', textAlign: 'center', marginTop: '1rem' }}>{videoUpdateMessage}</p>
                    )}
                </div>
            </Dialog>
        </div>
    );
};

export default ManageVideosScreen;


















/*
import React, { useState, useContext, useEffect, useRef } from 'react';
import UserSettingsContext from '../../../context/userSettingsContext';
import { useStore } from '../../store/useStore';
import { Button, Collapse, Dialog, FormGroup, Icon, InputGroup, TextArea } from '@blueprintjs/core';
import './ManageVideosScreen.scss';



const apiEndpoints = {
    saveVideo: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/savevideo`,
    getVideos: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/getvideos`,
    deleteVideo: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/deletevideo`,
    updateVideo: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/updatevideo`,
    getUniqueId: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/getUniqueId`,
    getUploadPart: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/getUploadPart`,
    completeUpload: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/completeUpload`,
    abortUpload: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/abortUpload`
};





const ManageVideosScreen = ({ isDarkTheme }) => {
    //GLOBAL STATE
    const isGlobalUser = useStore()[0].userSettings.isGlobal;
    const { userSettings } = useContext(UserSettingsContext);
    const { email } = userSettings;



    //GET ALL VIDEOS
    const [videos, setVideos] = useState([]);

    const getAllVideos = async () => {
        const videosRes = await fetch(apiEndpoints.getVideos, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({user: email})
        });
        const videosData = await videosRes.json();
        if (!videosData.videos) {
            console.log(videosData);
            setVideos([]);
            return
        }
        setVideos(videosData.videos);
    }
    
    useEffect(() => {
        getAllVideos();
    }, []);



    //VIDEOS LIST RENDERER
    const renderVideoList = () => {
        return (
            <React.Fragment>
                {videos 
                &&
                videos.map((v) => (
                    <div className="videos-list-line" key={v.id}>
                        <p className="video-name">{v.name}</p>
                        <p className="video-description">{v.description || 'No description provided'}</p>
                        <div className='video-buttons'>
                            <Icon
                                icon="edit"
                                className="video-controls-icon"
                                onClick={() => openEditDialog(v)}
                            ></Icon>
                            <Icon
                                icon="trash"
                                className="video-controls-icon"
                                onClick={() => confirmDelete(v.id)}
                            ></Icon>
                        </div>
                    </div>
                ))}
                {
                    videos && videos.length < 1 
                    && 
                    <p style={{
                        padding: '0',
                        margin: '0',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}>
                        No videos
                    </p>
                }
            </React.Fragment>
        )
    }



    //DELETE VIDEO
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [toDeleteVideoId, setToDeleteVideoId] = useState(null);
    const [message, setMessage] = useState('');
    const messageRef = useRef();

    const confirmDelete = (videoId) => {
        if (formDisabled) return;
        setToDeleteVideoId(videoId);
        setDeleteConfirmOpen(true);
    };

    const deleteVideo = (videoId) => {
        if (formDisabled) return;
        setFormDisabled(true);
        setToDeleteVideoId(null);
        setDeleteConfirmOpen(false);
        setCollapseOpen(false);
        setMessage('Deleting video...');

        fetch(apiEndpoints.deleteVideo, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: videoId, user: email }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (data && data.error) {
                    setMessage(data.error);
                    messageRef.current.scrollIntoView();
                    setFormDisabled(false);
                    setTimeout(() => {
                        setMessage('');
                    }, 2000);
                } else {
                    setMessage('Video deleted');
                    messageRef.current.scrollIntoView();
                    const videosBefore = [...videos];
                    const filteredVideos = videosBefore.filter((item) => item.id !== videoId);
                    const videoDeletedEvent = new CustomEvent('videodeleted', {detail: {videoId}}); //so Video.js knows of the deleted video
                    document.querySelector('body').dispatchEvent(videoDeletedEvent);
                    setVideos([...filteredVideos]);
                    setFormDisabled(false);
                    setTimeout(() => {
                        setMessage('');
                    }, 2000);
                }
            })
            .catch((error) => {
                console.log(error);
                setMessage(JSON.stringify(error));
                messageRef.current.scrollIntoView();
                setFormDisabled(false);
                setTimeout(() => {
                    setMessage('');
                }, 2000);
            });
    };
    
    
    
        //EDIT VIDEO
        const [editedVideo, setEditedVideo] = useState({ id: '', name: '', description: '', user: email });
        const [editDialogOpen, setEditDialogOpen] = useState(false);
        const [videoUpdateMessage, setVideoUpdateMessage] = useState('');
    
        const openEditDialog = (videoToEdit) => {
            setEditedVideo({ ...videoToEdit });
            setEditDialogOpen(true);
        };
    
        const closeEditDialogue = () => {
            setEditedVideo({ id: '', name: '', description: '' });
            setEditDialogOpen(false);
        };
    
        const handleEditChange = (e) => {
            setEditedVideo({ ...editedVideo, [e.target.name]: e.target.value });
        };
    
        const updateVideo = () => {
            setVideoUpdateMessage('Updating video...');
    
            fetch(apiEndpoints.updateVideo, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user: email, id: editedVideo.id, name: editedVideo.name, description: editedVideo.description }),
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (data && data.error) {
                        setVideoUpdateMessage(data.error);
                        setTimeout(() => {
                            setVideoUpdateMessage('');
                        }, 2000);
                    } else {
                        setVideoUpdateMessage('Video updated');
                        const videosBefore = [...videos];
                        const updatedVideoIndex = videosBefore.findIndex((item) => item.id === editedVideo.id);
                        videosBefore[updatedVideoIndex] = { ...editedVideo };
                        setVideos([...videosBefore]);
                        setTimeout(() => {
                            setVideoUpdateMessage('');
                            closeEditDialogue();
                        }, 2000);
                    }
                })
                .catch((error) => {
                    setVideoUpdateMessage(JSON.stringify(error));
                    setTimeout(() => {
                        setVideoUpdateMessage('');
                    }, 2000);
                });
        };



    //UPLOAD VIDEO
    const [values, setValues] = useState({name: '', description: '', user: email});
    const [formDisabled, setFormDisabled] = useState(false);
    const [collapseOpen, setCollapseOpen] = useState(false);
    const [formMessage, setFormMessage] = useState('');
    const [abortBtnShown, setAbortBtnShown] = useState('');

    const handleChange = e => {
        setFormMessage('');
        setValues({...values, [e.target.name]: e.target.value});
    }

    const readVideo = () => {
        const fileInput = document.getElementById('fileInput');
        if (formDisabled) return;
        if (!fileInput.files.length) return;
        setFormDisabled(true);
        
        //check the file is an .mp4
        setFormMessage('Checking file...');
        let reader = new FileReader();
        reader.onload = (e) => {
            if (!e.target.result.includes('data:video/mp4')) {
                setFormMessage('Please upload a supported .mp4 video file');
                setFormDisabled(false);
                setTimeout(() => {
                    setFormMessage('');
                }, 2000);
                return;
            }

            //if .mp4 start uploading
            uploadVideo(fileInput.files[0]);
        }
        reader.readAsDataURL(fileInput.files[0]);
    }

    const uploadVideo = async (file) => {
        const fileName = file.name;
        const fileSize = file.size;
        
        try {
            //get uploadId
            setFormMessage('Initializing upload...')
            const uploadIdRes = await fetch(apiEndpoints.getUniqueId, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({fileName})
            });
            const data = await uploadIdRes.json();
            if (!data.uploadId) throw new Error('Generating uploadId failed');
            const uploadId = data.uploadId;
            sessionStorage.setItem('uploadId', uploadId);
            
            //split file into chunks
            setFormMessage('Splitting file...');
            setAbortBtnShown(true);
            const chunkSize = 10 * 1024 * 1024;
            const chunkCount = Math.floor(fileSize/chunkSize) + 1;
            let multiUploadArray = [];

            for (let uploadCount = 1; uploadCount < chunkCount+1; uploadCount++) {
                if (sessionStorage.getItem('uploadAborted')) throw new Error('Upload aborted');
                let start = (uploadCount - 1) * chunkSize;
                let end = uploadCount * chunkSize;
                let fileBlob = uploadCount < chunkCount ? file.slice(start, end) : file.slice(start);

                //get presigned url for each chunk
                let getSignedUrlRes = await fetch(apiEndpoints.getUploadPart, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({fileName, partNumber: uploadCount, uploadId})
                });
                if (!getSignedUrlRes.ok) throw new Error(`Getting preSignedUrl failed at part ${uploadCount}`);
                let preSignedUrlData = await getSignedUrlRes.json();
                if (!preSignedUrlData.preSignedUrl) throw new Error(`Getting preSignedUrl failed at part ${uploadCount}`);
                let preSignedUrl = preSignedUrlData.preSignedUrl;

                //put chunk into presigned url
                let uploadChunk = await fetch(preSignedUrl, {method: 'PUT', body: fileBlob});

                //get aws Etag header for each chunk
                let EtagHeader = uploadChunk.headers.get('ETag');
                let uploadDetails = {
                    ETag: EtagHeader,
                    PartNumber: uploadCount
                };
                multiUploadArray.push(uploadDetails);
                if (!sessionStorage.getItem('uploadAborted')) setFormMessage(`${uploadCount}/${chunkCount} ready...`);
            }

            //complete upload
            setFormMessage(`Completing upload`);
            const completeUploadRes = await fetch(apiEndpoints.completeUpload, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({fileName, parts: multiUploadArray, uploadId})
            });
            const completeUpload = await completeUploadRes.json();
            if (!completeUpload.completeUpload?.Location) throw new Error('Completing upload failed');
            const videoUrl = completeUpload.completeUpload.Location;
            setFormMessage('Upload Completed, saving...');
            setAbortBtnShown(false);
            sessionStorage.removeItem('uploadId');

            //save {name, description, videoUrl, user} to DB
            const savedVideoRes = await fetch(apiEndpoints.saveVideo, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    name: values.name,
                    videoUrl,
                    description: values.description,
                    user: email
                }),
            });

            const savedVideo = await savedVideoRes.json();
            if (!savedVideo.id) throw new Error('Saving video failed');
            setFormMessage('Video saved');
            setValues({name: '', description: '', user: email});
            const videoUploadedEvent = new CustomEvent('videouploaded', {detail: {...savedVideo}}); //so Video.js knows of the new video
            document.querySelector('body').dispatchEvent(videoUploadedEvent);
            setVideos([{...savedVideo}, ...videos])
            setTimeout(() => {
                setFormMessage('');
                setFormDisabled(false);
                setCollapseOpen(false);
            }, 2000);

        } catch (error) {
            console.log(error);
            setFormMessage(typeof error === 'string' ? error : 'Upload went wrong. Aborted.');
            setTimeout(() => {setFormMessage('')}, 2000);
            setAbortBtnShown(false);
            setFormDisabled(false);
            sessionStorage.removeItem('uploadId');
        }
    }



    //ABORT UPLOAD
    const abortUpload = async () => {
        if (sessionStorage.getItem('uploadId')) {
            const fileInput = document.getElementById('fileInput');
            const file = fileInput.files[0]
            const fileName = file.name;
            const uploadId = sessionStorage.getItem('uploadId');
            const abortRes = await fetch(apiEndpoints.abortUpload, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({fileName, uploadId})
            })
            if (abortRes.ok) {
                sessionStorage.setItem('uploadAborted', 'true');
                setFormMessage('Aborting upload...');
                setAbortBtnShown(false);
                sessionStorage.removeItem('uploadId');
                setValues({name: '', description: '', user: email});
                setTimeout(() => {
                    setFormMessage('');
                    setFormDisabled(false);
                    setCollapseOpen(false);
                    sessionStorage.removeItem('uploadAborted');
                }, 2000);
            } else {
                setFormMessage('Upload abort failed');
                sessionStorage.removeItem('uploadAborted');
                setTimeout(() => {setFormMessage('')}, 2000);
            }
            

        } else {
            setFormMessage('Could not retrieve uploadId, cannot abort upload');
            setTimeout(() => {setFormMessage('')}, 2000);
        }
    }



    //UPLOAD FORM RENDERER
    const renderUploadForm = () => {
        return (
            <form style={{ width: '100%' }}>
                <FormGroup label={'Name'}>
                    <InputGroup
                        placeholder="Name (required)"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        disabled={formDisabled}
                    />
                </FormGroup>

                <FormGroup label={'Description'}>
                    <TextArea
                        placeholder="Description (optional)"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        style={{ width: '100%', minHeight: '6rem' }}
                        disabled={formDisabled}
                    />
                </FormGroup>

                {values.name && (
                    <Button style={{ width: '100%' }}>
                        <label className="bp4-file-input .modifier" htmlFor="fileInput">
                            <input
                                disabled={formDisabled}
                                type="file"
                                id="fileInput"
                                name="fileInput"
                                onChange={readVideo}
                                hidden
                                style={{width: '100%', height: '100%', cursor: 'pointer'}}
                            />
                            <span className="bp4-file-upload-input">Choose video...</span>
                        </label>
                    </Button>
                )}

                {abortBtnShown && <Button text="Abort Upload" style={{width: '100%', marginTop: '0.5rem'}} onClick={abortUpload} />}
                {formMessage ? <p style={{ marginTop: '1rem', color: '#d33d17', textAlign: 'center' }}>{formMessage}</p> : <p style={{ marginTop: '1rem', color: '#d33d17', textAlign: 'center' }}>{' '}</p>}
            </form>
        );
    };



    //RENDER
    return (
        //LAYOUT
        <div
            style={{
                minHeight: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}
        >
            <h2 style={{ padding: 0, margin: 0 }}>VIDEOS</h2> <br />
            


            <Button
                text={collapseOpen ? 'Close' : 'Upload Video'}
                style={{ width: '260px' }}
                onClick={() => {
                    setCollapseOpen(!collapseOpen);
                    if (collapseOpen === true) {
                        setValues({ name: '', description: '', user: email });
                    }
                }}
            />
            <br />
            <div style={{ width: '100%' }}>
                <Collapse isOpen={collapseOpen}>
                    {renderUploadForm()} <br />
                </Collapse>
            </div>



             <p ref={messageRef} style={{ color: '#d33d17', width: '100%', textAlign: 'center', margin: '0.5rem 0', padding: '0' }}>{message || ' '}</p>
            <div className='videos-list-wrapper'>
                {renderVideoList()}
            </div>



            <Dialog
                isOpen={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
                style={{ background: '#db3737', color: 'white' }}
            >
                <div
                    style={{
                        marginTop: '1.25rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <p style={{ textAlign: 'center', padding: 0, margin: 0 }}>
                        Are you sure you want to delete the video?
                    </p>
                    <div style={{ marginTop: '1rem' }}>
                        <span
                            onClick={() => deleteVideo(toDeleteVideoId)}
                            style={{ margin: '1rem 1rem 1rem 0rem', cursor: 'pointer' }}
                        >
                            DELETE
                        </span>
                        <span
                            onClick={() => {
                                setToDeleteVideoId(null);
                                setDeleteConfirmOpen(false);
                            }}
                            style={{ margin: '1rem 1rem 1rem 0rem', cursor: 'pointer' }}
                        >
                            CANCEL
                        </span>
                    </div>
                </div>
            </Dialog>



            <Dialog className={isDarkTheme && 'bp3-dark'} isOpen={editDialogOpen} onClose={closeEditDialogue}>
                <div style={{ padding: '1rem' }}>
                    <FormGroup label={'New Name'}>
                        <InputGroup
                            placeholder="Name (required)"
                            name="name"
                            value={editedVideo.name}
                            onChange={handleEditChange}
                            disabled={formDisabled}
                        />
                    </FormGroup>

                    <FormGroup label={'New Description'}>
                        <TextArea
                            placeholder="Description (optional)"
                            name="description"
                            value={editedVideo.description}
                            onChange={handleEditChange}
                            style={{ width: '100%' }}
                            disabled={formDisabled}
                        />
                    </FormGroup>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Button onClick={updateVideo} style={{ margin: '0.5rem' }}>
                            Update
                        </Button>
                        <Button onClick={closeEditDialogue} style={{ margin: '0.5rem' }}>
                            Cancel
                        </Button>
                    </div>

                    {videoUpdateMessage && (
                        <p style={{ color: '#d33d17', textAlign: 'center', marginTop: '1rem' }}>{videoUpdateMessage}</p>
                    )}
                </div>
            </Dialog>
        </div>
    );
};

export default ManageVideosScreen;

*/