import React, { useState, useEffect, useContext } from 'react';
import { Alignment, Navbar, Tag, Position } from '@blueprintjs/core';
import { generateAvatar } from '../../utils/GeneralUtils';
import './style.css';
import ReactLoading from 'react-loading';
import Menu from '../menu/Menu';
import { AuthContext } from '../../Auth';
import { signOutUser } from '../../utils/AmplifyUtils';
import { isStringEmpty } from '../../utils/GeneralUtils';
// import TextLink from '../text-link';

const NavBar = (props) => {
    const isLoading = useState(false)[0];
    const [profileImage, setProfileImage] = useState(null);
    const setIsProd = useState(false)[1];
    const { user, setCurrentUser } = useContext(AuthContext);

    const handleLogOut = () => {
        signOutUser()
            .then(() => {
                setCurrentUser(null);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        generateAvatar(
            user != null && user.attributes != null && !isStringEmpty(user.attributes.name)
                ? user.attributes.name
                : 'Default user',
        )
            .then((svg) => {
                setProfileImage(svg);
            })
            .catch((e) => {
                console.log(e);
            });
    }, [user]);

    if (process.env.REACT_APP_STAGE === 'prod') {
        setIsProd((prevProd) => !prevProd);
    }

    const menu_items = [
        {
            divider: {
                title:
                    user != null && user.attributes != null && !isStringEmpty(user.attributes.name)
                        ? user.attributes.name
                        : 'Default user',
            },
        },
        {
            item: {
                text: 'Sign out',
                icon: 'log-out',
                on_click: handleLogOut,
            },
        },
    ];

    if (isLoading) {
        return (
            <Navbar className="common-navbar bp3-navbar" fixedToTop tabIndex={-1}>
                <Navbar.Group align={Alignment.LEFT}>
                    LOGO HERE
                    <span className="bp3-navbar-heading" tabIndex={-1}>
                        SCOOT
                    </span>
                    <Tag tabIndex={-1} style={{ display: 'none' }} minimal={true} className="badge">
                        {process.env.REACT_APP_STAGE}
                    </Tag>
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <ReactLoading type="spin" color="white" height={32} width={32} />
                </Navbar.Group>
            </Navbar>
        );
    } else {
        return (
            <Navbar className="common-navbar bp3-navbar" fixedToTop tabIndex={-1}>
                <Navbar.Group align={Alignment.LEFT}>
                    LOGO HERE
                    <span className="bp3-navbar-heading" tabIndex={-1}>
                        SCOOT
                    </span>
                    <Tag tabIndex={-1} style={{ display: 'none' }} minimal={true} className="badge">
                        {process.env.REACT_APP_STAGE}
                    </Tag>
                </Navbar.Group>
                <div className="navBarHeader" tabIndex={-1}>
                    {props.header}
                </div>
                <Navbar.Group align={Alignment.RIGHT}>
                    <Menu
                        menu_items={menu_items}
                        menu_position={Position.BOTTOM_LEFT}
                        menu_icon={
                            <img className="navbar-avatar" src={profileImage} alt="Profile" width={32} height={32} />
                        }
                    />
                </Navbar.Group>
            </Navbar>
        );
    }
};

export default NavBar;
