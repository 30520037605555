import React, { useState, useContext, useEffect } from 'react';
import FullCenterView from '../components/card-styles/FullCenterView';
import _ from 'lodash';
import Map from '../../common/components/map/Map';
import { AreaContext } from '../indexFloor';
import { useStore } from '../store/useStore';
import FullCenterViewFill from '../components/card-styles/FullCenterViewFill';
import MapNavbar from '../../common/components/map/MapNavbar';

// Structure of data objects sent to map component

// const INITIAL_VIEWPORT = {
//     longitude: -56.164532,
//     latitude: -34.901112,
// };

// const markers = [
//     {
//         longitude: -56.16,
//         latitude: -34.89,
//         text: 'Test location 1',
//     },
// ];

const ScootMap = React.memo(function (props) {
    const [initialViewport, setInitialViewport] = useState(null);
    const [markers, setMarkers] = useState();
    const { areaInfo } = useContext(AreaContext);
    const globalStore = useStore()[0];

    useEffect(() => {
        let markers = [];

        _.forEach(globalStore.publicTransport, (transport) => {
            if (transport.toggleTransport) {
                _.forEach(transport.stations, (station) => {
                    markers.push({
                        latitude: +station.lat,
                        longitude: +station.lng,
                        stopName: station.vehicleStopName,
                        stopId: station.vehicleStopId,
                        transportType: transport.transportType,
                    });
                });
            }
        });
        setMarkers([...markers]);
    }, [globalStore]);

    useEffect(() => {
        if (areaInfo != null) {
            if (areaInfo.coordinates && areaInfo.coordinates.length > 0) {
                setInitialViewport({
                    longitude: Number.parseFloat(areaInfo.coordinates[1]),
                    latitude: Number.parseFloat(areaInfo.coordinates[0]),
                });
            }
        }
    }, [areaInfo]);

    return (
        <FullCenterView alternative={true}>
            <FullCenterViewFill>
                <Map init_viewport={initialViewport} markers={markers} isDarkTheme={props.isDarkTheme}></Map>
                <MapNavbar></MapNavbar>
            </FullCenterViewFill>
        </FullCenterView>
    );
});

export default ScootMap;
