import React, { useState } from 'react';
import { Navbar, Collapse, Icon, Alignment } from '@blueprintjs/core';
import NavbarButtons from './NavbarButtons';

const MapNavbar = function () {
    const [isOpen, setIsOpen] = useState(false);
    const mapNavbarStyle = {
        height: '100%',
        width: isOpen ? '10%' : '4%',
        position: 'absolute',
        top: '0',
        right: '0',
        cursor: 'default',
    };

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Navbar style={mapNavbarStyle} align={Alignment.CENTER}>
            <div style={{ height: '0.5rem' }} />
            <Icon
                onClick={handleClick}
                icon={isOpen ? 'menu-closed' : 'menu'}
                iconSize={30}
                style={{ cursor: 'pointer' }}
            />
            <Collapse isOpen={isOpen}>
                <NavbarButtons />
            </Collapse>
        </Navbar>
    );
};

export default MapNavbar;
