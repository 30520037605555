import React from 'react';
import { useState, useEffect, useCallback, useContext } from 'react';
import { Button, ProgressBar } from '@blueprintjs/core';
import General from '../pages/General';
import Video from '../pages/Video';
import ScootMap from '../pages/ScootMap';
import Sensors from '../pages/Sensors';
import Weather from '../pages/Weather';
import Poster from '../pages/Poster';
import UserSettingsContext from '../../context/userSettingsContext';

//initialize store

import configureMapGeojson from '../store/map-geojson';
import configureLayersStore from '../store/map-layers';
import configureUserSettings from '../store/user-settings';
import configureWidgetDataStorage from '../store/widget-data-storage';
// import { useStore } from '../store/useStore';

configureMapGeojson();
configureLayersStore();
configureUserSettings();
configureWidgetDataStorage();

function Center(props) {
    // const globalStore = useStore()[0];
    // const presentTime = globalStore.presentSettings.presentTime.time;
    // const presentStarted = globalStore.presentSettings.playStarted;

    //!!!!
    const { userSettings } = useContext(UserSettingsContext);
    const presentTime = userSettings.presentSettings.presentTime.time;
    const presentStarted = userSettings.presentSettings.playStarted;

    const [vidDur, setVidDur] = useState(null);
    const [currentButton, setCurrentButton] = useState(1);
    const [isHovered, setIsHovered] = useState(false);
    const [progressBarValue, setProgressBarValue] = useState(0);

    const getVideoDuration = function (curVid) {
        curVid.addEventListener('loadeddata', (e) => setVidDur(e.target.duration));
    };
    const scootPresent = useCallback(
        function (button) {
            const theButton = document.querySelector(`[value="${button}"]`);
            const isNextButton = document.querySelector(`[value="${button + 1}"]`);

            theButton.click();
            setProgressBarValue(presentTime);
            setCurrentButton((prev) => (isNextButton ? ++prev : 0));
        },
        [presentTime],
    );
    useEffect(() => {
        let timeout;
        let updateTimer;
        if (presentStarted & (currentButton === 4) & (vidDur !== null)) {
            setProgressBarValue(vidDur);
            timeout = setInterval(() => {
                scootPresent(currentButton);
            }, vidDur * 1000);
            updateTimer = setInterval(() => {
                setProgressBarValue((prev) => {
                    return (prev = prev - 0.1);
                });
            }, 100);
        }
        if (presentStarted & (currentButton !== 4)) {
            setProgressBarValue(presentTime);
            timeout = setInterval(() => {
                scootPresent(currentButton);
            }, presentTime * 1000);
            updateTimer = setInterval(() => {
                setProgressBarValue((prev) => {
                    return (prev = prev - 0.1);
                });
            }, 100);
        }
        return () => {
            clearInterval(updateTimer);
            clearInterval(timeout);
        };
    }, [presentStarted, currentButton, presentTime, scootPresent, vidDur]);

    const onNextClickHandler = function () {
        scootPresent(currentButton);
    };

    let view = parseInt(props.navbar);

    return (
        <React.Fragment>
            <div className="floor-center">
                {view === 0 && <General />}
                {view === 1 && <ScootMap isDarkTheme={props.isDarkTheme} />}
                {view === 2 && <Sensors />}
                {view === 3 && <Video isDarkTheme={props.isDarkTheme} getVideoDuration={getVideoDuration} />}
                {view === 4 && <Weather isDarkTheme={props.isDarkTheme} />}{' '}
                {/* if you change the order and Weather is not view number 4 anymore change the view number in `goToWeatherScreen` in TopNavbar.js too!  */}
                {view === 5 && <Poster isDarkTheme={props.isDarkTheme} />}
            </div>
            {presentStarted && (
                <ProgressBar
                    stripes={false}
                    value={currentButton !== 4 ? progressBarValue / presentTime : progressBarValue / vidDur}
                    className="present-progress-bar"
                />
            )}
            {presentStarted && (
                <Button
                    className={isHovered ? 'floor-center-button' : 'floor-center-button-hover'}
                    onClick={onNextClickHandler}
                    icon={isHovered ? 'arrow-right' : null}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                />
            )}
        </React.Fragment>
    );
}

export default Center;
