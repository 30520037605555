/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteAction = /* GraphQL */ `
  mutation DeleteAction($input: DeleteEntityInput!) {
    deleteAction(input: $input)
  }
`;
export const deleteAnalyticsQuery = /* GraphQL */ `
  mutation DeleteAnalyticsQuery($input: DeleteEntityInput!) {
    deleteAnalyticsQuery(input: $input)
  }
`;
export const deleteArea = /* GraphQL */ `
  mutation DeleteArea($input: DeleteEntityInput!) {
    deleteArea(input: $input)
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer($input: DeleteEntityInput!) {
    deleteCustomer(input: $input)
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice($input: DeleteEntityInput!) {
    deleteDevice(input: $input)
  }
`;
export const deleteEntity = /* GraphQL */ `
  mutation DeleteEntity($input: DeleteEntityInput!) {
    deleteEntity(input: $input)
  }
`;
export const deleteHWProfile = /* GraphQL */ `
  mutation DeleteHWProfile($input: DeleteEntityInput!) {
    deleteHWProfile(input: $input)
  }
`;
export const deleteHWType = /* GraphQL */ `
  mutation DeleteHWType($input: DeleteEntityInput!) {
    deleteHWType(input: $input)
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation($input: DeleteEntityInput!) {
    deleteLocation(input: $input)
  }
`;
export const deleteReactor = /* GraphQL */ `
  mutation DeleteReactor($input: DeleteEntityInput!) {
    deleteReactor(input: $input)
  }
`;
export const deleteTarget = /* GraphQL */ `
  mutation DeleteTarget($input: DeleteEntityInput!) {
    deleteTarget(input: $input)
  }
`;
export const disableUser = /* GraphQL */ `
  mutation DisableUser($input: DisableUserInput!) {
    disableUser(input: $input)
  }
`;
export const upsertAction = /* GraphQL */ `
  mutation UpsertAction($input: UpsertActionInput!) {
    upsertAction(input: $input) {
      condition
      entityId
      id
      isActive
      reactor {
        context
        id
        isActive
        name
      }
      rowHistory {
        createdAt
        updatedAt
      }
    }
  }
`;
export const upsertAnalyticsQuery = /* GraphQL */ `
  mutation UpsertAnalyticsQuery($input: UpsertAnalyticsQueryInput!) {
    upsertAnalyticsQuery(input: $input) {
      description
      id
      name
      rowHistory {
        createdAt
        updatedAt
      }
      sql
    }
  }
`;
export const upsertArea = /* GraphQL */ `
  mutation UpsertArea($input: UpsertAreaInput!) {
    upsertArea(input: $input) {
      code
      floor
      id
      locationId
      misc
      name
      pic
      rowHistory {
        createdAt
        updatedAt
      }
    }
  }
`;
export const upsertCustomer = /* GraphQL */ `
  mutation UpsertCustomer($input: UpsertCustomer!) {
    upsertCustomer(input: $input) {
      address
      admins
      email
      id
      name
      rowHistory {
        createdAt
        updatedAt
      }
    }
  }
`;
export const upsertDevice = /* GraphQL */ `
  mutation UpsertDevice($input: UpsertDeviceInput!) {
    upsertDevice(input: $input) {
      area {
        code
        floor
        id
        locationId
        misc
        name
        pic
      }
      hwProfile {
        brand
        firmware
        id
        iotProtocol
        manufacturer
        model
        powerType
        productNumber
        vendor
        warranty
      }
      id
      rowHistory {
        createdAt
        updatedAt
      }
      serialNumber
      status
    }
  }
`;
export const upsertEntity = /* GraphQL */ `
  mutation UpsertEntity($input: UpsertEntityInput!) {
    upsertEntity(input: $input) {
      dataType
      device {
        id
        serialNumber
        status
      }
      id
      isActive
      lastValue
      measure
      rowHistory {
        createdAt
        updatedAt
      }
      unit
    }
  }
`;
export const upsertEntityLV = /* GraphQL */ `
  mutation UpsertEntityLV($input: UpsertEntityInputLV!) {
    upsertEntityLV(input: $input) {
      dataType
      device {
        id
        serialNumber
        status
      }
      id
      isActive
      lastValue
      measure
      rowHistory {
        createdAt
        updatedAt
      }
      unit
    }
  }
`;
export const upsertHWProfile = /* GraphQL */ `
  mutation UpsertHWProfile($input: UpsertHWProfileInput!) {
    upsertHWProfile(input: $input) {
      brand
      customer {
        address
        admins
        email
        id
        name
      }
      firmware
      hwType {
        id
        name
      }
      id
      iotProtocol
      manufacturer
      model
      powerType
      productNumber
      rowHistory {
        createdAt
        updatedAt
      }
      vendor
      warranty
    }
  }
`;
export const upsertHWType = /* GraphQL */ `
  mutation UpsertHWType($input: UpsertHWTypeInput!) {
    upsertHWType(input: $input) {
      id
      name
      rowHistory {
        createdAt
        updatedAt
      }
    }
  }
`;
export const upsertLocation = /* GraphQL */ `
  mutation UpsertLocation($input: UpsertLocationInput!) {
    upsertLocation(input: $input) {
      city
      coordinates
      country
      customer {
        address
        admins
        email
        id
        name
      }
      id
      postcode
      rowHistory {
        createdAt
        updatedAt
      }
      shortcode
      state
      street
      streetNo
    }
  }
`;
export const upsertReactor = /* GraphQL */ `
  mutation UpsertReactor($input: UpsertReactorInput!) {
    upsertReactor(input: $input) {
      context
      id
      isActive
      location {
        city
        coordinates
        country
        id
        postcode
        shortcode
        state
        street
        streetNo
      }
      name
      rowHistory {
        createdAt
        updatedAt
      }
      target {
        id
        name
        type
      }
    }
  }
`;
export const upsertTarget = /* GraphQL */ `
  mutation UpsertTarget($input: UpsertTargetInput!) {
    upsertTarget(input: $input) {
      id
      name
      rowHistory {
        createdAt
        updatedAt
      }
      type
    }
  }
`;
export const upsertUser = /* GraphQL */ `
  mutation UpsertUser($input: UpsertUserInput!) {
    upsertUser(input: $input) {
      areaId
      customerId
      email
      group
      id
      isActive
      name
    }
  }
`;
