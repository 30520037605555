import { Card, Tab, Tabs, Classes } from "@blueprintjs/core";
import AreasPanel from "./components/AreasPanel";
import UsersPanel from "./components/UsersPanel";
import SensorsPanel from "./components/SensorsPanel";
import CustomersPanel from "./components/CustomersPanel";
import './Sidebar.scss';
import React from 'react';
import '../../../../web/floor-app/themes/themes.scss';



const Sidebar = () => {
  return (
    <Card className='sidebar'>
        <Tabs id="sidebarTabs" vertical={true} className={`sidebar-tabs ${Classes.FOCUS_DISABLED}`}>
            <Tab style={{flexGrow: '1'}} id="usersPanel" title="USERS" panel={<UsersPanel />} />
            <Tab  id="sensorsPanel" title="SENSORS" panel={<SensorsPanel />} />
            <Tab id="customersPanel" title="CUSTOMERS" panel={<CustomersPanel />}  />
            <Tab id="areasPanel" title="AREAS" panel={<AreasPanel />} />
        </Tabs>
    </Card>
  )
}

export default Sidebar