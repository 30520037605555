import { Dialog } from '@blueprintjs/core';
import React, { useState } from 'react';
import FullCenterView from '../components/card-styles/FullCenterView';
import ManageBackgroundScreen from '../components/settings/ManageBackgroundScreen';
import ManagePosterScreen from '../components/settings/ManagePosterScreen';
import ManageWidgetsScreen from '../components/settings/ManageWidgetsScreen';
import ManageNotesScreen from '../components/settings/ManageNotesScreen';
import ManageVideosScreen from '../components/settings/ManageVideosScreen';
import ManageGeneralSettings from '../components/settings/ManageGeneralSettings';
import './Settings.scss';

const Settings = ({ isDarkTheme, isOpen, setIsOpen }) => {
    //VALUES
    const [selectedView, setSelectedView] = useState(null);

    //SCREENS
    const dashboardScreens = {
        Background: <ManageBackgroundScreen isDarkTheme={isDarkTheme} />,
        Poster: <ManagePosterScreen isDarkTheme={isDarkTheme} />,
        Widgets: <ManageWidgetsScreen isDarkTheme={isDarkTheme} />,
        Notes: <ManageNotesScreen isDarkTheme={isDarkTheme} />,
        Videos: <ManageVideosScreen isDarkTheme={isDarkTheme} />,
        General: <ManageGeneralSettings isDarkTheme={isDarkTheme} />,
    };

    //RENDERERS
    const renderNavbarCard = (cardText, onCardClick) => {
        return (
            <div
                onClick={onCardClick}
                className="dashboard-navbar-card"
                style={isDarkTheme ? { background: 'var(--dark-card-fill)' } : { background: 'var(--light-card-fill)' }}
            >
                <p className="dashboard-navbar-card-text">{cardText ? cardText : ''}</p>
            </div>
        );
    };

    //RENDER
    return (
        <Dialog
            isOpen={isOpen}
            style={{ width: 'calc(100% - 30px)' }}
            onClose={() => setIsOpen(false)}
            title="Settings"
            className={isDarkTheme && 'bp3-dark'}
        >
            <FullCenterView>
                <div className="dashboard-wrapper">
                    <div className="dashboard-navbar">
                        {renderNavbarCard('Background', function () {
                            setSelectedView('Background');
                        })}
                        {renderNavbarCard('General', function () {
                            setSelectedView('General');
                        })}
                        {renderNavbarCard('Notes', function () {
                            setSelectedView('Notes');
                        })}
                        {renderNavbarCard('Poster', function () {
                            setSelectedView('Poster');
                        })}
                        {renderNavbarCard('Videos', function () {
                            setSelectedView('Videos');
                        })}
                        {renderNavbarCard('Widgets', function () {
                            setSelectedView('Widgets');
                        })}
                    </div>

                    <div className="dashboard-screen">
                        {selectedView ? (
                            dashboardScreens[selectedView]
                        ) : (
                            <h2
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}
                            >
                                PLEASE CHOOSE A FEATURE TO EDIT
                            </h2>
                        )}
                    </div>
                </div>
            </FullCenterView>
        </Dialog>
    );
};

export default Settings;
