import React, { createContext, useState, useCallback } from 'react';



const CustomToastContext = createContext();

export const CustomToastProvider = ({children}) => {
    const [toastState, setToastState] = useState({
        toastShown: false,
        progressShown: false,
        progressValue: 0,
        toastText: '',
    });

    const changeToastState = useCallback((settingKeyValuePair) => {
        setToastState((prev) => {return {...prev, ...settingKeyValuePair}});
    }, [toastState]);

    return (
        <CustomToastContext.Provider value={{ toastState, changeToastState }}>
            {children}
        </CustomToastContext.Provider>
    );
}

export default CustomToastContext;