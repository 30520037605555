/**
 * menu component based on blueprintjs
 *
 * version 0.0.1
 * author George Kanellopoulos
 * description: This component generates a drop down menu
 */

/*example:
 <Menu>
    <Menu.Item icon="new-text-box" onClick={this.handleClick} text="New text box" />
    <Menu.Item icon="new-object" onClick={this.handleClick} text="New object" />
    <Menu.Item icon="new-link" onClick={this.handleClick} text="New link" />
    <Menu.Divider />
    <Menu.Item text="Settings..." icon="cog">
        <Menu.Item icon="tick" text="Save on edit" />
        <Menu.Item icon="blank" text="Compile on edit" />
    </Menu.Item>
</Menu>

becomes:

menu_items = [
  {
    item: {
      text: "New text box",
      icon: "new-text-box",
      on_click: this.handleClick //a function from parent
    },
    item: {text: "New object", icon: "new-object", href: "/projects"},
    divider: true,
    item: {
      text: "Settings",
      icon: "cog",
      items: [
        {
          item: {text: "Save on edit", icon: "tick", href: "/projects"},
          item: {
            text: "Compile on edit",
            icon: "blank",
            href: "url" //a url
            on_click: f{}
            on_click_parameter: in case on_click is filled
          }
        }
      ]
    }
  }
];

*/

import React, { useState } from 'react';
import './style.css';
import { Menu as BpMenu, MenuDivider, MenuItem, PopoverInteractionKind, Button } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Link } from 'react-router-dom';

const Menu = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setselectedItem] = useState(null);

    const renderMenu = (items) => {
        return items.map((obj, key) => {
            if (obj.hasOwnProperty('item')) {
                if (obj.item.hasOwnProperty('on_click')) {
                    if (obj.item.hasOwnProperty('on_click_parameter')) {
                        return (
                            <MenuItem
                                key={key}
                                icon={obj.item.icon}
                                text={obj.item.text}
                                onClick={() => {
                                    setselectedItem(key);
                                    obj.item.on_click(obj.item.on_click_parameter);
                                }}
                            />
                        );
                    } else {
                        return (
                            <MenuItem
                                key={key}
                                icon={obj.item.icon}
                                text={obj.item.text}
                                onClick={() => {
                                    setselectedItem(key);
                                    obj.item.on_click();
                                }}
                            />
                        );
                    }
                } else if (obj.item.hasOwnProperty('href')) {
                    return (
                        <Link key={key} to={obj.item.href}>
                            <MenuItem tagName="span" icon={obj.item.icon} text={obj.item.text} />
                        </Link>
                    );
                } else if (obj.item.hasOwnProperty('items')) {
                    return (
                        <MenuItem key={key} icon={obj.item.icon} text={obj.item.text}>
                            {renderMenu(obj.item.items)}
                        </MenuItem>
                    );
                } else {
                    <MenuItem
                        key={key}
                        icon={obj.item.icon}
                        text={obj.item.text}
                        onClick={() => {
                            setselectedItem(key);
                        }}
                    />;
                }
            } else if (obj.hasOwnProperty('divider')) {
                return <MenuDivider key={key} title={obj.divider.title} />;
            }
            return null;
        });
    };

    const handleInteraction = (nextOpenState) => {
        setIsOpen(nextOpenState);
    };

    let {
        menu_items,
        menu_position,
        menu_icon,
        menu_text,
        menu_intent,
        menu_component,
        style,
        selected_item_button,
        menu_button_class_name,
        fill,
    } = props;

    let popoverContent = <BpMenu style={style}>{renderMenu(menu_items)}</BpMenu>;

    let menu_button;
    if (selected_item_button && selectedItem !== null) {
        const menuItem = menu_items[selectedItem].item;
        menu_button = (
            <Button
                text={menuItem.text}
                icon={menuItem.icon ? menuItem.icon : menu_icon ? menu_icon : ''}
                intent={menu_intent}
                className={menu_button_class_name}
            />
        );
    } else if (props.children) {
        menu_button = props.children;
    } else {
        // menu_button = menu_text ? (
        //         <Button text={menu_text} icon={menu_icon ? menu_icon : ''} intent={menu_intent} className={menu_button_class_name}/>
        //     ) : (
        //         <span className="menu">{menu_icon}</span>
        //     );
        if (menu_text) {
            menu_button = (
                <Button
                    text={menu_text}
                    icon={menu_icon ? menu_icon : ''}
                    intent={menu_intent}
                    className={menu_button_class_name}
                />
            );
        } else if (menu_icon) {
            menu_button = <span className="menu">{menu_icon}</span>;
        } else {
            menu_button = menu_component;
        }
    }

    return (
        <Popover2
            content={popoverContent}
            position={menu_position}
            interactionKind={PopoverInteractionKind.CLICK}
            isOpen={isOpen}
            onInteraction={(state) => handleInteraction(state)}
            canEscapeKeyClose={true}
            fill={fill}
        >
            {menu_button}
        </Popover2>
    );
};

export default Menu;
