import React, { useState } from 'react';
import Button from '../../components/button/Button';
import TextInput from '../../components/text-input/TextInput';
import Header from '../../components/header/Header';
import ErrorCard from '../../components/error-card/ErrorCard';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import Menu from '../../components/menu/Menu';
import { Icon, Position } from '@blueprintjs/core';
import { getCustomer, subscribeToEntity, updateEntity } from '../../utils/API_calls';
import Map from '../../components/map/Map';

const Playground = (props) => {
    const [count, setCount] = useState(0);

    const handleClick = () => {
        console.log('You clicked.');
    };

    const getCustomerById = () => {
        getCustomer('Customer_1')
            .then((customer) => {
                console.log(customer);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const subscribeToEntities = () => {
        //TEST
        subscribeToEntity('temp5284693');
        subscribeToEntity('mot5284693');
    };

    const updateTestEntity = (num) => {
        updateEntity(num)
            .then((res) => {
                console.log(res);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const menu_items = [
        {
            divider: {
                title: 'USER NAME',
            },
        },
        {
            item: {
                text: 'brekeke 1',
                icon: 'log-out',
                on_click: handleClick,
            },
        },
        {
            item: {
                text: 'brekeke 2',
                icon: 'log-out',
                on_click: handleClick,
            },
        },
        {
            item: {
                text: 'brekeke 3',
                icon: 'build',
                items: [
                    {
                        item: {
                            text: 'brekeke 2',
                            icon: 'log-out',
                            on_click: handleClick,
                        },
                    },
                ],
            },
        },
    ];

    return (
        <div>
            <Navbar />
            <div className="page-content">
                <p>Example</p>
                <p>You clicked {count} times</p>
                <Header level={1}>Header 1</Header>
                <Header level={2}>Header 2</Header>
                <Header level={3}>Header 3</Header>
                <Header level={4}>Body 1</Header>
                <Header level={5}>Body 2</Header>
                <Header level={6}>Body 3</Header>
                <Menu
                    menu_items={menu_items}
                    menu_position={Position.BOTTOM_RIGHT}
                    menu_icon={<Icon icon="menu" color="black" iconSize={32}></Icon>}
                />
                <TextInput disabled={false} large={false} placeholder="Write some text here" />
                <br />
                <TextInput disabled={true} large={false} placeholder="Disabled text input" value="BREKEKE" />
                <br />
                <TextInput
                    disabled={false}
                    large={false}
                    placeholder="text input"
                    has_error={1}
                    error_msg="This is an error."
                />
                <br />
                <Button onClick={() => setCount(count + 1)}>Click me</Button>
                <Button disabled onClick={() => setCount(count + 1)}>
                    Disabled
                </Button>
                <Button is_loading={1}>Loading...</Button>
                <Button onClick={() => getCustomerById()}>Get customer by ID</Button>
                <Button onClick={subscribeToEntities}>SUBSCRIBE</Button>
                <Button onClick={() => updateTestEntity(1)}>UPDATE ENTITY 1</Button>
                <Button onClick={() => updateTestEntity(2)}>UPDATE ENTITY 2</Button>
                <Menu
                    menu_items={menu_items}
                    menu_position={Position.BOTTOM_LEFT}
                    menu_component={<div>Customers</div>}
                />
                <br />
                <br />
                <ErrorCard text="Unable to sign in." style={{ width: '250px' }} />
            </div>
            <Map />
            <Footer />
        </div>
    );
};

export default Playground;
