import { Divider } from '@blueprintjs/core';
import React from 'react';
import FullCenterView from '../components/card-styles/FullCenterView';
import FullCenterViewLeftSide from '../components/card-styles/FullCenterViewLeftSide';
import FullCenterViewRightSide from '../components/card-styles/FullCenterViewRightSide';
import WidgetSensor from '../components/widgets/WidgetSensor';
import WidgetSensorsFilter from '../components/widgets/WidgetSensorsFilter';

import './Sensors.scss';

function Sensors() {
    return (
        <FullCenterView>
            <FullCenterViewLeftSide>
                <WidgetSensorsFilter />
            </FullCenterViewLeftSide>
            <Divider />
            <FullCenterViewRightSide>
                <div className="single-sensor-widget-flex">
                    <WidgetSensor />
                </div>
            </FullCenterViewRightSide>
        </FullCenterView>
    );
}

export default Sensors;
