import React, { useState, useEffect, useContext} from 'react';
import { Button, Collapse, Dialog, FormGroup, InputGroup } from '@blueprintjs/core';
import UserSettingsContext from '../../../context/userSettingsContext';
import { useStore } from '../../store/useStore';
import './ManagePosterScreen.scss';

import img1 from '../../assets/images/PosterDXCStudents.png';





const ManagePosterScreen = ({ isDarkTheme }) => {
    //ENDPOINTS
    const posterApiEndpoints = {
        getSignedUrl: `https://1b6assto53.execute-api.eu-west-1.amazonaws.com/dev/getsignedurl`,
        savePoster: `https://1b6assto53.execute-api.eu-west-1.amazonaws.com/dev/saveposter`,
        getUserPosters: `https://1b6assto53.execute-api.eu-west-1.amazonaws.com/dev/getuserposters`,
        deletePoster: `https://1b6assto53.execute-api.eu-west-1.amazonaws.com/dev/deleteposter`,
    };



    // GLOBAL/LOCAL USER
    const isGlobalUser = useStore()[0].userSettings.isGlobal;



    //GET ALL POSTERS
    const { userSettings, changeUserSettings } = useContext(UserSettingsContext);
    const { email, activePoster : activePosterFromUserSettings } = userSettings;
    const [posters, setPosters] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (email && isGlobalUser) {
            setMessage('Getting posters...');
            fetch(posterApiEndpoints.getUserPosters, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({user: email})
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.error) {
                        setMessage(JSON.stringify(data.error));
                        // setTimeout(() => {setMessage('')}, 2000);
                        setPosters(null);
                    } else {
                        setMessage('');
                        setPosters(data.posters);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setMessage(JSON.stringify(error));
                });
        } else {
            setPosters([{id: 'localuserposter1', imageUrl: img1, name: 'Sample poster'}])
        }
    }, [email]);



    //GET ACTIVE POSTER
    const [activePoster, setActivePoster] = useState(null);
    const [updateMessage, setUpdateMessage] = useState('');
    const [formDisabled, setFormDisabled] = useState(false);

    useEffect(() => {
        if (posters) {
            if (activePosterFromUserSettings) {
                //only one poster can be active
                setActivePoster(activePosterFromUserSettings);
            } else {
                setActivePoster(null);
            }
        }
    }, [posters]);



    //DELETE POSTER
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [toDeletePosterId, setToDeletePosterId] = useState(null);
    const [deleteMessage, setDeleteMessage] = useState('');

    const deletePoster = (posterId) => {
        //disable btns, show message, cleanup
        if (formDisabled) return;
        if (posterIsActive(posterId)) {
            setDeleteMessage('Poster is active. Please deactivate poster before deleting it');
            setTimeout(() => {setDeleteMessage('')}, 2000);
            return;
        }
        setFormDisabled(true);
        setToDeletePosterId(null);
        setDeleteConfirmOpen(false);
        setDeleteMessage('Deleting Poster...');

        //delete poster
        fetch(posterApiEndpoints.deletePoster, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: posterId, user: email }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (data && data.error) {
                    setDeleteMessage(data.error);
                    setTimeout(() => {
                        setDeleteMessage('');
                        setFormDisabled(false);
                    }, 2000);
                } else {
                    setDeleteMessage('Poster deleted');
                    setTimeout(() => {
                        setDeleteMessage('');
                        setFormDisabled(false);
                    }, 2000);
                    const postersBefore = [...posters];
                    const filteredPosters = postersBefore.filter((item) => item.id !== posterId);
                    setPosters([...filteredPosters]);
                }
            })
            .catch((error) => {
                console.log(error);
                setDeleteMessage(JSON.stringify(error));
                setTimeout(() => {
                    setDeleteMessage('');
                    setFormDisabled(false);
                }, 2000);
            });
    };



    //POSTER UPLOAD FORM
    const [collapseOpen, setCollapseOpen] = useState(false);
    const [values, setValues] = useState({ user: email, name: '', imageUrl: '', createdAt: '', id: '' });
    const { name, posterUrl } = values;
    const [uploadMessage, setUploadMessage] = useState('');

    const handleChange = (e) => {
        setUploadMessage('');
        setValues({ ...values, [e.target.name]: e.target.value });
    };



    //POSTER UPLOAD
    const readFile = () => {
        //checks
        if (formDisabled) return;
        if (!name || name.trim() === '') {
            setUploadMessage('Please enter poster name');
            setTimeout(() => {
                setUploadMessage('');
                setFormDisabled(false);
            }, 2000);
            return;
        }

        //disable form
        setFormDisabled(true);

        //get posterInput el.
        let fileInput = document.getElementById('posterInput');
        if (!fileInput.files.length) return;

        //read input file
        let reader = new FileReader();
        reader.onload = (e) => {
            //verify if file is an image
            if (!e.target.result.includes('data:image/')) {
                setUploadMessage('Please upload an image');
                setFormDisabled(false);
                setTimeout(() => {
                    setUploadMessage('');
                }, 2000);
                return;
            }

            uploadPoster(e.target.result);
        };

        reader.readAsDataURL(fileInput.files[0]);
    };

    const uploadPoster = (poster) => {
        setUploadMessage('Uploading poster...');

        //get pre-signed url to aws s3 bucket
        fetch(posterApiEndpoints.getSignedUrl, { method: 'GET' })
            .then((response) => response.json())
            .then((data) => {
                //do some convert-image-to-base64 magic
                setUploadMessage('...converting image...');
                let binary = atob(poster.split(',')[1]);
                let array = [];
                for (var i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i));
                }
                let blobData = new Blob([new Uint8Array(array)], { type: 'image/png' });
                let objectUrl = data.url.split('?')[0];

                //use the pre-signed aws s3 bucket url to save the image
                fetch(data.url, { method: 'PUT', body: blobData })
                    .then((response) => {
                        //save imageUrl to db
                        setUploadMessage('...image uploaded, saving...');
                        fetch(posterApiEndpoints.savePoster, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ user: email, name, imageUrl: objectUrl }),
                        })
                            .then((response) => {
                                return response.json();
                            })
                            .then((data) => {
                                if (data.error) {
                                    console.log(data.error);
                                    setUploadMessage(JSON.stringify(data.error));
                                    setTimeout(() => {
                                        setFormDisabled(false);
                                        setUploadMessage('');
                                    }, 2000);
                                }
                                setPosters([data, ...posters]);
                                setUploadMessage('Poster uploaded');
                                setValues({ user: email, id: '', name: '', imageUrl: '', createdAt: '' });
                                setTimeout(() => {
                                    setFormDisabled(false);
                                    setUploadMessage('');
                                    setCollapseOpen(false);
                                }, 2000);
                            })
                            .catch((error) => {
                                console.log(error);
                                setUploadMessage('Poster upload failed :(');
                                setTimeout(() => {
                                    setFormDisabled(false);
                                    setUploadMessage('');
                                }, 2000);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        setUploadMessage(JSON.stringify(error));
                        setTimeout(() => {
                            setFormDisabled(false);
                            setUploadMessage('');
                        }, 2000);
                    });
            })
            .catch((error) => {
                console.log(error);
                setUploadMessage(JSON.stringify(error));
                setTimeout(() => {
                    setFormDisabled(false);
                    setUploadMessage('');
                }, 2000);
            });
    };



    //DEACTIVATE POSTER
    const posterIsActive = (posterId) => {
        if (activePoster && activePoster.id === posterId) return true
        else return false 
    }

    const deactivatePoster = (posterId) => {
        changeUserSettings({activePoster: null});
        setActivePoster(null);
    }

    const toggleActivePoster = (posterId) => {
        let posterIdx = [...posters].findIndex(item => item.id === posterId);

        if (posterIsActive(posterId)) {
            changeUserSettings({activePoster: null});
            setActivePoster(null);
        } else {
            changeUserSettings({ activePoster: {...posters[posterIdx]} });
            setActivePoster({...posters[posterIdx]});
        }
    }



    //POSTERS RENDERER
    const renderPosters = () => {
        if (!posters || posters.length === 0) return <div />;
        return (
            <div
                className="posters-container"
                style={isDarkTheme ? { background: 'var(--dark-card-fill)' } : { background: 'var(--light-card-fill)' }}
            >
                {posters &&
                    posters.map((item) => (
                        <div className="poster-item" style={{ position: 'relative' }} key={item.id}>
                            <img
                                src={item.imageUrl}
                                width={260}
                                height={150}
                                style={{
                                    borderRadius: '5px',
                                    marginRight: '0.5rem',
                                    boxShadow: isDarkTheme ? '0 0 10px silver' : '0 0 10px #777',
                                }}
                            />

                            <div className="poster-item-layover">
                                <p style={{ border: 0, padding: 0, color: 'white' }}>
                                    {' '}
                                    <b>{item.name}</b>{' '}
                                </p>
                                <p style={{ border: 0, padding: 0, color: 'white' }}>
                                    {' '}
                                    <b>{posterIsActive(item.id) ? 'Active' : 'Inactive'}</b>{' '}
                                </p>

                                <div>
                                    <Button
                                        style={{ margin: '5px' }}
                                        text={posterIsActive(item.id) ? 'Deactivate' : 'Activate'}
                                        onClick={() => {
                                            if (posterIsActive(item.id)) deactivatePoster(item.id);
                                            else toggleActivePoster(item.id);
                                        }}
                                    ></Button>
                                    {isGlobalUser && <Button
                                        style={{ margin: '5px' }}
                                        text="Delete"
                                        onClick={() => {
                                            if (posterIsActive(item.id)) {
                                                setDeleteMessage('Poster is active. Please deactivate poster before deleting it');
                                                setTimeout(() => {setDeleteMessage('')}, 2000);
                                                return;
                                            }
                                            setToDeletePosterId(item.id);
                                            setDeleteConfirmOpen(true);
                                        }}
                                    ></Button>}
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        );
    };



    //RENDER
    return (
        <div style={{ minHeight: '100%', width: '100%' }}>
            <h2 style={{ padding: 0, margin: 0 }}>POSTER</h2> <br />
            <div className="controls-and-preview-container">
                <div className="poster-screen-left-side">
                    {isGlobalUser && <Button
                        style={{ width: '260px', marginBottom: '0.5rem' }}
                        onClick={() => setCollapseOpen(!collapseOpen)}
                        text={collapseOpen ? 'Close' : 'Upload a new poster'}
                    />}
                    <Collapse isOpen={collapseOpen}>
                        <form
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '260px',
                                margin: '0.5rem 0 2rem 0',
                            }}
                        >
                            <br />
                            <FormGroup label={'Poster Name'}>
                                <InputGroup
                                    placeholder="Name (required)"
                                    name="name"
                                    value={name}
                                    onChange={handleChange}
                                    style={{ width: '100%' }}
                                    disabled={formDisabled}
                                />
                            </FormGroup>

                            {name && name.trim() !== '' && (
                                <Button style={{ width: '100%' }}>
                                    <label
                                        className="bp4-file-input .modifier"
                                        htmlFor="posterInput"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <input
                                            disabled={formDisabled}
                                            type="file"
                                            id="posterInput"
                                            name="posterInput"
                                            onChange={readFile}
                                            hidden
                                        />
                                        <span className="bp4-file-upload-input">Upload Poster Image...</span>
                                    </label>
                                </Button>
                            )}

                            <br />
                            {uploadMessage && <p style={{ color: '#d33d17', textAlign: 'center' }}>{uploadMessage}</p>}
                        </form>
                    </Collapse>

                    <h3 style={{ padding: 0, margin: '1rem 0 0.25rem 0' }}>Posters: </h3>
                    {posters && posters.length > 0 && renderPosters()}
                    <br />
                    {message && <p style={{ color: '#d33d17', textAlign: 'center' }}>{message}</p>}
                    {updateMessage && <p style={{ color: '#d33d17', textAlign: 'center' }}>{updateMessage}</p>}
                    {deleteMessage && <p style={{ color: '#d33d17', textAlign: 'center' }}>{deleteMessage}</p>}
                </div>

                <div className="poster-screen-right-side">
                    <h3 style={{ margin: 0, marginBottom: '0.25rem', padding: 0 }}>Currently Selected: </h3>
                    {!activePoster && (
                        <div style={{ color: '#999' }}>
                            <h3 style={{ padding: 0, margin: 0 }}>No poster is active</h3>
                            <p style={{ padding: 0, margin: 0 }}>
                                <small>upload and activate posters on the left</small>
                            </p>
                        </div>
                    )}
                    {activePoster && <img src={activePoster.imageUrl} width={'100%'} />}
                </div>
            </div>
            <Dialog
                isOpen={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
                style={{ background: '#db3737', color: 'white' }}
            >
                <div
                    style={{
                        marginTop: '1.25rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <p style={{ textAlign: 'center', padding: 0, margin: 0 }}>
                        Are you sure you want to delete this poster?
                    </p>
                    <div style={{ marginTop: '1rem' }}>
                        <span
                            onClick={() => deletePoster(toDeletePosterId)}
                            style={{ margin: '1rem 1rem 1rem 0rem', cursor: 'pointer' }}
                        >
                            DELETE
                        </span>
                        <span
                            onClick={() => {
                                setToDeletePosterId(null);
                                setDeleteConfirmOpen(false);
                            }}
                            style={{ margin: '1rem 1rem 1rem 0rem', cursor: 'pointer' }}
                        >
                            CANCEL
                        </span>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ManagePosterScreen;
