import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Auth from './common/Auth';
import routes from './Routes';
import { UserSettingsProvider } from './context/userSettingsContext';
import { CustomToastProvider } from './context/customToastProvider';



function App() {
    function ErrorFallback({ error, resetErrorBoundary }) {
        return (
            <div role="alert">
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
            </div>
        );
    }

    const myErrorHandler = (error, info) => {
        console.log(error);
        console.log(info);
    };


    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
            <UserSettingsProvider>
                <CustomToastProvider>
                    <BrowserRouter>
                        <Auth>{routes}</Auth>
                    </BrowserRouter>
                </CustomToastProvider>
            </UserSettingsProvider>
        </ErrorBoundary>
    );
}

export default App;
