import { H2, Spinner } from '@blueprintjs/core';
import React from 'react';

function Location(props) {
    if (props.data === null) {
        return (
            <>
                <Spinner intent="success" />
                <H2 className="floor-weather" style={{marginLeft: '1rem'}}>Getting Location...</H2>
            </>
        );
    }

    return (
        <H2 className="floor-weather">
            Area: {props.data.name} | Floor: {props.data.floor}
        </H2>
    );
}

export default Location;
