import React, { useEffect, useState } from 'react';
import AlertButton from '../AlertButton';

const navbarStyle = {
    position: 'fixed',
    height: '80px',
    bottom: '0px',
    width: '100%',
};

function BottomNavbar({ navbarChange, navbar }) {
    const [actualButton, setactualButton] = useState({});

    let view = parseInt(navbar);

    //Passes the button value to up in component hierarchy, stores the actual button,
    //changes the style accordingly
    const buttonHandler = (t) => {
        if (Object.keys(actualButton).length !== 0) {
            actualButton.setAttribute('class', 'bp3-button floor-navbar-button');
        }

        setactualButton(t);
        t.setAttribute('class', 'bp3-button bp3-active floor-navbar-button');
        navbarChange(t.value);
    };

    //Grabbing the General View button at the load of the application

    useEffect(() => {
        if (view !== 999) {
            const initialButtonAtLoad = document.getElementsByClassName('bp3-button floor-navbar-button');
            buttonHandler(initialButtonAtLoad[view]);
        }
        // eslint-disable-next-line
    }, [view]);

    return (
        <nav className="bp3-navbar floor-navbar" style={navbarStyle}>
            <div className="bp3-navbar-group bp3-align-left">
                <button
                    className="bp3-button floor-navbar-button"
                    type="button"
                    value={0}
                    onClick={(e) => buttonHandler(e.target)}
                >
                    General
                </button>
                <button
                    className="bp3-button floor-navbar-button"
                    type="button"
                    value={1}
                    onClick={(e) => buttonHandler(e.target)}
                >
                    Location
                </button>
                <button
                    className="bp3-button floor-navbar-button"
                    type="button"
                    value={2}
                    onClick={(e) => buttonHandler(e.target)}
                >
                    Sensors
                </button>
                <button
                    className="bp3-button floor-navbar-button"
                    type="button"
                    value={3}
                    onClick={(e) => buttonHandler(e.target)}
                >
                    Video
                </button>
                <button
                    className="bp3-button floor-navbar-button"
                    type="button"
                    value={4}
                    onClick={(e) => buttonHandler(e.target)}
                >
                    Weather
                </button>
                <button
                    className="bp3-button floor-navbar-button"
                    type="button"
                    value={5}
                    onClick={(e) => buttonHandler(e.target)}
                >
                    Poster
                </button>
            </div>
            <div className="bp3-navbar-group bp3-align-right floor-navbar-group-right ">
                <AlertButton navbarChange={navbarChange}></AlertButton>
            </div>
        </nav>
    );
}

export default BottomNavbar;
