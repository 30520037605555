import React, { useEffect, useState, useContext, useRef } from 'react';
import UserSettingsContext from '../../context/userSettingsContext';
import TopNavbar from '../components/navbars/TopNavbar';
import BottomNavbar from '../components/navbars/BottomNavbar';
import Center from '../components/Center';
import AlertScreen from '../components/AlertScreen';
import CustomToast from '../components/CustomToast';
import '../themes/themes.scss';
import './Main.css';



function Main() {
    //NAVBAR, THEME
    const [navbar, setNavbar] = useState(0);
    const navbarChange = (value) => {
        setNavbar(value);
    };
    let view = parseInt(navbar);

    useEffect(() => {
        const body = document.getElementsByTagName('body');
        body[0].style.margin = '0';
        body[0].style.backgroundColor = '#293742';
    }, []);

    //CUSTOM BACKGROUND(S)
    const { userSettings, changeUserSettings } = useContext(UserSettingsContext);
    const { activeBackgrounds, backgroundRotationTime } = userSettings;
    const [currentBackground, setCurrentBackground] = useState(0);
    const timeInterval = useRef();

    useEffect(() => {
        if (activeBackgrounds?.length === 1) setCurrentBackground(0);
        if (timeInterval.current) clearTimeout(timeInterval.current);
        if (activeBackgrounds && activeBackgrounds.length > 1) {
            timeInterval.current = setTimeout(() => {
                if (currentBackground >= activeBackgrounds.length - 1) setCurrentBackground(0);
                else setCurrentBackground((prev) => prev + 1);
            }, backgroundRotationTime * 1000);
        }
    }, [activeBackgrounds, backgroundRotationTime, currentBackground]);

    const { isDarkTheme } = userSettings;

    const toggleThemeHandler = function () {
        changeUserSettings({ isDarkTheme: !isDarkTheme });
    };

    //RENDER
    return (
        <div
            className={isDarkTheme ? 'floor-main-dark bp3-dark' : 'floor-main-light'}
            style={
                activeBackgrounds && activeBackgrounds.length > 0
                    ? currentBackground > activeBackgrounds.length - 1
                        ? { background: `url(${activeBackgrounds[0].imageUrl}) no-repeat center center/cover` }
                        : {
                              background: `url(${activeBackgrounds[currentBackground].imageUrl}) no-repeat center center/cover`,
                          }
                    : { background: '' }
            }
        >
            {view === 999 && <AlertScreen />}
            <TopNavbar onToggleThemeClick={toggleThemeHandler} isDarkTheme={isDarkTheme}></TopNavbar>
            <Center navbar={navbar} isDarkTheme={isDarkTheme}></Center>
            <BottomNavbar navbarChange={navbarChange} navbar={navbar}></BottomNavbar>
            <CustomToast isDarkTheme={isDarkTheme} />
        </div>
    );
}

export default Main;
