import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-initials-sprites';

export function isStringEmpty(text) {
    if (text == null) {
        return true;
    }
    if (text.trim().length === 0) {
        return true;
    }
    return false;
}

export function isPasswordFormatCorrect(pass) {
    var specialCharsRegex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if (pass.length >= 16) {
        if (pass !== pass.toUpperCase()) {
            if (pass !== pass.toLowerCase()) {
                if (specialCharsRegex.test(pass)) {
                    if (/\d/.test(pass)) {
                        return true;
                    }
                }
            }
        }
    }
    return false;
}

export function isEmailFormatCorrect(email) {
    var emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
    if (emailRegex.test(email)) {
        return true;
    }
    return false;
}

export function generateAvatar(name, width = 96, height = 96, color = '#6f2c91') {
    return new Promise(function (resolve, reject) {
        try {
            let options = {
                dataUri: true,
                radius: 50,
                bold: true,
                width: width,
                height: height,
                background: color,
                seed: name,
            };
            let svg = createAvatar(style, options);
            resolve(svg);
        } catch (e) {
            console.log(e);
            reject(e);
        }
    });
}

export function formOperationObject(operationType, data = null) {
    return {
        operation: operationType,
        details: data,
    };
}
