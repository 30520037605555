const config = {
    cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_wmdUKHgtP',
        APP_CLIENT_ID: '4sibkil3kk4q2ks7962tmv5q2l',
    },
    appsync: {
        ENDPOINT: 'https://3dw245ilqnd4vgrdk2qk7zvbpi.appsync-api.eu-west-1.amazonaws.com/graphql',
        REGION: 'eu-west-1',
        AUTHENTICATION_TYPE: 'AMAZON_COGNITO_USER_POOLS',
        APIKEY: 'eqay3ahcfnhh3a7hknrpk3zaqe',
    },
    locationService: {
        MAP_NAME: 'scootMaps',
        REGION: 'eu-west-1',
        IDENTITY_POOL_ID: 'eu-west-1:f3577fd1-6224-47af-80c3-b4e7e5400880',
    },
};

export default config;
