import React from 'react';
import './style.css';

// Imagine level instead of h1,h2,h3,...
const Header = (props) => {
    let className = `bp3-heading ${props.className ? props.className : ''}`;
    switch (props.level) {
        case 1:
            return (
                <h1 className={className} style={props.style}>
                    {props.children}
                </h1>
            );
        case 2:
            return (
                <h2 className={className} style={props.style}>
                    {props.children}
                </h2>
            );
        case 3:
            return (
                <h3 className={className} style={props.style}>
                    {props.children}
                </h3>
            );
        case 4:
            return (
                <h4 className={className} style={props.style}>
                    {props.children}
                </h4>
            );
        case 5:
            return (
                <h5 className={className} style={props.style}>
                    {props.children}
                </h5>
            );
        case 6:
            return (
                <h6 className={className} style={props.style}>
                    {props.children}
                </h6>
            );
        default:
            return (
                <h3 className={className} style={props.style}>
                    {props.children}
                </h3>
            );
    }
};

export default Header;
