import { Icon } from '@blueprintjs/core';
import React from 'react';

import lightning from '../../../floor-app/assets/icons/content/scooter/DXC_Industry_Energy.svg';
import appStore from '../../../floor-app/assets/icons/content/app-store/app-store.png';
import googlePlay from '../../../floor-app/assets/icons/content/app-store/google-play.png';

import './MapPopup.scss';

const departureColor = function (departureTime) {
    if (departureTime <= 3) {
        return '#ED9B33';
    } else if ((departureTime > 3) & (departureTime <= 5)) {
        return '#FFCD00';
    } else return '#006975';
};

const MapPopup = function (props) {
    const contentInfo = props.popupContent.contentInfo;

    if (props.popupContent.id === 'bus') {
        const arr = [];
        for (let i = 0; i < 3; i++) {
            if (contentInfo[i] !== undefined) {
                const color = departureColor(contentInfo[i].departureTime);

                arr.push(
                    <div className="bus-block" key={i}>
                        <div className="scooter-attribute">
                            <a
                                className="bus-id"
                                href={`https://imhd.sk/ba/linka/${contentInfo[i].departureId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <b>{contentInfo[i].departureId}</b>
                            </a>
                            <div className="bus-arrow">
                                <Icon className="bus-arrow" icon="arrow-right" iconSize={13} />
                            </div>

                            <div className="bus-headedTo">{contentInfo[i].headedTo}</div>
                            <div className="bus-departureTime" style={{ color: color }}>
                                {contentInfo[i].departureTime} min
                            </div>
                        </div>
                    </div>,
                );
            }
        }

        return <React.Fragment>{arr}</React.Fragment>;
    }

    if (props.popupContent.id === 'scooters') {
        return (
            <div className="scooter-block">
                <div className="scooter-detail">
                    <div>
                        <div className="scooter-provider">
                            <a href={contentInfo.website} target="_blank" rel="noopener noreferrer">
                                {contentInfo.provider}
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className="scooter-attributes">
                            <span>{contentInfo.type}</span>
                            <div className="scooter-attribute">
                                <div>{contentInfo.battery}%</div>
                                <img src={lightning} alt="Lightning" className="scooter-image" />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="scooter-prices">
                        <span className="scooter-text"> To unlock</span>
                        <span className="scooter-price"> {contentInfo.unlock + contentInfo.currency}</span>
                    </div>
                    <div className="scooter-prices">
                        <span className="scooter-text"> Per minute</span>
                        <span className="scooter-price"> {contentInfo.perMin + contentInfo.currency}</span>
                    </div>
                </div>
                <div className="scooter-footer">
                    <div>
                        <a href={contentInfo.ios} target="_blank" rel="noopener noreferrer">
                            <img alt="App Store" src={appStore} />
                        </a>
                        <a href={contentInfo.android} target="_blank" rel="noopener noreferrer">
                            <img alt="Google Play" src={googlePlay} />
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    if (props.popupContent.id === 'restaurants') {
        return (
            <div className="scooter-block">
                <div className="scooter-detail">
                    <div>
                        <div className="scooter-provider">
                            <a href={contentInfo.website} target="_blank" rel="noopener noreferrer">
                                {contentInfo.name}
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className="scooter-attributes">
                            <span>{contentInfo.hours['open_now'] ? 'Open' : 'Closed'}</span>
                            {contentInfo.rating && (
                                <div className="scooter-attribute">
                                    <div>{contentInfo.rating}/10</div>
                                    <Icon className="scooter-image" icon={'star-empty'} iconSize={13} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="scooter-prices">
                        <span> {contentInfo.hours.display}</span>
                    </div>
                </div>
            </div>
        );
    }
};
export default MapPopup;
