import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import UserSettingsContext from '../../context/userSettingsContext';
import { useStore } from '../store/useStore';
import { Button, Collapse, Dialog, FormGroup, Icon, InputGroup, TextArea } from '@blueprintjs/core';
import VideoCarousel from '../components/VideoCarousel';
import './Video.scss';

function Video({ isDarkTheme, getVideoDuration }) {
    //VIDEO API ENDPOINTS
    const apiEndpoints = {
        getSignedUrl: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/getsignedurl`,
        saveVideo: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/savevideo`,
        getVideos: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/getvideos`,
        deleteVideo: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/deletevideo`,
        updateVideo: `https://fx1qjqcm98.execute-api.eu-west-1.amazonaws.com/dev/updatevideo`,
    };



    //GLOBAL STATE
    const isGlobalUser = useStore()[0].userSettings.isGlobal;
    const { userSettings } = useContext(UserSettingsContext);
    const { email } = userSettings;



    //VALUES
    const [videos, setVideos] = useState([]); // [{user, id, name, videoUrl, description, createdAt}]
    const [message, setMessage] = useState('');
    const screenBackground = useRef();
    const [currentVideo, setCurrentVideo] = useState(0);



    //GET ALL VIDEOS FROM DB
    useEffect(() => {
        setMessage('Getting videos...');
        // COMMENT OUT AND USE FREE VIDEOS BELOW WHEN TESTING
        fetch(apiEndpoints.getVideos, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({user: email})
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (data.error) {
                    setMessage(data.error);
                    setTimeout(() => {
                        setMessage('');
                    }, 2000);
                } else {
                    setVideos([...data.videos]);
                    setMessage('');
                }
            })
            .catch((error) => {
                setMessage(JSON.stringify(error));
                setTimeout(() => {
                    setMessage('');
                }, 2000);
            });
    }, []);



    //LISTEN FOR NEWLY UPLOADED AND DELETED VIDEOS (emitted in ManageVideosScreen.js)
    const addUploadedVideoToPlaylist = useCallback((e) => {
        setCurrentVideo(0);
        setVideos([e.detail, ...videos]);
    }, [videos]);

    const removeDeletedVideoFromPlaylist = useCallback(e => {
        let videosBefore = [...videos];
        videosBefore = videosBefore.filter(item => item.id !== e.detail.videoId);
        setCurrentVideo(0);
        setVideos([...videosBefore]);
    }, [videos])

    useEffect(() => {
        document.querySelector('body').addEventListener('videouploaded', addUploadedVideoToPlaylist);
        document.querySelector('body').addEventListener('videodeleted', removeDeletedVideoFromPlaylist);
        return () => {
            document.querySelector('body').removeEventListener('videouploaded', addUploadedVideoToPlaylist);
            document.querySelector('body').removeEventListener('videodeleted', removeDeletedVideoFromPlaylist);
        };
    }, [videos])



    //DIALOG
    const [dialogOpen, setDialogOpen] = useState(false);
    const [collapseOpen, setCollapseOpen] = useState(false);



    //VIDEO UPLOAD
    const [fileInfo, setFileInfo] = useState({ id: '', name: '', videoUrl: '', description: '', createdAt: '' });
    const { name, description } = fileInfo;
    const [formDisabled, setFormDisabled] = useState(false);

    const handleChange = (e) => {
        setFileInfo({ ...fileInfo, [e.target.name]: e.target.value });
    };

    const isNameUnique = name => {
        let allNames = [...videos].map(video => video.name.toLowerCase());
        if (allNames.includes(name.toLowerCase())) return false
        return true
    }

    const readVideo = () => {
        //disable form
        if (formDisabled) return;
        setFormDisabled(true);

        //check name (must be entered)
        if (!name || name.trim() === '') {
            setMessage('Please enter video name');
            setTimeout(() => {
                setMessage('');
                setFormDisabled(false);
            }, 2000);
            return;
        }

        //get fileInput html el.
        let fileInput = document.getElementById('fileInput');
        if (!fileInput.files.length) return;

        //read input file
        let reader = new FileReader();
        reader.onload = (e) => {
            //verify if file is .mp4
            if (!e.target.result.includes('data:video/mp4')) {
                setMessage('Please upload a supported .mp4 video file');
                setFormDisabled(false);
                setTimeout(() => {
                    setMessage('');
                }, 2000);
                return;
            }

            //check name (must be unique)
            if (!isNameUnique(name)) {
                setMessage('Video with such name already exists');
                setTimeout(() => {
                    setMessage('');
                    setFormDisabled(false);
                }, 2000);
            } else {
                setMessage('Video name validated');
                uploadVideo(e.target.result);
            }
        };

        reader.readAsDataURL(fileInput.files[0]);
    };

    const uploadVideo = (video) => {
        setMessage('Uploading Video... (might take a couple of seconds)');

        //get pre-signed url to aws s3 bucket
        fetch(apiEndpoints.getSignedUrl, { method: 'GET' })
            .then((response) => response.json())
            .then((data) => {
                //do some convert-video-to-base64 magic
                setMessage('...converting video...');
                let binary = atob(video.split(',')[1]);
                let array = [];
                for (var i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i));
                }
                let blobData = new Blob([new Uint8Array(array)], { type: 'video/mp4' });
                let objectUrl = data.url.split('?')[0];

                //use the pre-signed aws s3 bucket url to save the video
                fetch(data.url, { method: 'PUT', body: blobData })
                    .then((response) => {
                        //save videoUrl to db
                        setMessage('...video uploaded, saving...');
                        fetch(apiEndpoints.saveVideo, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                name: fileInfo.name,
                                videoUrl: objectUrl,
                                description: fileInfo.description,
                                user: email
                            }),
                        })
                            .then((response) => {
                                return response.json();
                            })
                            .then((data) => {
                                if (data.error) {
                                    console.log(data.error);
                                    setMessage(JSON.stringify(data.error));
                                    setTimeout(() => {
                                        setFormDisabled(false);
                                        setMessage('');
                                    }, 2000);
                                }
                                setVideos([data, ...videos]);
                                setMessage('Video uploaded');
                                setCurrentVideo(0);
                                setFileInfo({ user: email, id: '', name: '', videoUrl: '', description: '', createdAt: '' });
                                setTimeout(() => {
                                    setFormDisabled(false);
                                    setMessage('');
                                }, 2000);
                            })
                            .catch((error) => {
                                console.log(error);
                                setMessage('Video upload failed :(');
                                setTimeout(() => {
                                    setFormDisabled(false);
                                    setMessage('');
                                }, 2000);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        setMessage(JSON.stringify(error));
                        setTimeout(() => {
                            setFormDisabled(false);
                            setMessage('');
                        }, 2000);
                    });
            })
            .catch((error) => {
                console.log(error);
                setMessage(JSON.stringify(error));
                setTimeout(() => {
                    setFormDisabled(false);
                    setMessage('');
                }, 2000);
            });
    };



    //DELETE VIDEO
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [toDeleteVideoId, setToDeleteVideoId] = useState(null);

    const confirmDelete = (videoId) => {
        setToDeleteVideoId(videoId);
        setDeleteConfirmOpen(true);
    };

    const deleteVideo = (videoId) => {
        setToDeleteVideoId(null);
        setDeleteConfirmOpen(false);
        setCollapseOpen(false);
        setMessage('Deleting video...');

        fetch(apiEndpoints.deleteVideo, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: videoId, user: email }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (data && data.error) {
                    setMessage(data.error);
                    setTimeout(() => {
                        setMessage('');
                    }, 2000);
                } else {
                    setMessage('Video deleted');
                    const videosBefore = [...videos];
                    const filteredVideos = videosBefore.filter((item) => item.id !== videoId);
                    setVideos([...filteredVideos]);
                    setCurrentVideo(0); ////
                    setTimeout(() => {
                        setMessage('');
                    }, 2000);
                }
            })
            .catch((error) => {
                console.log(error);
                setMessage(JSON.stringify(error));
                setTimeout(() => {
                    setMessage('');
                }, 2000);
            });
    };



    //EDIT VIDEO
    const [editedVideo, setEditedVideo] = useState({ id: '', name: '', description: '' });
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [videoUpdateMessage, setVideoUpdateMessage] = useState('');

    const openEditDialog = (videoToEdit) => {
        setEditedVideo({ ...videoToEdit });
        setEditDialogOpen(true);
    };

    const closeEditDialogue = () => {
        setEditedVideo({ id: '', name: '', description: '' });
        setEditDialogOpen(false);
    };

    const handleEditChange = (e) => {
        setEditedVideo({ ...editedVideo, [e.target.name]: e.target.value });
    };

    const updateVideo = () => {
        setVideoUpdateMessage('Updating video...');

        fetch(apiEndpoints.updateVideo, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user: email, id: editedVideo.id, name: editedVideo.name, description: editedVideo.description }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (data && data.error) {
                    setVideoUpdateMessage(data.error);
                    setTimeout(() => {
                        setVideoUpdateMessage('');
                    }, 2000);
                } else {
                    setVideoUpdateMessage('Video updated');
                    const videosBefore = [...videos];
                    const updatedVideoIndex = videosBefore.findIndex((item) => item.id === editedVideo.id);
                    videosBefore[updatedVideoIndex] = { ...editedVideo };
                    setVideos([...videosBefore]);
                    setTimeout(() => {
                        setVideoUpdateMessage('');
                        closeEditDialogue();
                    }, 2000);
                }
            })
            .catch((error) => {
                setVideoUpdateMessage(JSON.stringify(error));
                setTimeout(() => {
                    setVideoUpdateMessage('');
                }, 2000);
            });
    };


    
    //RENDER
    return (
        <React.Fragment>
            <div ref={screenBackground} className="presentation-page">
                {videos && videos.length === 0 && (
                    <h3 style={{ color: 'white', fontSize: '2rem' }}>No Videos Loaded</h3>
                )}
                {videos && videos.length > 0 && (currentVideo || currentVideo === 0) && (
                    <VideoCarousel
                        videos={videos}
                        currentVideo={currentVideo}
                        setCurrentVideo={setCurrentVideo}
                        getVideoDuration={getVideoDuration}
                    />
                )}
                {isGlobalUser && <div
                    className="show-dialog-btn"
                    onClick={() => {
                        setDialogOpen(true);
                    }}
                >
                    <Icon icon="plus" color="white"></Icon>
                </div>}
            </div>

            {/* UPLOAD VIDEO DIALOG */}
            <Dialog
                className={isDarkTheme && 'bp3-dark'}
                isOpen={dialogOpen}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                onClose={() => {
                    setDialogOpen(false);
                }}
                title={`Video Control Panel`}
            >
                <div style={{ width: '100%', padding: '1rem' }}>
                    <h3 style={{ marginBottom: '0.25rem' }}>Upload Video:</h3>
                    <Button style={{ width: '100%' }} onClick={() => setCollapseOpen(!collapseOpen)}>
                        {collapseOpen ? 'Close' : 'Click to upload...'}
                    </Button>

                    <Collapse isOpen={collapseOpen}>
                        <br />
                        <FormGroup label={'Video Name'}>
                            <InputGroup
                                placeholder="Name (required)"
                                name="name"
                                value={name}
                                onChange={handleChange}
                                disabled={formDisabled}
                            />
                        </FormGroup>

                        <FormGroup label={'Video Description'}>
                            <TextArea
                                placeholder="Description (optional)"
                                name="description"
                                value={description}
                                onChange={handleChange}
                                style={{ width: '100%' }}
                                disabled={formDisabled}
                            />
                        </FormGroup>

                        {name && (
                            <Button style={{ width: '100%' }}>
                                <label className="bp4-file-input .modifier" htmlFor="fileInput">
                                    <input
                                        disabled={formDisabled}
                                        type="file"
                                        id="fileInput"
                                        name="fileInput"
                                        onChange={readVideo}
                                        hidden
                                    />
                                    <span className="bp4-file-upload-input">Choose video...</span>
                                </label>
                            </Button>
                        )}

                        <br />
                        <br />

                        {message && <p className="video-dialog-message">{message}</p>}
                    </Collapse>

                    <br />
                    <br />

                    <h3 style={{ marginBottom: '0.25rem' }}>Video List:</h3>
                    <div
                        className="video-list-box"
                        style={
                            isDarkTheme
                                ? { background: 'var(--dark-card-fill)' }
                                : { background: 'var(--light-card-fill)' }
                        }
                    >
                        {videos &&
                            videos.map((v) => (
                                <div className="video-list-line" key={v.id}>
                                    <div style={{ width: '33.3%', display: 'flex' }}>
                                        <p className="video-name">{v.name}</p>
                                    </div>

                                    <div style={{ width: '53.3%' }}>
                                        <p className="video-description">{v.description}</p>
                                    </div>

                                    <div style={{ width: '12.3%' }}>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                            <Icon
                                                icon="edit"
                                                className="video-controls-icon"
                                                onClick={() => openEditDialog(v)}
                                            ></Icon>
                                            <Icon
                                                icon="trash"
                                                className="video-controls-icon"
                                                onClick={() => confirmDelete(v.id)}
                                            ></Icon>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        {(!videos || (videos && videos.length < 1)) && (
                            <p style={{ textAlign: 'center', margin: 0, padding: 0 }}>No videos</p>
                        )}
                    </div>

                    {!collapseOpen && message && <p style={{ color: '#d33d17', textAlign: 'center' }}>{message}</p>}
                </div>
            </Dialog>

            {/* CONFIRM DELETE DIALOG */}
            <Dialog
                isOpen={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
                style={{ background: '#db3737', color: 'white' }}
            >
                <div
                    style={{
                        marginTop: '1.25rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <p style={{ textAlign: 'center', padding: 0, margin: 0 }}>
                        Are you sure you want to delete the video?
                    </p>
                    <div style={{ marginTop: '1rem' }}>
                        <span
                            onClick={() => deleteVideo(toDeleteVideoId)}
                            style={{ margin: '1rem 1rem 1rem 0rem', cursor: 'pointer' }}
                        >
                            DELETE
                        </span>
                        <span
                            onClick={() => {
                                setToDeleteVideoId(null);
                                setDeleteConfirmOpen(false);
                            }}
                            style={{ margin: '1rem 1rem 1rem 0rem', cursor: 'pointer' }}
                        >
                            CANCEL
                        </span>
                    </div>
                </div>
            </Dialog>

            {/* EDIT VIDEO DIALOG */}
            <Dialog className={isDarkTheme && 'bp3-dark'} isOpen={editDialogOpen} onClose={closeEditDialogue}>
                <div style={{ padding: '1rem' }}>
                    <FormGroup label={'New Name'}>
                        <InputGroup
                            placeholder="Name (required)"
                            name="name"
                            value={editedVideo.name}
                            onChange={handleEditChange}
                            disabled={formDisabled}
                        />
                    </FormGroup>

                    <FormGroup label={'New Description'}>
                        <TextArea
                            placeholder="Description (optional)"
                            name="description"
                            value={editedVideo.description}
                            onChange={handleEditChange}
                            style={{ width: '100%' }}
                            disabled={formDisabled}
                        />
                    </FormGroup>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Button onClick={updateVideo} style={{ margin: '0.5rem' }}>
                            Update
                        </Button>
                        <Button onClick={closeEditDialogue} style={{ margin: '0.5rem' }}>
                            Cancel
                        </Button>
                    </div>

                    {videoUpdateMessage && (
                        <p style={{ color: '#d33d17', textAlign: 'center', marginTop: '1rem' }}>{videoUpdateMessage}</p>
                    )}
                </div>
            </Dialog>
        </React.Fragment>
    );
}

export default Video;
