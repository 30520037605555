import React, { useContext, useEffect, useState, useRef } from 'react';
import DxcLogo from './DxcLogo';
import Location from '../Location';
import useDateTime from '../hooks/useDateTime';
import { AreaContext } from '../../indexFloor';
import { Button, Menu, MenuDivider, MenuItem, Icon } from '@blueprintjs/core';
import { useStore } from '../../store/useStore';
import WeatherIcon from '../WeatherIcon';

import { Popover2 } from '@blueprintjs/popover2';
import AutoPresent from '../AutoPresent';
import Settings from '../../pages/Settings';

const TopNavbar = (props) => {
    // MENU
    const [isOpen, setIsOpen] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    //USE STORE
    const dispatch = useStore()[1];

    //DATE & AREA
    let dateTime = useDateTime();
    const { areaInfo, setArea } = useContext(AreaContext);

    //WEATHER
    const [currentWeather, setCurrentWeather] = useState(null);
    const getWeatherInterval = useRef();
    const openweathermapApiKey = `c0e5e41b74fb9a8a3210ada8fa62a914`;

    const getCurrentWeather = (lat, lon) => {
        console.log('Getting fresh current weather info');
        return fetch(
            `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=metric&appid=${openweathermapApiKey}`,
        )
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (data && data.weather[0].icon) {
                    setCurrentWeather(data);
                } else {
                    setCurrentWeather(null);
                    console.log('Fresh current weather data fetching failed: ', data);
                }
            })
            .catch((error) => {
                console.log(error);
                return { error: JSON.stringify(error) || 'Something went wrong with current weather fething' };
            });
    };

    useEffect(() => {
        if (areaInfo && areaInfo.coordinates) {
            //get current weather as soon as areInfo loads
            getCurrentWeather(areaInfo.coordinates[0], areaInfo.coordinates[1]);

            //get fresh current weather data each hour
            getWeatherInterval.current = setInterval(() => {
                getCurrentWeather(areaInfo.coordinates[0], areaInfo.coordinates[1]);
            }, 3600000);
        }

        return () => {
            if (getWeatherInterval.current) clearInterval(getWeatherInterval.current);
        };
    }, [areaInfo]);

    const goToWeatherScreen = () => {
        const weatherButton = document.querySelector('[value="4"]'); //!!! can select the wrong button if you change things in BottomNavbar or something else has value="4" !!!
        weatherButton.click();
    };

    const toggleAutoPresent = function () {
        setIsOpen((prev) => !prev);
    };

    const toggleSettings = function () {
        setIsSettingsOpen((prev) => !prev);
    };

    //THEME
    const toggleThemeHandler = function () {
        props.onToggleThemeClick();
        dispatch('CHANGE_TEXT_STYLE', props.isDarkTheme);
    };

    const dxcMenu = (
        <Menu>
            <MenuItem icon="cog" text="Settings" title="Settings" onClick={toggleSettings} />
            <MenuItem icon="presentation" text="Present" title="Present" onClick={toggleAutoPresent} />
            <MenuItem
                icon={props.isDarkTheme ? 'flash' : 'moon'}
                text={props.isDarkTheme ? 'Light Theme' : 'Dark Theme'}
                onClick={toggleThemeHandler}
            />
            <MenuDivider />
            <MenuItem icon="log-out" text="Log-out" />
        </Menu>
    );

    //RENDER
    return (
        <nav className="bp3-navbar floor-navbar">
            <div className="bp3-navbar-group bp3-align-left">
                <DxcLogo isDarkTheme={props.isDarkTheme}></DxcLogo>
                <Location data={areaInfo}></Location>
            </div>

            <div className="bp3-navbar-group bp3-align-right floor-navbar-group-right">
                <h2 className="bp3-heading floor-time">{dateTime.time}</h2>
                {currentWeather && currentWeather.weather && currentWeather.weather[0].icon && (
                    <h2
                        className="bp3-heading floor-weather bp3-control"
                        onClick={goToWeatherScreen}
                        style={{ margin: 0, padding: 0 }}
                    >
                        {/*renderIcon(currentWeather.weather[0].icon)*/}
                        <WeatherIcon iconCode={currentWeather.weather[0].icon} />
                    </h2>
                )}

                <span className="bp3-navbar-divider" />

                <h2 className="bp3-heading floor-date">{dateTime.date}</h2>

                <span className="bp3-navbar-divider" />

                <Popover2 content={dxcMenu} minimal={true} placement="bottom-end">
                    <Button>
                        <Icon icon="home" />
                    </Button>
                </Popover2>
            </div>

            <AutoPresent isOpen={isOpen} isDarkTheme={props.isDarkTheme} toggleAutoPresent={toggleAutoPresent} />

            <Settings isOpen={isSettingsOpen} isDarkTheme={props.isDarkTheme} setIsOpen={setIsSettingsOpen}></Settings>
        </nav>
    );
};

export default TopNavbar;
