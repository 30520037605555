/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getActionById = /* GraphQL */ `
  query GetActionById($ActionId: ID!) {
    getActionById(ActionId: $ActionId) {
      condition
      entityId
      id
      isActive
      reactor {
        context
        id
        isActive
        name
      }
    }
  }
`;
export const getActionsByCustomerId = /* GraphQL */ `
  query GetActionsByCustomerId($CustomerId: ID!, $pagination: PaginationInput) {
    getActionsByCustomerId(CustomerId: $CustomerId, pagination: $pagination) {
      actions {
        condition
        entityId
        id
        isActive
      }
      nextToken
    }
  }
`;
export const getActionsByEntityId = /* GraphQL */ `
  query GetActionsByEntityId($EntityId: ID!) {
    getActionsByEntityId(EntityId: $EntityId) {
      condition
      entityId
      id
      isActive
      reactor {
        context
        id
        isActive
        name
      }
    }
  }
`;
export const getActionsByLocationCode = /* GraphQL */ `
  query GetActionsByLocationCode($CustomerId: ID!, $LocationCode: String!) {
    getActionsByLocationCode(
      CustomerId: $CustomerId
      LocationCode: $LocationCode
    ) {
      condition
      entityId
      id
      isActive
      reactor {
        context
        id
        isActive
        name
      }
    }
  }
`;
export const getActionsByLocationId = /* GraphQL */ `
  query GetActionsByLocationId($LocationId: ID!) {
    getActionsByLocationId(LocationId: $LocationId) {
      condition
      entityId
      id
      isActive
      reactor {
        context
        id
        isActive
        name
      }
    }
  }
`;
export const getAnalytics = /* GraphQL */ `
  query GetAnalytics($input: GetAnalyticsInput!, $pagination: PaginationInput) {
    getAnalytics(input: $input, pagination: $pagination) {
      nextToken
      timestreams {
        timestamp
      }
    }
  }
`;
export const getAnalyticsQueries = /* GraphQL */ `
  query GetAnalyticsQueries($CustomerId: ID!) {
    getAnalyticsQueries(CustomerId: $CustomerId) {
      description
      id
      name
      sql
    }
  }
`;
export const getAreaById = /* GraphQL */ `
  query GetAreaById($AreaId: ID!) {
    getAreaById(AreaId: $AreaId) {
      code
      floor
      id
      locationId
      misc
      name
      pic
    }
  }
`;
export const getAreasByAreaCode = /* GraphQL */ `
  query GetAreasByAreaCode($AreaCode: String!) {
    getAreasByAreaCode(AreaCode: $AreaCode) {
      code
      floor
      id
      locationId
      misc
      name
      pic
    }
  }
`;
export const getAreasByFloor = /* GraphQL */ `
  query GetAreasByFloor($Floor: String!, $LocationCode: String!) {
    getAreasByFloor(Floor: $Floor, LocationCode: $LocationCode) {
      code
      floor
      id
      locationId
      misc
      name
      pic
    }
  }
`;
export const getAreasByLocationCode = /* GraphQL */ `
  query GetAreasByLocationCode($LocationCode: String!) {
    getAreasByLocationCode(LocationCode: $LocationCode) {
      code
      floor
      id
      locationId
      misc
      name
      pic
    }
  }
`;
export const getAreasByLocationId = /* GraphQL */ `
  query GetAreasByLocationId($LocationId: ID!) {
    getAreasByLocationId(LocationId: $LocationId) {
      code
      floor
      id
      locationId
      misc
      name
      pic
    }
  }
`;
export const getCustomerById = /* GraphQL */ `
  query GetCustomerById($CustomerId: ID!) {
    getCustomerById(CustomerId: $CustomerId) {
      address
      admins
      email
      id
      name
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers {
    getCustomers {
      address
      admins
      email
      id
      name
    }
  }
`;
export const getDeviceById = /* GraphQL */ `
  query GetDeviceById($DeviceId: ID!) {
    getDeviceById(DeviceId: $DeviceId) {
      area {
        code
        floor
        id
        locationId
        misc
        name
        pic
      }
      hwProfile {
        brand
        firmware
        id
        iotProtocol
        manufacturer
        model
        powerType
        productNumber
        vendor
        warranty
      }
      id
      serialNumber
      status
    }
  }
`;
export const getDeviceBySerialNumber = /* GraphQL */ `
  query GetDeviceBySerialNumber($SerialNumber: String!) {
    getDeviceBySerialNumber(SerialNumber: $SerialNumber) {
      area {
        code
        floor
        id
        locationId
        misc
        name
        pic
      }
      hwProfile {
        brand
        firmware
        id
        iotProtocol
        manufacturer
        model
        powerType
        productNumber
        vendor
        warranty
      }
      id
      serialNumber
      status
    }
  }
`;
export const getDevicesByAreaCode = /* GraphQL */ `
  query GetDevicesByAreaCode(
    $AreaCode: String!
    $CustomerId: ID!
    $Floor: String!
    $LocationCode: String!
    $pagination: PaginationInput
  ) {
    getDevicesByAreaCode(
      AreaCode: $AreaCode
      CustomerId: $CustomerId
      Floor: $Floor
      LocationCode: $LocationCode
      pagination: $pagination
    ) {
      devices {
        id
        serialNumber
        status
      }
      nextToken
    }
  }
`;
export const getDevicesByCity = /* GraphQL */ `
  query GetDevicesByCity(
    $City: String!
    $Country: String!
    $CustomerId: ID!
    $State: String!
    $pagination: PaginationInput
  ) {
    getDevicesByCity(
      City: $City
      Country: $Country
      CustomerId: $CustomerId
      State: $State
      pagination: $pagination
    ) {
      devices {
        id
        serialNumber
        status
      }
      nextToken
    }
  }
`;
export const getDevicesByCountry = /* GraphQL */ `
  query GetDevicesByCountry(
    $Country: String!
    $CustomerId: ID!
    $pagination: PaginationInput
  ) {
    getDevicesByCountry(
      Country: $Country
      CustomerId: $CustomerId
      pagination: $pagination
    ) {
      devices {
        id
        serialNumber
        status
      }
      nextToken
    }
  }
`;
export const getDevicesByCustomerId = /* GraphQL */ `
  query GetDevicesByCustomerId($CustomerId: ID!, $pagination: PaginationInput) {
    getDevicesByCustomerId(CustomerId: $CustomerId, pagination: $pagination) {
      devices {
        id
        serialNumber
        status
      }
      nextToken
    }
  }
`;
export const getDevicesByFloor = /* GraphQL */ `
  query GetDevicesByFloor(
    $CustomerId: ID!
    $Floor: String!
    $LocationCode: String!
  ) {
    getDevicesByFloor(
      CustomerId: $CustomerId
      Floor: $Floor
      LocationCode: $LocationCode
    ) {
      area {
        code
        floor
        id
        locationId
        misc
        name
        pic
      }
      hwProfile {
        brand
        firmware
        id
        iotProtocol
        manufacturer
        model
        powerType
        productNumber
        vendor
        warranty
      }
      id
      serialNumber
      status
    }
  }
`;
export const getDevicesByHWProfileId = /* GraphQL */ `
  query GetDevicesByHWProfileId(
    $HWProfileId: String!
    $HWTypeId: ID!
    $pagination: PaginationInput
  ) {
    getDevicesByHWProfileId(
      HWProfileId: $HWProfileId
      HWTypeId: $HWTypeId
      pagination: $pagination
    ) {
      devices {
        id
        serialNumber
        status
      }
      nextToken
    }
  }
`;
export const getDevicesByHWTypeId = /* GraphQL */ `
  query GetDevicesByHWTypeId($HWTypeId: ID!, $pagination: PaginationInput) {
    getDevicesByHWTypeId(HWTypeId: $HWTypeId, pagination: $pagination) {
      devices {
        id
        serialNumber
        status
      }
      nextToken
    }
  }
`;
export const getDevicesByLocationCode = /* GraphQL */ `
  query GetDevicesByLocationCode(
    $CustomerId: ID!
    $LocationCode: String!
    $pagination: PaginationInput
  ) {
    getDevicesByLocationCode(
      CustomerId: $CustomerId
      LocationCode: $LocationCode
      pagination: $pagination
    ) {
      devices {
        id
        serialNumber
        status
      }
      nextToken
    }
  }
`;
export const getDevicesByLocationId = /* GraphQL */ `
  query GetDevicesByLocationId($LocationId: ID!, $pagination: PaginationInput) {
    getDevicesByLocationId(LocationId: $LocationId, pagination: $pagination) {
      devices {
        id
        serialNumber
        status
      }
      nextToken
    }
  }
`;
export const getDevicesByPostCode = /* GraphQL */ `
  query GetDevicesByPostCode(
    $City: String!
    $Country: String!
    $CustomerId: ID!
    $Postcode: String!
    $State: String!
    $pagination: PaginationInput
  ) {
    getDevicesByPostCode(
      City: $City
      Country: $Country
      CustomerId: $CustomerId
      Postcode: $Postcode
      State: $State
      pagination: $pagination
    ) {
      devices {
        id
        serialNumber
        status
      }
      nextToken
    }
  }
`;
export const getDevicesByPowerType = /* GraphQL */ `
  query GetDevicesByPowerType(
    $HWProfileId: String!
    $HWTypeId: ID!
    $PowerType: String!
    $pagination: PaginationInput
  ) {
    getDevicesByPowerType(
      HWProfileId: $HWProfileId
      HWTypeId: $HWTypeId
      PowerType: $PowerType
      pagination: $pagination
    ) {
      devices {
        id
        serialNumber
        status
      }
      nextToken
    }
  }
`;
export const getDevicesByState = /* GraphQL */ `
  query GetDevicesByState(
    $Country: String!
    $CustomerId: ID!
    $State: String!
    $pagination: PaginationInput
  ) {
    getDevicesByState(
      Country: $Country
      CustomerId: $CustomerId
      State: $State
      pagination: $pagination
    ) {
      devices {
        id
        serialNumber
        status
      }
      nextToken
    }
  }
`;
export const getDevicesByStreet = /* GraphQL */ `
  query GetDevicesByStreet(
    $City: String!
    $Country: String!
    $CustomerId: ID!
    $Postcode: String!
    $State: String!
    $Street: String!
    $pagination: PaginationInput
  ) {
    getDevicesByStreet(
      City: $City
      Country: $Country
      CustomerId: $CustomerId
      Postcode: $Postcode
      State: $State
      Street: $Street
      pagination: $pagination
    ) {
      devices {
        id
        serialNumber
        status
      }
      nextToken
    }
  }
`;
export const getEntitiesByAreaCode = /* GraphQL */ `
  query GetEntitiesByAreaCode(
    $AreaCode: String!
    $CustomerId: ID!
    $Floor: String!
    $LocationCode: String!
    $pagination: PaginationInput
  ) {
    getEntitiesByAreaCode(
      AreaCode: $AreaCode
      CustomerId: $CustomerId
      Floor: $Floor
      LocationCode: $LocationCode
      pagination: $pagination
    ) {
      entities {
        dataType
        id
        isActive
        lastValue
        measure
        unit
      }
      nextToken
    }
  }
`;
export const getEntitiesByCustomerId = /* GraphQL */ `
  query GetEntitiesByCustomerId(
    $CustomerId: ID!
    $pagination: PaginationInput
  ) {
    getEntitiesByCustomerId(CustomerId: $CustomerId, pagination: $pagination) {
      entities {
        dataType
        id
        isActive
        lastValue
        measure
        unit
      }
      nextToken
    }
  }
`;
export const getEntitiesByDeviceId = /* GraphQL */ `
  query GetEntitiesByDeviceId($DeviceId: ID!) {
    getEntitiesByDeviceId(DeviceId: $DeviceId) {
      dataType
      device {
        id
        serialNumber
        status
      }
      id
      isActive
      lastValue
      measure
      unit
    }
  }
`;
export const getEntitiesByFloor = /* GraphQL */ `
  query GetEntitiesByFloor(
    $CustomerId: ID!
    $Floor: String!
    $LocationCode: String!
    $pagination: PaginationInput
  ) {
    getEntitiesByFloor(
      CustomerId: $CustomerId
      Floor: $Floor
      LocationCode: $LocationCode
      pagination: $pagination
    ) {
      entities {
        dataType
        id
        isActive
        lastValue
        measure
        unit
      }
      nextToken
    }
  }
`;
export const getEntitiesByLocationCode = /* GraphQL */ `
  query GetEntitiesByLocationCode(
    $CustomerId: ID!
    $LocationCode: String!
    $pagination: PaginationInput
  ) {
    getEntitiesByLocationCode(
      CustomerId: $CustomerId
      LocationCode: $LocationCode
      pagination: $pagination
    ) {
      entities {
        dataType
        id
        isActive
        lastValue
        measure
        unit
      }
      nextToken
    }
  }
`;
export const getEntitiesByLocationId = /* GraphQL */ `
  query GetEntitiesByLocationId(
    $LocationId: ID!
    $pagination: PaginationInput
  ) {
    getEntitiesByLocationId(LocationId: $LocationId, pagination: $pagination) {
      entities {
        dataType
        id
        isActive
        lastValue
        measure
        unit
      }
      nextToken
    }
  }
`;
export const getEntityByEntityId = /* GraphQL */ `
  query GetEntityByEntityId($EntityId: ID!) {
    getEntityByEntityId(EntityId: $EntityId) {
      dataType
      device {
        id
        serialNumber
        status
      }
      id
      isActive
      lastValue
      measure
      unit
    }
  }
`;
export const getExtSourceData = /* GraphQL */ `
  query GetExtSourceData($input: GetExtSourceDataInput) {
    getExtSourceData(input: $input) {
      data
      type
    }
  }
`;
export const getHardwareProfileById = /* GraphQL */ `
  query GetHardwareProfileById($HWProfileId: ID!) {
    getHardwareProfileById(HWProfileId: $HWProfileId) {
      brand
      customer {
        address
        admins
        email
        id
        name
      }
      firmware
      hwType {
        id
        name
      }
      id
      iotProtocol
      manufacturer
      model
      powerType
      productNumber
      vendor
      warranty
    }
  }
`;
export const getHardwareProfilesByCustomerId = /* GraphQL */ `
  query GetHardwareProfilesByCustomerId($CustomerId: ID!) {
    getHardwareProfilesByCustomerId(CustomerId: $CustomerId) {
      brand
      customer {
        address
        admins
        email
        id
        name
      }
      firmware
      hwType {
        id
        name
      }
      id
      iotProtocol
      manufacturer
      model
      powerType
      productNumber
      vendor
      warranty
    }
  }
`;
export const getHardwareProfilesByHWTypeId = /* GraphQL */ `
  query GetHardwareProfilesByHWTypeId($HWTypeId: ID!) {
    getHardwareProfilesByHWTypeId(HWTypeId: $HWTypeId) {
      brand
      customer {
        address
        admins
        email
        id
        name
      }
      firmware
      hwType {
        id
        name
      }
      id
      iotProtocol
      manufacturer
      model
      powerType
      productNumber
      vendor
      warranty
    }
  }
`;
export const getHardwareProfilesByLocationId = /* GraphQL */ `
  query GetHardwareProfilesByLocationId($LocationId: ID!) {
    getHardwareProfilesByLocationId(LocationId: $LocationId) {
      brand
      customer {
        address
        admins
        email
        id
        name
      }
      firmware
      hwType {
        id
        name
      }
      id
      iotProtocol
      manufacturer
      model
      powerType
      productNumber
      vendor
      warranty
    }
  }
`;
export const getHardwareTypeById = /* GraphQL */ `
  query GetHardwareTypeById($HWTypeId: ID!) {
    getHardwareTypeById(HWTypeId: $HWTypeId) {
      id
      name
    }
  }
`;
export const getHardwareTypesByCustomerId = /* GraphQL */ `
  query GetHardwareTypesByCustomerId($CustomerId: ID!) {
    getHardwareTypesByCustomerId(CustomerId: $CustomerId) {
      id
      name
    }
  }
`;
export const getLocationByLocationCode = /* GraphQL */ `
  query GetLocationByLocationCode($LocationCode: String!) {
    getLocationByLocationCode(LocationCode: $LocationCode) {
      city
      coordinates
      country
      customer {
        address
        admins
        email
        id
        name
      }
      id
      postcode
      shortcode
      state
      street
      streetNo
    }
  }
`;
export const getLocationByLocationId = /* GraphQL */ `
  query GetLocationByLocationId($LocationId: ID!) {
    getLocationByLocationId(LocationId: $LocationId) {
      city
      coordinates
      country
      customer {
        address
        admins
        email
        id
        name
      }
      id
      postcode
      shortcode
      state
      street
      streetNo
    }
  }
`;
export const getLocationsByCity = /* GraphQL */ `
  query GetLocationsByCity(
    $City: String!
    $Country: String!
    $CustomerId: ID!
    $State: String!
  ) {
    getLocationsByCity(
      City: $City
      Country: $Country
      CustomerId: $CustomerId
      State: $State
    ) {
      city
      coordinates
      country
      customer {
        address
        admins
        email
        id
        name
      }
      id
      postcode
      shortcode
      state
      street
      streetNo
    }
  }
`;
export const getLocationsByCountry = /* GraphQL */ `
  query GetLocationsByCountry($Country: String!, $CustomerId: ID!) {
    getLocationsByCountry(Country: $Country, CustomerId: $CustomerId) {
      city
      coordinates
      country
      customer {
        address
        admins
        email
        id
        name
      }
      id
      postcode
      shortcode
      state
      street
      streetNo
    }
  }
`;
export const getLocationsByCustomerId = /* GraphQL */ `
  query GetLocationsByCustomerId(
    $CustomerId: ID!
    $pagination: PaginationInput
  ) {
    getLocationsByCustomerId(CustomerId: $CustomerId, pagination: $pagination) {
      locations {
        city
        coordinates
        country
        id
        postcode
        shortcode
        state
        street
        streetNo
      }
      nextToken
    }
  }
`;
export const getLocationsByHWProfileId = /* GraphQL */ `
  query GetLocationsByHWProfileId($HWProfileId: String!, $HWTypeId: ID!) {
    getLocationsByHWProfileId(HWProfileId: $HWProfileId, HWTypeId: $HWTypeId) {
      city
      coordinates
      country
      customer {
        address
        admins
        email
        id
        name
      }
      id
      postcode
      shortcode
      state
      street
      streetNo
    }
  }
`;
export const getLocationsByPostCode = /* GraphQL */ `
  query GetLocationsByPostCode(
    $City: String!
    $Country: String!
    $CustomerId: ID!
    $Postcode: String!
    $State: String!
  ) {
    getLocationsByPostCode(
      City: $City
      Country: $Country
      CustomerId: $CustomerId
      Postcode: $Postcode
      State: $State
    ) {
      city
      coordinates
      country
      customer {
        address
        admins
        email
        id
        name
      }
      id
      postcode
      shortcode
      state
      street
      streetNo
    }
  }
`;
export const getLocationsByState = /* GraphQL */ `
  query GetLocationsByState(
    $Country: String!
    $CustomerId: ID!
    $State: String!
  ) {
    getLocationsByState(
      Country: $Country
      CustomerId: $CustomerId
      State: $State
    ) {
      city
      coordinates
      country
      customer {
        address
        admins
        email
        id
        name
      }
      id
      postcode
      shortcode
      state
      street
      streetNo
    }
  }
`;
export const getLocationsByStreet = /* GraphQL */ `
  query GetLocationsByStreet(
    $City: String!
    $Country: String!
    $CustomerId: ID!
    $Postcode: String!
    $State: String!
    $Street: String!
  ) {
    getLocationsByStreet(
      City: $City
      Country: $Country
      CustomerId: $CustomerId
      Postcode: $Postcode
      State: $State
      Street: $Street
    ) {
      city
      coordinates
      country
      customer {
        address
        admins
        email
        id
        name
      }
      id
      postcode
      shortcode
      state
      street
      streetNo
    }
  }
`;
export const getReactorByActionId = /* GraphQL */ `
  query GetReactorByActionId($ActionId: ID!) {
    getReactorByActionId(ActionId: $ActionId) {
      context
      id
      isActive
      location {
        city
        coordinates
        country
        id
        postcode
        shortcode
        state
        street
        streetNo
      }
      name
      target {
        id
        name
        type
      }
    }
  }
`;
export const getReactorById = /* GraphQL */ `
  query GetReactorById($ReactorId: ID!) {
    getReactorById(ReactorId: $ReactorId) {
      context
      id
      isActive
      location {
        city
        coordinates
        country
        id
        postcode
        shortcode
        state
        street
        streetNo
      }
      name
      target {
        id
        name
        type
      }
    }
  }
`;
export const getReactorsByLocationId = /* GraphQL */ `
  query GetReactorsByLocationId($LocationId: ID!) {
    getReactorsByLocationId(LocationId: $LocationId) {
      context
      id
      isActive
      location {
        city
        coordinates
        country
        id
        postcode
        shortcode
        state
        street
        streetNo
      }
      name
      target {
        id
        name
        type
      }
    }
  }
`;
export const getTargetById = /* GraphQL */ `
  query GetTargetById($TargetId: ID!) {
    getTargetById(TargetId: $TargetId) {
      id
      name
      type
    }
  }
`;
export const getTargetsByCustomerId = /* GraphQL */ `
  query GetTargetsByCustomerId($CustomerId: ID!) {
    getTargetsByCustomerId(CustomerId: $CustomerId) {
      id
      name
      type
    }
  }
`;
export const getUserById = /* GraphQL */ `
  query GetUserById($UserId: ID!) {
    getUserById(UserId: $UserId) {
      areaId
      customerId
      email
      group
      id
      isActive
      name
    }
  }
`;
export const getUsersByGroup = /* GraphQL */ `
  query GetUsersByGroup($Group: String!, $pagination: PaginationInput) {
    getUsersByGroup(Group: $Group, pagination: $pagination) {
      nextToken
      users {
        areaId
        customerId
        email
        group
        id
        isActive
        name
      }
    }
  }
`;
export const getUsersByLocation = /* GraphQL */ `
  query GetUsersByLocation($LocationId: ID!, $pagination: PaginationInput) {
    getUsersByLocation(LocationId: $LocationId, pagination: $pagination) {
      nextToken
      users {
        areaId
        customerId
        email
        group
        id
        isActive
        name
      }
    }
  }
`;
