import React from 'react';
import './style.css';
import { Icon } from '@blueprintjs/core';

const ErrorCard = (props) => {
    if (!props.visible) {
        return null;
    }

    return (
        <div
            className={`error-card-container ${props.className ? props.className : ''}`}
            style={props.style ? props.style : null}
        >
            <span className="error-icon-container">
                <Icon size={16} icon="error" className="error-icon" color="white" />
            </span>
            <span className="error-card-text">{props.text}</span>
        </div>
    );
};

export default ErrorCard;
