// import './ManageGeneralSettings.scss';
import { useStore } from '../../store/useStore';
const ManageGeneralSettings = function () {
    const [globalStore, dispatch] = useStore();
    const isGlobal = globalStore.userSettings.isGlobal;
    const onLabelClickHandler = function () {
        dispatch('TOGGLE_USERTYPE');
    };

    return (
        <div
            style={{
                minHeight: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}
        >
            <h2 style={{ padding: 0, margin: 0 }}>GENERAL</h2> <br />
            <div style={{ display: 'flex' }}>
                <span style={{ width: '25ch' }}>Global User:</span>
                <label className="bp3-control bp3-switch">
                    <input defaultChecked={isGlobal} onClick={onLabelClickHandler} type="checkbox" />
                    <span className="bp3-control-indicator bp3-active"></span>
                    {isGlobal ? 'On' : 'Off'}
                </label>
            </div>
        </div>
    );
};

export default ManageGeneralSettings;
