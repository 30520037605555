import React from 'react';
import { FaSun, FaCloudSun, FaCloud, FaCloudRain, FaCloudSunRain, FaSnowflake, FaSmog, FaMoon, FaCloudMoon, FaCloudMoonRain, FaTemperatureLow, FaBolt } from 'react-icons/fa';

const WeatherIcon = ({ iconCode }) => {

    if (!iconCode) console.error(`No iconCode props passed to WeatherIcon.js`);

    const renderIcon = (iconCode) => { //maps ugly openweathermap icons to react-icons
        switch (iconCode) {
            case "01d":
                return <FaSun />
            case "01n":
                return <FaMoon />
            case "02d":
                return <FaCloudSun />
            case "02n":
                return <FaCloudMoon />
            case "03d":
                return <FaCloud />
            case "03n":
                return <FaCloud />
            case "04d":
                return <FaCloud />
            case "04n":
                return <FaCloud />
            case "09d":
                return <FaCloudRain />
            case "09n":
                return <FaCloudRain />
            case "10d":
                return <FaCloudSunRain />
            case "10n":
                return <FaCloudMoonRain />
            case "11d":
                return <FaBolt />
            case "11n":
                return <FaBolt />
            case "13d":
                return <FaSnowflake />
            case "13n":
                return <FaSnowflake />
            case "50d":
                return <FaSmog />
            case "50n":
                return <FaSmog />
            default:
                return <FaTemperatureLow />;
        }     
    }


return (
    <React.Fragment> {iconCode && renderIcon(iconCode)} </React.Fragment>
)
}

export default WeatherIcon