import React from 'react';
import { useStore } from '../../../floor-app/store/useStore';
// import { useState } from 'react';

function NavbarButtons(props) {
    // const [activeButton, setActiveButton] = useState('BUS');
    const [globalStore, dispatch] = useStore();

    const onButtonClickHandler = async function (e) {
        const layerId = e.target.id;
        if (globalStore[`${layerId}GeoJson`] === null) {
            await dispatch(`FETCH_GEOJSON`, layerId);
            dispatch('TOGGLE_LAYER', layerId);
        }

        if (globalStore[`${layerId}GeoJson`] !== null) {
            dispatch('TOGGLE_LAYER', layerId);
            dispatch(`TOGGLE_GEOJSON`, layerId);
        }
    };

    // const buttonHandlerClear = () => {
    //     can clear all markers
    // };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                minWidth: '100%',
            }}
        >
            <button
                onClick={onButtonClickHandler}
                id={'bus'}
                key={0}
                className={`bp3-button ${globalStore.busGeoJson?.toggled ? 'bp3-active' : ''}`}
                style={{ width: '100%', marginTop: '2rem' }}
            >
                Bus
            </button>

            {/* <button
                onClick={buttonHandler}
                id={'TRAIN'}
                key={1}
                className={`bp3-button ${false ? 'bp3-active' : ''}`}
                style={{ width: '100%', marginTop: '0.5rem' }}
            >
                Train
            </button>

            <button
                onClick={buttonHandler}
                id={'PLANE'}
                key={2}
                className={`bp3-button ${false ? 'bp3-active' : ''}`}
                style={{ width: '100%', marginTop: '0.5rem' }}
            >
                Plane
            </button> */}

            <button
                onClick={onButtonClickHandler}
                id={'scooters'}
                key={3}
                className={`bp3-button ${globalStore.scootersGeoJson?.toggled ? 'bp3-active' : ''}`}
                style={{ width: '100%', marginTop: '0.5rem' }}
            >
                Scooters
            </button>

            <button
                onClick={onButtonClickHandler}
                id={'restaurants'}
                key={4}
                className={`bp3-button ${globalStore.restaurantsGeoJson?.toggled ? 'bp3-active' : ''}`}
                style={{ width: '100%', marginTop: '0.5rem' }}
            >
                Restaurants
            </button>

            {/* <button
                onClick={buttonHandlerClear}
                id={'CLEAR'}
                key={-1}
                className="bp3-button floor-filter-button"
                style={{ width: '100%', marginTop: '0.5rem' }}
            >
                Clear
            </button> */}
        </div>
    );
}

export default NavbarButtons;
