import React from 'react';
import './style.css';
import { Button as BpButton } from '@blueprintjs/core';
import ReactLoading from 'react-loading';

const Button = (props) => {
    return (
        <BpButton
            {...props}
            icon={
                props.is_loading ? (
                    <ReactLoading type="spin" color="black" height={32} width={32} />
                ) : props.icon ? (
                    props.icon
                ) : null
            }
            disabled={props.is_loading ? true : props.disabled ? props.disabled : false}
            className={`styled-button ${props.className ? props.className : ''}`}
        />
    );
};

export default Button;
