import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from './common/pages/login/Login';
import Playground from './common/pages/playground/Playground';
import Navbar from './common/components/navbar';
import Footer from './common/components/footer';
// import CreateCustomerPage from './admin/pages/create-customer-page/CreateCustomerPage';
import { isGod, isFloor } from './common/utils/AmplifyUtils';
import AdminPage from './admin/pages/admin-page';
import IndexFloor from './floor-app/indexFloor';
import IndexAdmin from './admin/indexAdmin';

import Sidebar from '../web/common/components/sidebar/Sidebar';

const routes = (currentUser, path) => {
    // let params = path.split('/');

    if (!currentUser) {
        return (
            <>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/playground" exact component={Playground} />
                    <Redirect from="*" to="/login" />
                </Switch>
            </>
        );
    } else {
        // pages that required login

        if (isGod(currentUser)) {
            //Index admin component is here only for importing global scss file purposes. Each app type will have it's own 'starting point'.
            return (
                <IndexAdmin>
                    <Navbar path={path} />
                    <Sidebar />
                    <div className="page-content">
                        <Switch>
                            <Route path="/playground" exact component={Playground} />
                            <Route path="/adminPage" exact component={AdminPage} />
                            <Redirect from="*" to="/adminPage" />
                        </Switch>
                    </div>
                    <Footer />
                </IndexAdmin>
            );
        } else if (isFloor(currentUser)) {
            return (
                <>
                    <Switch>
                        <Route path="/floor" exact component={IndexFloor} />
                        <Route path="/playground" exact component={Playground} />
                        <Redirect from="*" to="/floor" />
                    </Switch>
                </>
            );
        } else {
            return (
                <>
                    <Navbar path={path} />
                    <div className="page-content">
                        <Switch>
                            <Route path="/playground" exact component={Playground} />
                            <Redirect from="*" to="/playground" />
                        </Switch>
                    </div>
                    <Footer />
                </>
            );
        }
    }
};

export default routes;
