import PanelCard from './PanelCard'
import React from 'react'

const CustomersPanel = () => {
  return (
    <PanelCard>
        Customers Panel
    </PanelCard>
  )
}

export default CustomersPanel