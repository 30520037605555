import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import Button from '../../components/button/Button';
import TextInput from '../../components/text-input/TextInput';
import ErrorCard from '../../components/error-card/ErrorCard';
import Header from '../../components/header/Header';
import { Link } from 'react-router-dom';
import { isEmailFormatCorrect, isStringEmpty } from '../../utils/GeneralUtils';
import { signInUser } from '../../utils/AmplifyUtils';
// import _ from 'lodash';
import './style.scss';
import DXC_logo from '../../../../design/images/dxc_logo.png';
import { AuthContext } from '../../Auth';

const Login = (props) => {
    const [isLoading, setIsLoading] = useState(0);
    const [userName, setUserName] = useState('');
    const [isUsernameCorrect, setIsUsernameCorrect] = useState({ isEmpty: false, isFormatIncorrect: false });
    const [password, setPassword] = useState('');
    const [isPasswordProvided, setIsPasswordProvided] = useState(true);
    const [isErrorCardVisible, setIsErrorCardVisible] = useState(false);

    const { setCurrentUser } = useContext(AuthContext);

    const handleSignIn = () => {
        let bothCorrect = true;

        if (isStringEmpty(userName)) {
            setIsUsernameCorrect({ isEmpty: true, isFormatIncorrect: false });
            bothCorrect = false;
        } else {
            if (!isEmailFormatCorrect(userName)) {
                setIsUsernameCorrect({ isEmpty: false, isFormatIncorrect: true });
                bothCorrect = false;
            } else {
                setIsUsernameCorrect({ isEmpty: false, isFormatIncorrect: false });
            }
        }

        if (isStringEmpty(password)) {
            setIsPasswordProvided(false);
            bothCorrect = false;
        } else {
            setIsPasswordProvided(true);
        }

        if (bothCorrect) {
            setIsLoading(1);
            signInUser(userName, password)
                .then((userData) => {
                    return setCurrentUser(userData);
                })
                .catch((e) => {
                    setIsErrorCardVisible(true);
                    setIsLoading(0);
                });
        }
    };

    //Email style
    let userNameStyle = {
        border: '0px',
        marginBottom: '40px',
    };

    //Password style
    let passwordStyle = {
        border: '0px',
        marginBottom: '40px',
    };

    let hasEmailError = false;
    let emailErrorMsg = '';
    if (isUsernameCorrect.isEmpty) {
        hasEmailError = true;
        emailErrorMsg = 'Please, provide your email address.';
    } else if (isUsernameCorrect.isFormatIncorrect) {
        hasEmailError = true;
        emailErrorMsg = 'Your email address is in incorrect format.';
    }

    return (
        <ScreenClassRender
            render={(screenClass) => (
                <div className="login-page-container">
                    <div className="right-triangle" />
                    <div className="left-triangle" />
                    <Container fluid>
                        <Row align="center" style={{ height: '80vh' }}>
                            <Col xl={4} lg={4} md={4}></Col>
                            <Col xl={4} lg={4} md={4} className="login-form-container">
                                <Row justify="center">
                                    <img alt="DXC Logo" src={DXC_logo} width="60%" height="60%" />
                                </Row>
                                <Row justify="center">
                                    <div style={{ marginBottom: '25px', marginTop: '15px' }}>
                                        <Header level={3} className="sign-in-header">
                                            Sign in to SCOOT
                                        </Header>
                                    </div>
                                </Row>
                                <Row justify="center">
                                    <Col xl={7} lg={8} md={12}>
                                        <ErrorCard
                                            text="Unable to sign in"
                                            visible={isErrorCardVisible}
                                            className="login-error-card"
                                        />
                                    </Col>
                                </Row>
                                <Row justify="center">
                                    <Col xl={7} lg={8} md={12}>
                                        <Header level={5}>Email address</Header>
                                        <TextInput
                                            disabled={false}
                                            large={false}
                                            style={userNameStyle}
                                            onChange={(evt) => setUserName(evt.target.value)}
                                            has_error={hasEmailError ? 1 : 0}
                                            error_msg={emailErrorMsg}
                                        />
                                    </Col>
                                </Row>
                                <Row justify="center">
                                    <Col xl={7} lg={8} md={12}>
                                        <Header level={5} style={{ float: 'left' }}>
                                            Password
                                        </Header>
                                        <TextInput
                                            disabled={false}
                                            large={false}
                                            style={passwordStyle}
                                            type="password"
                                            onChange={(evt) => setPassword(evt.target.value)}
                                            has_error={isPasswordProvided ? 0 : 1}
                                            error_msg="Please, provide your password."
                                        />
                                    </Col>
                                </Row>
                                <Row justify="center">
                                    <Col xl={7} lg={8} md={12}>
                                        <i>
                                            <Link
                                                tabIndex={-1}
                                                to={'/forgottenPassword'}
                                                className="dialogLabel min"
                                                style={{ color: 'blue', float: 'right', marginBottom: '5px' }}
                                            >
                                                Forgot password?
                                            </Link>
                                        </i>
                                        <Button
                                            text="Sign in"
                                            fill={true}
                                            onClick={handleSignIn}
                                            is_loading={isLoading}
                                            className="sign-in-btn"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={4} lg={4} md={4}></Col>
                        </Row>
                    </Container>
                </div>
            )}
        ></ScreenClassRender>
    );
};

export default withRouter(Login);
