/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updatedEntity = /* GraphQL */ `
  subscription UpdatedEntity($id: ID!) {
    updatedEntity(id: $id) {
      dataType
      device {
        id
        serialNumber
        status
      }
      id
      isActive
      lastValue
      measure
      rowHistory {
        createdAt
        updatedAt
      }
      unit
    }
  }
`;
