import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import App from './App';
import config from './common/config/amplify_config';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import 'mapbox-gl/dist/mapbox-gl.css';

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    },
    API: {
        aws_appsync_graphqlEndpoint: config.appsync.ENDPOINT,
        aws_appsync_region: config.appsync.REGION,
        aws_appsync_authenticationType: config.appsync.AUTHENTICATION_TYPE,
        aws_appsync_apiKey: config.appsync.APIKEY,
    },
});

ReactDOM.render(<App />, document.getElementById('root'));
