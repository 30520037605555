import React from 'react'
import { Card } from '@blueprintjs/core';



const PanelCard = (props) => {
  return (
    <div style={{position: 'relative'}}>
        <Card style={{position: 'absolute', top: 0, left: 0, width: '100%', height: 'calc(100vh - 70px - 60px)'}} >
            {props.children}
        </Card>
    </div>
  )
}

export default PanelCard