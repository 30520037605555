import React, { useState, useContext, useEffect, useRef } from 'react';
import UserSettingsContext from '../../context/userSettingsContext';




const Poster = ({ isDarkTheme }) => {
    //STATE
    const { userSettings } = useContext(UserSettingsContext);
    const { activePoster } = userSettings;
    const [message, setMessage] = useState('');



    //CHECK IF ACTIVE POSTER
    useEffect(() => {
        if (!activePoster) setMessage('No active poster');
        else setMessage('');
    }, [activePoster]);



    //POSTER SIZE
    const [posterSize, setPosterSize] = useState({width: 0, height: 0});
    const { width, height } = posterSize;
    const poster = useRef();

    useEffect(() => {
        if (activePoster) {
            poster.current = document.getElementById('displayed-poster');
            if (poster.current === null) { //js needs a split second before getting the img.id from dom. Let it try again if it doesn't get the img the first time
                setTimeout(() => {
                    poster.current = document.getElementById('displayed-poster');
                    if (!poster) setPosterSize({width: 0, height: 0});
                    else {
                        let img = new Image();
                        img.src = activePoster.imageUrl
                        img.onload = function() {
                            setPosterSize({width: this.width, height: this.height});
                        }
                    }
                }, 100)} else {
                    let img = new Image();
                    img.src = activePoster.imageUrl
                    img.onload = function() {
                        setPosterSize({width: this.width, height: this.height});
                    }
                }
        } else {
            poster.current = null;
            setPosterSize({width: 0, height: 0});
        }
    }, [activePoster]);



    //RENDER
    return (
            <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: height > width ? 'flex-start' : 'center', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 'calc(100% - 30px)', height: 'calc(100% - 190px)', overflow: 'auto'}}>
                {message && <h2 style={{color: 'white', textShadow: '2px 2px 5px #333', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>{message}</h2>}
                {!message && activePoster && <img id="displayed-poster" src={activePoster.imageUrl} alt="Poster Deleted" width={"100%"} />}
            </div>
    )
}

export default Poster