import { initStore } from './useStore';

const configureStore = () => {
    const actions = {
        FETCH_GEOJSON: async function (_, layerId) {
            const fetchGeoJson = async function (id) {
                switch (id) {
                    case 'restaurants':
                        const restaurantsResponse = await fetch(
                            'https://api.foursquare.com/v3/places/search?limit=50&categories=13065&ll=48.165709,17.177926&radius=1000',
                            {
                                headers: {
                                    Accept: 'application/json',
                                    Authorization: 'fsq333oTJYg//ip1yO+Sm0kQ8MzY++cX8eF4uHlNeWvPEc8=',
                                },
                            },
                        );

                        if (!restaurantsResponse.ok) return console.log('error');
                        const restaurantsData = await restaurantsResponse.json();

                        const restaurantsFeaturesArr = restaurantsData.results.map((restaurant) => {
                            return {
                                geometry: {
                                    coordinates: [
                                        restaurant.geocodes.main.longitude,
                                        restaurant.geocodes.main.latitude,
                                    ],
                                    type: 'Point',
                                },
                                properties: {
                                    id: restaurant['fsq_id'],
                                    link: restaurant.link,
                                    address: restaurant.location.address,
                                    country: restaurant.location.country,
                                    postCode: restaurant.location.postcode,
                                    region: restaurant.location.region,
                                    name: restaurant.name,
                                },
                                type: 'Feature',
                            };
                        });

                        const restaurantsGeoJSON = {
                            features: restaurantsFeaturesArr,
                            meta: {},
                            type: 'FeatureCollection',
                        };

                        return restaurantsGeoJSON;
                    case 'bus':
                        const busResponse = await fetch(
                            'https://transit.land/api/v2/rest/stops?lat=48.16553&lon=17.17791&apikey=imBBSqKPA8WUqbU8z9I6B82OMhP0Bb0w&format=geojson&radius=500',
                        );
                        if (!busResponse.ok) return console.log('error');
                        const busData = await busResponse.json();

                        return busData;
                    case 'scooters':
                        const query = `
                    query ($lat: Float!, $lng: Float!) {
                      vehicles (lat: $lat, lng: $lng) {
                        id
                        type
                        lat
                        attributes
                        battery
                        pricing {
                          unlock
                          currency
                          perMin {
                              interval
                              price
                          }
                          includeVat
                          vat
                        }
                        lng
                        provider {
                          name
                          website
                          app {
                              android
                              ios
                          }
                        }
                        publicId
                      }
                    }
                  `;
                        const response = await fetch(
                            'https://flow-api.fluctuo.com/v1?access_token=S9Q1jTw9htrMTJeT2vfA2HgGurrj1w8s',
                            {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Accept: 'application/json',
                                },
                                body: JSON.stringify({
                                    query,
                                    variables: {
                                        lat: 48.16553,
                                        lng: 17.17791,
                                    },
                                }),
                            },
                        );

                        if (!response.ok) console.error('Wrong fetch');

                        const scootersData = await response.json();
                        // Data returned from this Api Call is not geoJSON so we need to convert it.
                        // 1.st step is to create featuresArr
                        const scootersFeaturesArr = scootersData.data.vehicles.map((vehicle) => {
                            return {
                                geometry: {
                                    coordinates: [vehicle.lng, vehicle.lat],
                                    type: 'Point',
                                },
                                properties: {
                                    id: vehicle.id,
                                    type: vehicle.type,
                                    provider: vehicle.provider.name,
                                    currency: vehicle.pricing.currency,
                                    unlock: vehicle.pricing.unlock,
                                    perMin: vehicle.pricing.perMin[0].price,
                                    battery: vehicle.battery,
                                    publicId: vehicle.publicId,
                                    website: vehicle.provider.website,
                                    android: vehicle.provider.app.android,
                                    ios: vehicle.provider.app.ios,
                                },
                                type: 'Feature',
                            };
                        });

                        // 2. step is to add scootersFeaturesArr into features and you have geoJson ready
                        const scootersGeoJSON = {
                            features: scootersFeaturesArr,
                            meta: {},
                            type: 'FeatureCollection',
                        };

                        return scootersGeoJSON;
                    default:
                        console.log('Something went wrong in switch statement');
                }
            };

            const newGeoJson = await fetchGeoJson(layerId);
            newGeoJson.toggled = true;
            console.log('FETCH_GEOJSON', layerId.toUpperCase());

            return { [`${layerId}GeoJson`]: newGeoJson };
        },
        TOGGLE_GEOJSON: function (curState, layerId) {
            const updatedGeoJson = { ...curState[`${layerId}GeoJson`] };

            updatedGeoJson.toggled = !updatedGeoJson.toggled;
            console.log(`TOGGLE_GEOJSON`, layerId.toUpperCase());
            return { [`${layerId}GeoJson`]: updatedGeoJson };
        },
    };

    initStore(actions, {
        restaurantsGeoJson: null,
        scootersGeoJson: null,
        busGeoJson: null,
    });
};
export default configureStore;
