import PanelCard from './PanelCard'
import React from 'react'

const SensorsPanel = () => {
  return (
    <PanelCard>
        Sensors Panel
    </PanelCard>
  )
}

export default SensorsPanel