import React, { useState, useEffect, useRef, useContext } from 'react';
import UserSettingsContext from '../../../context/userSettingsContext';
import { Button, Icon } from '@blueprintjs/core';
import './ManageWidgetsScreen.scss';
import { useStore } from '../../store/useStore';

const ManageWidgetsScreen = ({ isDarkTheme }) => {
    // GLOBAL/LOCAL USER
    const isGlobalUser = useStore()[0].userSettings.isGlobal;

    //USER SETTINGS ENDPOINTS
    const userSettingsEndpoints = {
        getUserSettings: `https://25uhu3mgnf.execute-api.eu-west-1.amazonaws.com/dev/getusersettings`,
        setUserSettings: `https://25uhu3mgnf.execute-api.eu-west-1.amazonaws.com/dev/setusersettings`,
    };

    //MESSAGE
    const [message, setMessage] = useState('');

    const showMessage = (text) => {
        console.log(text);
        setMessage(text);
        setTimeout(() => {
            setMessage('');
        }, 2000);
    };

    //WIDGETS & FIELDS ( = widgets that can be added)
    const existingWidgets = {
        newsWidget: 'News Widget',
        noteWidget: 'Note Widget',
        performanceWidget: 'Performance Widget',
        sampleWidget1: 'Sample Widget 1',
        sampleWidget2: 'Sample Widget 2',
        sampleWidget3: 'Sample Widget 3',
    };

    const [freeFields, setFreeFields] = useState({ //fields marked 'FREE' on the grid
        row1: [1, 2, 3, 4, 5, 6],
        row2: [1, 2, 3, 4, 5, 6],
        row3: [1, 2, 3, 4, 5, 6],
    });

    const { userSettings, changeUserSettings } = useContext(UserSettingsContext);
    const { widgets } = userSettings; //[{name, positionX, positionY, sizeX, sizeY}]
    const [localWidgets, setLocalWidgets] = useState([]); //widgets position is initially loaded from ctx.userSettings.widgets. Then localWidgets are used (to avoid conflicts in case multiple people manipulate widgets on multiple machines)
    const [firstLoad, setFirstLoad] = useState(true);

    const changeUserSettingsWidgetsInDB = (newWidgets) => { //saves widgets position to DB
        isGlobalUser &&
            fetch(userSettingsEndpoints.setUserSettings, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ...userSettings, widgets: newWidgets }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (!data?.id) console.log('Updating widgets in User Settings failed');
                })
                .catch((error) => {
                    console.log('Updating widgets in User Settings failed');
                });
    };

    useEffect(() => {
        //remove free fields from area taken by widgets on first load
        if (firstLoad && widgets && widgets.length > 0) {
            let allFreeFields = { ...freeFields };
            widgets.forEach((widget) => {
                for (let y = widget.positionY; y < widget.positionY + widget.sizeY; y++) {
                    for (let x = widget.positionX; x < widget.positionX + widget.sizeX; x++) {
                        allFreeFields[`row${y}`] = allFreeFields[`row${y}`].filter((item) => item !== x);
                    }
                    allFreeFields[`row${y}`].sort();
                }
            });
            setFreeFields({ ...allFreeFields });
            setFirstLoad(false);
            setLocalWidgets(widgets); //populate localWidgets with saved ctx.userSettings.widgets
        }
    }, [widgets, firstLoad]);

    const saveWidgets = (newWidgetsState) => { //changes 1)ctx.userSettings, 2)DB userSettings, 3) localWidgets
        changeUserSettings({ widgets: newWidgetsState });
        changeUserSettingsWidgetsInDB(newWidgetsState);
        setLocalWidgets(newWidgetsState);
    };

    const isFieldFree = (x, y) => {
        if (x > 6 || y > 3) return showMessage('x must be <= 6, y must be <= 3');
        if (freeFields[`row${y}`].includes(x)) return true;
        else return false;
    };

    const getFirstFreeField = () => { //used so new widgets are not added randomly, but in left-to-right up-to-down manner
        let freeField = false;
        if (freeFields.row1.length > 0) freeField = { positionX: freeFields.row1[0], positionY: 1, sizeX: 1, sizeY: 1 };
        else if (freeFields.row2.length > 0)
            freeField = { positionX: freeFields.row2[0], positionY: 2, sizeX: 1, sizeY: 1 };
        else if (freeFields.row3.length > 0)
            freeField = { positionX: freeFields.row3[0], positionY: 3, sizeX: 1, sizeY: 1 };
        return freeField;
    };

    //CREATE & REMOVE WIDGET
    const nameIsUnique = (name) => {
        let nameExists = [...localWidgets].findIndex((item) => item.name === name);
        if (nameExists !== -1) {
            showMessage('Widget already added');
            return false;
        } else return true;
    };

    const createWidget = (name) => {
        if (!nameIsUnique(name)) return;
        const firstFreeField = getFirstFreeField();
        if (firstFreeField) {
            saveWidgets([...localWidgets, { name, ...firstFreeField }]);
            let remainingFreeFields = { ...freeFields };
            let row = firstFreeField.positionY;
            let remainingFieldsInRow = remainingFreeFields[`row${row}`].filter(
                (item) => item !== firstFreeField.positionX,
            );
            remainingFreeFields[`row${row}`] = remainingFieldsInRow;
            remainingFreeFields[`row${row}`].sort();
            setFreeFields({ ...remainingFreeFields });
        } else showMessage('No free fields left.');
    };

    const removeWidget = (widget) => {
        let allWidgets = [...localWidgets];
        let widgetIdx = allWidgets.findIndex((item) => item.name === widget.name);
        allWidgets.splice(widgetIdx, 1);
        saveWidgets(allWidgets);
        putFreeFieldsInWidgetPosition(widget);
    };

    //RESIZE WIDGET
    const resizeAndReturnWidget = (widget, newPositionX, newPositionY, newSizeX, newSizeY) => {
        //rewrite widget size & positions
        if (!widget) return showMessage('Widget not passed as a first argument to resizeWidget()');
        widget.positionX = newPositionX || widget.positionX;
        widget.positionY = newPositionY || widget.positionY;
        widget.sizeX = newSizeX || widget.sizeX;
        widget.sizeY = newSizeY || widget.sizeY;

        //save widget
        let newWidgets = [...localWidgets];
        let widgetIdx = newWidgets.findIndex((item) => item.name === widget.name);
        newWidgets.splice(widgetIdx, 1, widget);
        saveWidgets(newWidgets);
        return widget;
    };

    const expandRight = (name) => {
        //check if can be expanded
        let widget = [...localWidgets].find((item) => item.name === name);
        if (!widget) return showMessage('Widget with that name not found');
        if (widget.sizeX === 3) return showMessage('Widget cannot be wider than 3 fields');
        if (widget.positionX + widget.sizeX - 1 >= 6) return showMessage('Widget cannot expand beyond the grid');

        let canExpandRight = false;
        for (let i = widget.positionY; i < widget.positionY + widget.sizeY; i++) {
            if (isFieldFree(widget.positionX + widget.sizeX, i)) canExpandRight = true;
            else {
                canExpandRight = false;
                break;
            }
        }

        if (canExpandRight) {
            //expand widget to the right
            const resizedWidget = resizeAndReturnWidget(widget, null, null, widget.sizeX + 1, null);

            //remove free fields from fields widget now occupies
            let remainingFreeFields = { ...freeFields };
            for (let i = resizedWidget.positionY; i < resizedWidget.positionY + resizedWidget.sizeY; i++) {
                remainingFreeFields[`row${i}`] = remainingFreeFields[`row${i}`].filter(
                    (item) => item !== resizedWidget.positionX + resizedWidget.sizeX - 1,
                );
                remainingFreeFields[`row${i}`].sort();
            }
            setFreeFields({ ...remainingFreeFields });
        } else showMessage('Widget cannot expand right');
    };

    const shrinkRight = (name) => {
        //check if can shrink
        let widget = [...localWidgets].find((item) => item.name === name);
        if (!widget) return showMessage('Widget with that name not found');
        if (widget.sizeX === 1) return showMessage('Widget cannot be smaller than 1 field');

        //shrink on the right
        const resizedWidget = resizeAndReturnWidget(widget, null, null, widget.sizeX - 1, null);

        //add free fields to emptied area
        let remainingFreeFields = { ...freeFields };
        for (let i = resizedWidget.positionY; i < resizedWidget.positionY + resizedWidget.sizeY; i++) {
            remainingFreeFields[`row${i}`].push(resizedWidget.positionX + resizedWidget.sizeX);
            remainingFreeFields[`row${i}`].sort();
        }
        setFreeFields({ ...remainingFreeFields });
    };

    const expandLeft = (name) => {
        //check if can expand
        let widget = [...localWidgets].find((item) => item.name === name);
        if (!widget) return showMessage('Widget with that name not found');
        if (widget.positionX === 1) return showMessage('Widget cannot expand beyond the grid');
        if (widget.sizeX === 3) return showMessage('Widget cannot be larger than 3 fields');

        let canExpandLeft = false;
        for (let i = widget.positionY; i < widget.positionY + widget.sizeY; i++) {
            if (isFieldFree(widget.positionX - 1, i)) canExpandLeft = true;
            else {
                canExpandLeft = false;
                break;
            }
        }

        if (canExpandLeft) {
            //expand to the left
            const resizedWidget = resizeAndReturnWidget(widget, widget.positionX - 1, null, widget.sizeX + 1, null);

            //remove free fields from fields widget now occupies
            let remainingFreeFields = { ...freeFields };
            for (let i = resizedWidget.positionY; i < resizedWidget.positionY + resizedWidget.sizeY; i++) {
                remainingFreeFields[`row${i}`] = remainingFreeFields[`row${i}`].filter(
                    (item) => item !== resizedWidget.positionX,
                );
                remainingFreeFields[`row${i}`].sort();
            }
            setFreeFields({ ...remainingFreeFields });
        } else showMessage('Widget cannot expand left');
    };

    const shrinkLeft = (name) => {
        //check if can shrink
        let widget = [...localWidgets].find((item) => item.name === name);
        if (!widget) return showMessage('Widget with that name not found');
        if (widget.sizeX === 1) return showMessage('Widget cannot be smaller than 1 field');

        //shrink on the left
        const resizedWidget = resizeAndReturnWidget(widget, widget.positionX + 1, null, widget.sizeX - 1, null);

        //clear emptied fields
        let remainingFreeFields = { ...freeFields };
        for (let i = resizedWidget.positionY; i < resizedWidget.positionY + resizedWidget.sizeY; i++) {
            remainingFreeFields[`row${i}`].push(resizedWidget.positionX - 1);
            remainingFreeFields[`row${i}`].sort();
        }
        setFreeFields({ ...remainingFreeFields });
    };

    const expandDown = (name) => {
        //check if can expand
        let widget = [...localWidgets].find((item) => item.name === name);
        if (!widget) return showMessage('Widget with that name not found');
        if (widget.sizeY === 3) return showMessage('Widget cannot expand beyound the grid');
        if (widget.positionY === 3) return showMessage('Widget cannot expand beyound the grid');
        if (widget.positionY - 1 + widget.sizeY > 3) return showMessage('Widget cannot expand beyound the grid');

        let canExpandDown = false;
        for (let i = widget.positionX; i < widget.positionX + widget.sizeX; i++) {
            if (isFieldFree(i, widget.positionY + widget.sizeY)) canExpandDown = true;
            else {
                canExpandDown = false;
                break;
            }
        }

        if (canExpandDown) {
            //resize widget
            const resizedWidget = resizeAndReturnWidget(widget, null, null, null, widget.sizeY + 1);

            //remove free fields from fields widget now occupies
            let remainingFreeFields = { ...freeFields };
            for (let i = resizedWidget.positionX; i < resizedWidget.positionX + resizedWidget.sizeX; i++) {
                remainingFreeFields[`row${resizedWidget.positionY - 1 + resizedWidget.sizeY}`] = remainingFreeFields[
                    `row${resizedWidget.positionY - 1 + resizedWidget.sizeY}`
                ].filter((item) => item !== i);
            }
            remainingFreeFields[`row${resizedWidget.positionY + 1}`].sort();
            setFreeFields({ ...remainingFreeFields });
        } else showMessage('Cannot expand down');
    };

    const shrinkDown = (name) => {
        //check if can shrink
        let widget = [...localWidgets].find((item) => item.name === name);
        if (!widget) return showMessage('Widget with that name not found');
        if (widget.sizeY === 1) return showMessage('Widget cannot be smaller than 1 field');

        //shrink at the bottom
        const resizedWidget = resizeAndReturnWidget(widget, null, null, null, widget.sizeY - 1);

        //clear emptied fields
        let remainingFreeFields = { ...freeFields };
        for (let i = resizedWidget.positionX; i < resizedWidget.positionX + resizedWidget.sizeX; i++) {
            remainingFreeFields[`row${resizedWidget.positionY + resizedWidget.sizeY}`].push(i);
            remainingFreeFields[`row${resizedWidget.positionY + resizedWidget.sizeY}`].sort();
        }
        setFreeFields({ ...remainingFreeFields });
    };

    const expandUp = (name) => {
        //check if can expand
        let widget = [...localWidgets].find((item) => item.name === name);
        if (!widget) return showMessage('Widget with that name not found');
        if (widget.positionY === 1) return showMessage('Widget cannot expand beyond the grid');
        if (widget.sizeY === 3) return showMessage('Widget cannot be larger than 3 fields');

        let canExpandUp = false;
        for (let i = widget.positionX; i < widget.positionX + widget.sizeX; i++) {
            if (isFieldFree(i, widget.positionY - 1)) canExpandUp = true;
            else {
                canExpandUp = false;
                break;
            }
        }

        if (canExpandUp) {
            //resize widget
            const resizedWidget = resizeAndReturnWidget(widget, null, widget.positionY - 1, null, widget.sizeY + 1);

            //remove free fields from fields widget now occupies
            let remainingFreeFields = { ...freeFields };
            for (let i = resizedWidget.positionX; i < resizedWidget.positionX + resizedWidget.sizeX; i++) {
                remainingFreeFields[`row${resizedWidget.positionY}`] = remainingFreeFields[
                    `row${resizedWidget.positionY}`
                ].filter((item) => item !== i);
            }
            remainingFreeFields[`row${resizedWidget.positionY}`].sort();
            setFreeFields({ ...remainingFreeFields });
        } else showMessage('Cannot expand up');
    };

    const shrinkUp = (name) => {
        //check if can shrink
        let widget = [...localWidgets].find((item) => item.name === name);
        if (!widget) return showMessage('Widget with that name not found');
        if (widget.sizeY === 1) return showMessage('Widget cannot be smaller than one field');

        //shrink at the top
        const resizedWidget = resizeAndReturnWidget(widget, null, widget.positionY + 1, null, widget.sizeY - 1);

        //clear emptied
        let remainingFreeFields = { ...freeFields };
        for (let i = resizedWidget.positionX; i < resizedWidget.positionX + resizedWidget.sizeX; i++) {
            remainingFreeFields[`row${resizedWidget.positionY - 1}`].push(i);
            remainingFreeFields[`row${resizedWidget.positionY - 1}`].sort();
        }
        setFreeFields({ ...remainingFreeFields });
    };

    //WIDGET DRAG AND DROP
    const [isDown, setIsDown] = useState(false);
    const [movedWidget, setMovedWidget] = useState(null);
    const gridPreview = useRef();
    const borderRef = useRef();

    const createBorder = (widgetEl) => {
        const rect = widgetEl.getBoundingClientRect();
        let border = document.createElement('div');
        border.style.width = `${rect.width}px`;
        border.style.height = `${rect.height}px`;
        border.style.border = '3px black solid';
        border.style.position = 'absolute';
        border.style.top = `${rect.top - gridPreview.current.getBoundingClientRect().top}px`;
        border.style.left = `${rect.left - gridPreview.current.getBoundingClientRect().left}px`;
        border.style.pointerEvents = 'none';
        borderRef.current = border;
        gridPreview.current.appendChild(border);
    };

    const putFreeFieldsInWidgetPosition = (widget) => {
        let remainingFreeFields = { ...freeFields };
        for (let y = widget.positionY; y < widget.positionY + widget.sizeY; y++) {
            for (let x = widget.positionX; x < widget.positionX + widget.sizeX; x++) {
                remainingFreeFields[`row${y}`].push(x);
            }
            remainingFreeFields[`row${y}`].sort();
        }
        setFreeFields({ ...remainingFreeFields });
    };

    const removeFreeFieldsInWidgetPosition = (widget) => {
        let remainingFreeFields = { ...freeFields };
        for (let y = widget.positionY; y < widget.positionY + widget.sizeY; y++) {
            for (let x = widget.positionX; x < widget.positionX + widget.sizeX; x++) {
                remainingFreeFields[`row${y}`] = remainingFreeFields[`row${y}`].filter((item) => item !== x);
            }
            remainingFreeFields[`row${y}`].sort();
        }
        setFreeFields({ ...remainingFreeFields });
    };

    const isRemainingFieldFree = (x, y, remainingFreeFields) => {
        if (x > 6 || y > 3) return showMessage('x must be <= 6, y must be <= 3');
        if (remainingFreeFields[`row${y}`].includes(x)) return true;
        else return false;
    };

    const checkIfWidgetFits = (newPositionX, newPositionY, remainingfreeFields) => {
        let widgetFits = false;
        for (let x = newPositionX; x < newPositionX + movedWidget.sizeX; x++) {
            for (let y = newPositionY; y < newPositionY + movedWidget.sizeY; y++) {
                if (isRemainingFieldFree(x, y, remainingfreeFields)) {
                    widgetFits = true;
                } else {
                    widgetFits = false;
                    break;
                }
            }
            if (!widgetFits) break;
        }
        return widgetFits;
    };

    const handleMouseDown = (e) => {
        setIsDown(true); //enable dragging
        const widgetName = e.target.parentElement.getAttribute('name'); //get clicket widget
        const clickedWidget = [...localWidgets].find((item) => item.name === widgetName);
        e.target.parentElement.style.pointerEvents = 'none'; //disable clicking on dragged widget
        setMovedWidget({ ...clickedWidget }); //prepare widget for dragging...
        createBorder(e.target.parentElement);
        putFreeFieldsInWidgetPosition(clickedWidget);
    };

    const handleMouseUp = (e) => {
        if (!isDown) return;

        //if dropped on grid gap or dragger
        if (e.target.className === 'grid-preview' || e.target.name === 'widget-dragger') {
            if (movedWidget) removeFreeFieldsInWidgetPosition(movedWidget);
        }

        //if dropped on a free field
        if (movedWidget && e.target.className === 'free-field') {
            let newPositionX = parseInt(e.target.style.gridColumn.split('/')[0].trim()); //get coords of a new position (from css)
            let newPositionY = parseInt(e.target.style.gridRow);
            document.querySelector(`[name="${movedWidget.name}"]`).style.pointerEvents = 'auto'; //re-enable clicking on widget

            let remainingFreeFields = { ...freeFields }; //check if widget fits
            let widgetFits = checkIfWidgetFits(newPositionX, newPositionY, remainingFreeFields);
            if (widgetFits) {
                const newWidget = resizeAndReturnWidget({ ...movedWidget }, newPositionX, newPositionY, null, null); //move widget to new position
                putFreeFieldsInWidgetPosition(movedWidget);
                removeFreeFieldsInWidgetPosition(newWidget);
            } else {
                showMessage(`Not enough free fields`);
                removeFreeFieldsInWidgetPosition(movedWidget);
                document.querySelector(`[name="${movedWidget.name}"]`).style.pointerEvents = 'auto'; //re-enable click on widget
            }
        }

        //if dropped on another widget
        if (movedWidget && e.target.className === 'widget') removeFreeFieldsInWidgetPosition(movedWidget);

        //clean up
        if (movedWidget) document.querySelector(`[name="${movedWidget.name}"]`).style.pointerEvents = 'auto';
        setIsDown(false);
        if (borderRef.current) borderRef.current.remove();
        borderRef.current = null;
        setMovedWidget(null);
    };

    const handleMouseMove = (e) => {
        if (!isDown) return;
        const rect = gridPreview.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        borderRef.current.style.left = `${x - 10}px`; //move border along with mouse...
        borderRef.current.style.top = `${y - 10}px`;
    };

    const handleMouseLeave = (e) => {
        if (movedWidget) {
            //put widget back where it was
            let allWidgets = [...localWidgets];
            let widgetIdx = allWidgets.findIndex((item) => item.name === movedWidget.name);
            allWidgets.splice(widgetIdx, 1, { ...movedWidget });
            saveWidgets(allWidgets);
            removeFreeFieldsInWidgetPosition(movedWidget);
        }

        //cleanup
        if (movedWidget) document.querySelector(`[name="${movedWidget.name}"]`).style.pointerEvents = 'auto'; //re-enable clicking on widget
        setIsDown(false);
        if (borderRef.current) borderRef.current.remove();
        borderRef.current = null;
        setMovedWidget(null);
        document.querySelector('.manage-widgets-title')?.click(); //in case unwanted mouse selection happened
    };

    //RENDER
    return (
        <div className="manage-widget-screen-wrapper">
            <h2 className="manage-widgets-title">WIDGETS</h2> <br />
            {localWidgets && (
                <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', marginBottom: '1rem' }}>
                    {Object.keys(existingWidgets).map((key) => (
                        <Button
                            key={key}
                            style={{ userSelect: 'none', marginBottom: '0.25rem', width: '260px', margin: '5px' }}
                            text={`Add ${existingWidgets[key]}`}
                            onClick={() => createWidget(`${existingWidgets[key]}`)}
                        />
                    ))}
                </div>
            )}
            {message ? (
                <p style={{ margin: '0.5rem 0', color: '#db3737', userSelect: 'none', pointerEvents: 'none' }}>
                    {message}
                </p>
            ) : (
                <p style={{ margin: '0.5rem 0', userSelect: 'none', pointerEvents: 'none' }}>
                    {'Add and adjust widgets'}
                </p>
            )}
            <div
                className="grid-preview"
                ref={gridPreview}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
                style={{ background: isDarkTheme ? '#666' : '#eee' }}
            >
                {localWidgets &&
                    localWidgets.length > 0 &&
                    localWidgets.map((w) => (
                        <div
                            key={w.name}
                            name={w.name}
                            className="widget"
                            style={{
                                background: '#7a53a4',
                                opacity: '0.7',
                                gridColumn: `${w.positionX} / span ${w.sizeX}`,
                                gridRow: `${w.positionY} / span ${w.sizeY}`,
                                position: 'relative',
                            }}
                        >
                            <p className="widget-name">{w.name}</p>

                            <div
                                className="widget-dragger"
                                style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                                onMouseDown={handleMouseDown}
                                onMouseUp={handleMouseUp}
                                onMouseMove={handleMouseMove}
                            >
                                <Icon style={{ pointerEvents: 'none' }} icon="hand" />
                            </div>

                            <div
                                className="remove-widget-btn"
                                style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                                onClick={() => removeWidget(w)}
                            >
                                <Icon style={{ pointerEvents: 'none' }} icon="cross" />
                            </div>

                            <div
                                className="resize-button-group resize-up-buttons"
                                style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                            >
                                <Icon icon="caret-up" onClick={() => expandUp(w.name)} />{' '}
                                <br style={{ userSelect: 'none' }} />
                                <Icon icon="caret-down" onClick={() => shrinkUp(w.name)} />
                            </div>

                            <div
                                className="resize-button-group resize-down-buttons"
                                style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                            >
                                <Icon
                                    style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                                    icon="caret-up"
                                    onClick={() => shrinkDown(w.name)}
                                />{' '}
                                <br style={{ userSelect: 'none' }} />
                                <Icon
                                    style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                                    icon="caret-down"
                                    onClick={() => expandDown(w.name)}
                                />
                            </div>

                            <div
                                className="resize-button-group resize-left-buttons"
                                style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                            >
                                <Icon
                                    style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                                    icon="caret-left"
                                    onClick={() => expandLeft(w.name)}
                                />
                                <Icon
                                    style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                                    icon="caret-right"
                                    onClick={() => shrinkLeft(w.name)}
                                />
                            </div>

                            <div
                                className="resize-button-group resize-right-buttons"
                                style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                            >
                                <Icon
                                    style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                                    icon="caret-left"
                                    onClick={() => shrinkRight(w.name)}
                                />
                                <Icon
                                    style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                                    icon="caret-right"
                                    onClick={() => expandRight(w.name)}
                                />
                            </div>
                        </div>
                    ))}

                {freeFields.row1.map((n, i) => (
                    <div
                        className="free-field"
                        key={Math.random()}
                        style={{
                            background: isDarkTheme ? '#333' : '#bbb',
                            gridColumn: `${n} / span 1`,
                            gridRow: `1 / span 1`,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {' '}
                        <span style={{ pointerEvents: 'none', userSelect: 'none' }}>FREE</span>{' '}
                    </div>
                ))}

                {freeFields.row2.map((n, i) => (
                    <div
                        className="free-field"
                        key={Math.random()}
                        style={{
                            background: isDarkTheme ? '#333' : '#bbb',
                            gridColumn: `${n} / span 1`,
                            gridRow: `2 / span 1`,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {' '}
                        <span style={{ pointerEvents: 'none', userSelect: 'none' }}>FREE</span>{' '}
                    </div>
                ))}

                {freeFields.row3.map((n, i) => (
                    <div
                        className="free-field"
                        key={Math.random()}
                        style={{
                            background: isDarkTheme ? '#333' : '#bbb',
                            gridColumn: `${n} / span 1`,
                            gridRow: `3 / span 1`,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {' '}
                        <span style={{ pointerEvents: 'none', userSelect: 'none' }}>FREE</span>{' '}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ManageWidgetsScreen;





/* THIS IS A BACKUP => when ManageWidgetsScreen.js manipulated widgets thru context directly (working well, but risky in case multiple users move widgets on multiple machiones at the same time)

import React, { useState, useEffect, useRef, useContext } from 'react';
import UserSettingsContext from '../../../context/userSettingsContext';
import { Button, Icon } from '@blueprintjs/core';
import './ManageWidgetsScreen.scss';
import { useStore } from '../../store/useStore';

const ManageWidgetsScreen = ({ isDarkTheme }) => {
    // GLOBAL/LOCAL USER
    const isGlobalUser = useStore()[0].userSettings.isGlobal;

    //USER SETTINGS ENDPOINTS
    const userSettingsEndpoints = {
        getUserSettings: `https://25uhu3mgnf.execute-api.eu-west-1.amazonaws.com/dev/getusersettings`,
        setUserSettings: `https://25uhu3mgnf.execute-api.eu-west-1.amazonaws.com/dev/setusersettings`,
    };

    //MESSAGE
    const [message, setMessage] = useState('');

    const showMessage = (text) => {
        console.log(text); ////
        setMessage(text);
        setTimeout(() => {
            setMessage('');
        }, 2000);
    };

    //WIDGETS & FIELDS
    const existingWidgets = {
        newsWidget: 'News Widget',
        noteWidget: 'Note Widget',
        performanceWidget: 'Performance Widget',
        sampleWidget1: 'Sample Widget 1',
        sampleWidget2: 'Sample Widget 2',
        sampleWidget3: 'Sample Widget 3',
    };

    const [freeFields, setFreeFields] = useState({
        row1: [1, 2, 3, 4, 5, 6],
        row2: [1, 2, 3, 4, 5, 6],
        row3: [1, 2, 3, 4, 5, 6],
    });

    const { userSettings, changeUserSettings } = useContext(UserSettingsContext);
    const { widgets } = userSettings; //[{name, positionX, positionY, sizeX, sizeY}]
    const [firstLoad, setFirstLoad] = useState(true);

    const changeUserSettingsWidgetsInDB = (newWidgets) => {
        isGlobalUser &&
            fetch(userSettingsEndpoints.setUserSettings, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ...userSettings, widgets: newWidgets }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (!data?.id) console.log('Updating widgets in User Settings failed');
                })
                .catch((error) => {
                    console.log('Updating widgets in User Settings failed');
                });
    };

    useEffect(() => {
        //remove free fields from area taken by widgets on first load
        if (firstLoad && widgets && widgets.length > 0) {
            let allFreeFields = { ...freeFields };
            widgets.forEach((widget) => {
                for (let y = widget.positionY; y < widget.positionY + widget.sizeY; y++) {
                    for (let x = widget.positionX; x < widget.positionX + widget.sizeX; x++) {
                        allFreeFields[`row${y}`] = allFreeFields[`row${y}`].filter((item) => item !== x);
                    }
                    allFreeFields[`row${y}`].sort();
                }
            });
            setFreeFields({ ...allFreeFields });
            setFirstLoad(false);
        }
    }, [widgets, firstLoad]);

    const saveWidgets = (newWidgetsState) => {
        changeUserSettings({ widgets: newWidgetsState });
        //localStorage.setItem('scootWidgets', JSON.stringify(newWidgetsState));
        changeUserSettingsWidgetsInDB(newWidgetsState);
    };

    const isFieldFree = (x, y) => {
        if (x > 6 || y > 3) return showMessage('x must be <= 6, y must be <= 3');
        if (freeFields[`row${y}`].includes(x)) return true;
        else return false;
    };

    const getFirstFreeField = () => {
        let freeField = false;
        if (freeFields.row1.length > 0) freeField = { positionX: freeFields.row1[0], positionY: 1, sizeX: 1, sizeY: 1 };
        else if (freeFields.row2.length > 0)
            freeField = { positionX: freeFields.row2[0], positionY: 2, sizeX: 1, sizeY: 1 };
        else if (freeFields.row3.length > 0)
            freeField = { positionX: freeFields.row3[0], positionY: 3, sizeX: 1, sizeY: 1 };
        return freeField;
    };

    //CREATE & REMOVE WIDGET
    const nameIsUnique = (name) => {
        let nameExists = [...widgets].findIndex((item) => item.name === name);
        if (nameExists !== -1) {
            showMessage('Widget already added');
            return false;
        } else return true;
    };

    const createWidget = (name) => {
        if (!nameIsUnique(name)) return;
        const firstFreeField = getFirstFreeField();
        if (firstFreeField) {
            saveWidgets([...widgets, { name, ...firstFreeField }]);
            let remainingFreeFields = { ...freeFields };
            let row = firstFreeField.positionY;
            let remainingFieldsInRow = remainingFreeFields[`row${row}`].filter(
                (item) => item !== firstFreeField.positionX,
            );
            remainingFreeFields[`row${row}`] = remainingFieldsInRow;
            remainingFreeFields[`row${row}`].sort();
            setFreeFields({ ...remainingFreeFields });
        } else showMessage('No free fields left.');
    };

    const removeWidget = (widget) => {
        let allWidgets = [...widgets];
        let widgetIdx = allWidgets.findIndex((item) => item.name === widget.name);
        allWidgets.splice(widgetIdx, 1);
        saveWidgets(allWidgets);
        putFreeFieldsInWidgetPosition(widget);
    };

    //RESIZE WIDGET
    const resizeAndReturnWidget = (widget, newPositionX, newPositionY, newSizeX, newSizeY) => {
        //rewrite widget size & positions
        if (!widget) return showMessage('Widget not passed as a first argument to resizeWidget()');
        widget.positionX = newPositionX || widget.positionX;
        widget.positionY = newPositionY || widget.positionY;
        widget.sizeX = newSizeX || widget.sizeX;
        widget.sizeY = newSizeY || widget.sizeY;

        //save widget
        let newWidgets = [...widgets];
        let widgetIdx = newWidgets.findIndex((item) => item.name === widget.name);
        newWidgets.splice(widgetIdx, 1, widget);
        saveWidgets(newWidgets);
        return widget;
    };

    const expandRight = (name) => {
        //check if can be expanded
        let widget = [...widgets].find((item) => item.name === name);
        if (!widget) return showMessage('Widget with that name not found');
        if (widget.sizeX === 3) return showMessage('Widget cannot be wider than 3 fields');
        if (widget.positionX + widget.sizeX - 1 >= 6) return showMessage('Widget cannot expand beyond the grid');

        let canExpandRight = false;
        for (let i = widget.positionY; i < widget.positionY + widget.sizeY; i++) {
            if (isFieldFree(widget.positionX + widget.sizeX, i)) canExpandRight = true;
            else {
                canExpandRight = false;
                break;
            }
        }

        if (canExpandRight) {
            //expand widget to the right
            const resizedWidget = resizeAndReturnWidget(widget, null, null, widget.sizeX + 1, null);

            //remove free fields from fields widget now occupies
            let remainingFreeFields = { ...freeFields };
            for (let i = resizedWidget.positionY; i < resizedWidget.positionY + resizedWidget.sizeY; i++) {
                remainingFreeFields[`row${i}`] = remainingFreeFields[`row${i}`].filter(
                    (item) => item !== resizedWidget.positionX + resizedWidget.sizeX - 1,
                );
                remainingFreeFields[`row${i}`].sort();
            }
            setFreeFields({ ...remainingFreeFields });
        } else showMessage('Widget cannot expand right');
    };

    const shrinkRight = (name) => {
        //check if can shrink
        let widget = [...widgets].find((item) => item.name === name);
        if (!widget) return showMessage('Widget with that name not found');
        if (widget.sizeX === 1) return showMessage('Widget cannot be smaller than 1 field');

        //shrink on the right
        const resizedWidget = resizeAndReturnWidget(widget, null, null, widget.sizeX - 1, null);

        //add free fields to emptied area
        let remainingFreeFields = { ...freeFields };
        for (let i = resizedWidget.positionY; i < resizedWidget.positionY + resizedWidget.sizeY; i++) {
            remainingFreeFields[`row${i}`].push(resizedWidget.positionX + resizedWidget.sizeX);
            remainingFreeFields[`row${i}`].sort();
        }
        setFreeFields({ ...remainingFreeFields });
    };

    const expandLeft = (name) => {
        //check if can expand
        let widget = [...widgets].find((item) => item.name === name);
        if (!widget) return showMessage('Widget with that name not found');
        if (widget.positionX === 1) return showMessage('Widget cannot expand beyond the grid');
        if (widget.sizeX === 3) return showMessage('Widget cannot be larger than 3 fields');

        let canExpandLeft = false;
        for (let i = widget.positionY; i < widget.positionY + widget.sizeY; i++) {
            if (isFieldFree(widget.positionX - 1, i)) canExpandLeft = true;
            else {
                canExpandLeft = false;
                break;
            }
        }

        if (canExpandLeft) {
            //expand to the left
            const resizedWidget = resizeAndReturnWidget(widget, widget.positionX - 1, null, widget.sizeX + 1, null);

            //remove free fields from fields widget now occupies
            let remainingFreeFields = { ...freeFields };
            for (let i = resizedWidget.positionY; i < resizedWidget.positionY + resizedWidget.sizeY; i++) {
                remainingFreeFields[`row${i}`] = remainingFreeFields[`row${i}`].filter(
                    (item) => item !== resizedWidget.positionX,
                );
                remainingFreeFields[`row${i}`].sort();
            }
            setFreeFields({ ...remainingFreeFields });
        } else showMessage('Widget cannot expand left');
    };

    const shrinkLeft = (name) => {
        //check if can shrink
        let widget = [...widgets].find((item) => item.name === name);
        if (!widget) return showMessage('Widget with that name not found');
        if (widget.sizeX === 1) return showMessage('Widget cannot be smaller than 1 field');

        //shrink on the left
        const resizedWidget = resizeAndReturnWidget(widget, widget.positionX + 1, null, widget.sizeX - 1, null);

        //clear emptied fields
        let remainingFreeFields = { ...freeFields };
        for (let i = resizedWidget.positionY; i < resizedWidget.positionY + resizedWidget.sizeY; i++) {
            remainingFreeFields[`row${i}`].push(resizedWidget.positionX - 1);
            remainingFreeFields[`row${i}`].sort();
        }
        setFreeFields({ ...remainingFreeFields });
    };

    const expandDown = (name) => {
        //check if can expand
        let widget = [...widgets].find((item) => item.name === name);
        if (!widget) return showMessage('Widget with that name not found');
        if (widget.sizeY === 3) return showMessage('Widget cannot expand beyound the grid');
        if (widget.positionY === 3) return showMessage('Widget cannot expand beyound the grid');
        if (widget.positionY - 1 + widget.sizeY > 3) return showMessage('Widget cannot expand beyound the grid');

        let canExpandDown = false;
        for (let i = widget.positionX; i < widget.positionX + widget.sizeX; i++) {
            if (isFieldFree(i, widget.positionY + widget.sizeY)) canExpandDown = true;
            else {
                canExpandDown = false;
                break;
            }
        }

        if (canExpandDown) {
            //resize widget
            const resizedWidget = resizeAndReturnWidget(widget, null, null, null, widget.sizeY + 1);

            //remove free fields from fields widget now occupies
            let remainingFreeFields = { ...freeFields };
            for (let i = resizedWidget.positionX; i < resizedWidget.positionX + resizedWidget.sizeX; i++) {
                remainingFreeFields[`row${resizedWidget.positionY - 1 + resizedWidget.sizeY}`] = remainingFreeFields[
                    `row${resizedWidget.positionY - 1 + resizedWidget.sizeY}`
                ].filter((item) => item !== i);
            }
            remainingFreeFields[`row${resizedWidget.positionY + 1}`].sort();
            setFreeFields({ ...remainingFreeFields });
        } else showMessage('Cannot expand down');
    };

    const shrinkDown = (name) => {
        //check if can shrink
        let widget = [...widgets].find((item) => item.name === name);
        if (!widget) return showMessage('Widget with that name not found');
        if (widget.sizeY === 1) return showMessage('Widget cannot be smaller than 1 field');

        //shrink at the bottom
        const resizedWidget = resizeAndReturnWidget(widget, null, null, null, widget.sizeY - 1);

        //clear emptied fields
        let remainingFreeFields = { ...freeFields };
        for (let i = resizedWidget.positionX; i < resizedWidget.positionX + resizedWidget.sizeX; i++) {
            remainingFreeFields[`row${resizedWidget.positionY + resizedWidget.sizeY}`].push(i);
            remainingFreeFields[`row${resizedWidget.positionY + resizedWidget.sizeY}`].sort();
        }
        setFreeFields({ ...remainingFreeFields });
    };

    const expandUp = (name) => {
        //check if can expand
        let widget = [...widgets].find((item) => item.name === name);
        if (!widget) return showMessage('Widget with that name not found');
        if (widget.positionY === 1) return showMessage('Widget cannot expand beyond the grid');
        if (widget.sizeY === 3) return showMessage('Widget cannot be larger than 3 fields');

        let canExpandUp = false;
        for (let i = widget.positionX; i < widget.positionX + widget.sizeX; i++) {
            if (isFieldFree(i, widget.positionY - 1)) canExpandUp = true;
            else {
                canExpandUp = false;
                break;
            }
        }

        if (canExpandUp) {
            //resize widget
            const resizedWidget = resizeAndReturnWidget(widget, null, widget.positionY - 1, null, widget.sizeY + 1);

            //remove free fields from fields widget now occupies
            let remainingFreeFields = { ...freeFields };
            for (let i = resizedWidget.positionX; i < resizedWidget.positionX + resizedWidget.sizeX; i++) {
                remainingFreeFields[`row${resizedWidget.positionY}`] = remainingFreeFields[
                    `row${resizedWidget.positionY}`
                ].filter((item) => item !== i);
            }
            remainingFreeFields[`row${resizedWidget.positionY}`].sort();
            setFreeFields({ ...remainingFreeFields });
        } else showMessage('Cannot expand up');
    };

    const shrinkUp = (name) => {
        //check if can shrink
        let widget = [...widgets].find((item) => item.name === name);
        if (!widget) return showMessage('Widget with that name not found');
        if (widget.sizeY === 1) return showMessage('Widget cannot be smaller than one field');

        //shrink at the top
        const resizedWidget = resizeAndReturnWidget(widget, null, widget.positionY + 1, null, widget.sizeY - 1);

        //clear emptied
        let remainingFreeFields = { ...freeFields };
        for (let i = resizedWidget.positionX; i < resizedWidget.positionX + resizedWidget.sizeX; i++) {
            remainingFreeFields[`row${resizedWidget.positionY - 1}`].push(i);
            remainingFreeFields[`row${resizedWidget.positionY - 1}`].sort();
        }
        setFreeFields({ ...remainingFreeFields });
    };

    //WIDGET DRAG AND DROP
    const [isDown, setIsDown] = useState(false);
    const [movedWidget, setMovedWidget] = useState(null);
    const gridPreview = useRef();
    const borderRef = useRef();

    const createBorder = (widgetEl) => {
        const rect = widgetEl.getBoundingClientRect();
        let border = document.createElement('div');
        border.style.width = `${rect.width}px`;
        border.style.height = `${rect.height}px`;
        border.style.border = '3px black solid';
        border.style.position = 'absolute';
        border.style.top = `${rect.top - gridPreview.current.getBoundingClientRect().top}px`;
        border.style.left = `${rect.left - gridPreview.current.getBoundingClientRect().left}px`;
        border.style.pointerEvents = 'none';
        borderRef.current = border;
        gridPreview.current.appendChild(border);
    };

    const putFreeFieldsInWidgetPosition = (widget) => {
        let remainingFreeFields = { ...freeFields };
        for (let y = widget.positionY; y < widget.positionY + widget.sizeY; y++) {
            for (let x = widget.positionX; x < widget.positionX + widget.sizeX; x++) {
                remainingFreeFields[`row${y}`].push(x);
            }
            remainingFreeFields[`row${y}`].sort();
        }
        setFreeFields({ ...remainingFreeFields });
    };

    const removeFreeFieldsInWidgetPosition = (widget) => {
        let remainingFreeFields = { ...freeFields };
        for (let y = widget.positionY; y < widget.positionY + widget.sizeY; y++) {
            for (let x = widget.positionX; x < widget.positionX + widget.sizeX; x++) {
                remainingFreeFields[`row${y}`] = remainingFreeFields[`row${y}`].filter((item) => item !== x);
            }
            remainingFreeFields[`row${y}`].sort();
        }
        setFreeFields({ ...remainingFreeFields });
    };

    const isRemainingFieldFree = (x, y, remainingFreeFields) => {
        if (x > 6 || y > 3) return showMessage('x must be <= 6, y must be <= 3');
        if (remainingFreeFields[`row${y}`].includes(x)) return true;
        else return false;
    };

    const checkIfWidgetFits = (newPositionX, newPositionY, remainingfreeFields) => {
        let widgetFits = false;
        for (let x = newPositionX; x < newPositionX + movedWidget.sizeX; x++) {
            for (let y = newPositionY; y < newPositionY + movedWidget.sizeY; y++) {
                if (isRemainingFieldFree(x, y, remainingfreeFields)) {
                    widgetFits = true;
                } else {
                    widgetFits = false;
                    break;
                }
            }
            if (!widgetFits) break;
        }
        return widgetFits;
    };

    const handleMouseDown = (e) => {
        setIsDown(true); //enable dragging
        const widgetName = e.target.parentElement.getAttribute('name'); //get clicket widget
        const clickedWidget = [...widgets].find((item) => item.name === widgetName);
        e.target.parentElement.style.pointerEvents = 'none'; //disable clicking on dragged widget
        setMovedWidget({ ...clickedWidget }); //prepare widget for dragging...
        createBorder(e.target.parentElement);
        putFreeFieldsInWidgetPosition(clickedWidget);
    };

    const handleMouseUp = (e) => {
        if (!isDown) return;

        //if dropped on grid gap or dragger
        if (e.target.className === 'grid-preview' || e.target.name === 'widget-dragger') {
            if (movedWidget) removeFreeFieldsInWidgetPosition(movedWidget);
        }

        //if dropped on a free field
        if (movedWidget && e.target.className === 'free-field') {
            let newPositionX = parseInt(e.target.style.gridColumn.split('/')[0].trim()); //get coords of a new position (from css)
            let newPositionY = parseInt(e.target.style.gridRow);
            document.querySelector(`[name="${movedWidget.name}"]`).style.pointerEvents = 'auto'; //re-enable clicking on widget

            let remainingFreeFields = { ...freeFields }; //check if widget fits
            let widgetFits = checkIfWidgetFits(newPositionX, newPositionY, remainingFreeFields);
            if (widgetFits) {
                const newWidget = resizeAndReturnWidget({ ...movedWidget }, newPositionX, newPositionY, null, null); //move widget to new position
                putFreeFieldsInWidgetPosition(movedWidget);
                removeFreeFieldsInWidgetPosition(newWidget);
            } else {
                showMessage(`Not enough free fields`);
                removeFreeFieldsInWidgetPosition(movedWidget);
                document.querySelector(`[name="${movedWidget.name}"]`).style.pointerEvents = 'auto'; //re-enable click on widget
            }
        }

        //if dropped on another widget
        if (movedWidget && e.target.className === 'widget') removeFreeFieldsInWidgetPosition(movedWidget);

        //clean up
        if (movedWidget) document.querySelector(`[name="${movedWidget.name}"]`).style.pointerEvents = 'auto';
        setIsDown(false);
        if (borderRef.current) borderRef.current.remove();
        borderRef.current = null;
        setMovedWidget(null);
    };

    const handleMouseMove = (e) => {
        if (!isDown) return;
        const rect = gridPreview.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        borderRef.current.style.left = `${x - 10}px`; //move border along with mouse...
        borderRef.current.style.top = `${y - 10}px`;
    };

    const handleMouseLeave = (e) => {
        if (movedWidget) {
            //put widget back where it was
            let allWidgets = [...widgets];
            let widgetIdx = allWidgets.findIndex((item) => item.name === movedWidget.name);
            allWidgets.splice(widgetIdx, 1, { ...movedWidget });
            saveWidgets(allWidgets);
            removeFreeFieldsInWidgetPosition(movedWidget);
        }

        //cleanup
        if (movedWidget) document.querySelector(`[name="${movedWidget.name}"]`).style.pointerEvents = 'auto'; //re-enable clicking on widget
        setIsDown(false);
        if (borderRef.current) borderRef.current.remove();
        borderRef.current = null;
        setMovedWidget(null);
        document.querySelector('.manage-widgets-title')?.click(); //in case unwanted mouse selection happened
    };

    //RENDER
    return (
        <div className="manage-widget-screen-wrapper">
            <h2 className="manage-widgets-title">WIDGETS</h2> <br />
            {widgets && (
                <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', marginBottom: '1rem' }}>
                    {Object.keys(existingWidgets).map((key) => (
                        <Button
                            key={key}
                            style={{ userSelect: 'none', marginBottom: '0.25rem', width: '260px', margin: '5px' }}
                            text={`Add ${existingWidgets[key]}`}
                            onClick={() => createWidget(`${existingWidgets[key]}`)}
                        />
                    ))}
                </div>
            )}
            {message ? (
                <p style={{ margin: '0.5rem 0', color: '#db3737', userSelect: 'none', pointerEvents: 'none' }}>
                    {message}
                </p>
            ) : (
                <p style={{ margin: '0.5rem 0', userSelect: 'none', pointerEvents: 'none' }}>
                    {'Add and adjust widgets'}
                </p>
            )}
            <div
                className="grid-preview"
                ref={gridPreview}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
                style={{ background: isDarkTheme ? '#666' : '#eee' }}
            >
                {widgets &&
                    widgets.length > 0 &&
                    widgets.map((w) => (
                        <div
                            key={w.name}
                            name={w.name}
                            className="widget"
                            style={{
                                background: '#7a53a4',
                                opacity: '0.7',
                                gridColumn: `${w.positionX} / span ${w.sizeX}`,
                                gridRow: `${w.positionY} / span ${w.sizeY}`,
                                position: 'relative',
                            }}
                        >
                            <p className="widget-name">{w.name}</p>

                            <div
                                className="widget-dragger"
                                style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                                onMouseDown={handleMouseDown}
                                onMouseUp={handleMouseUp}
                                onMouseMove={handleMouseMove}
                            >
                                <Icon style={{ pointerEvents: 'none' }} icon="hand" />
                            </div>

                            <div
                                className="remove-widget-btn"
                                style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                                onClick={() => removeWidget(w)}
                            >
                                <Icon style={{ pointerEvents: 'none' }} icon="cross" />
                            </div>

                            <div
                                className="resize-button-group resize-up-buttons"
                                style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                            >
                                <Icon icon="caret-up" onClick={() => expandUp(w.name)} />{' '}
                                <br style={{ userSelect: 'none' }} />
                                <Icon icon="caret-down" onClick={() => shrinkUp(w.name)} />
                            </div>

                            <div
                                className="resize-button-group resize-down-buttons"
                                style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                            >
                                <Icon
                                    style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                                    icon="caret-up"
                                    onClick={() => shrinkDown(w.name)}
                                />{' '}
                                <br style={{ userSelect: 'none' }} />
                                <Icon
                                    style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                                    icon="caret-down"
                                    onClick={() => expandDown(w.name)}
                                />
                            </div>

                            <div
                                className="resize-button-group resize-left-buttons"
                                style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                            >
                                <Icon
                                    style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                                    icon="caret-left"
                                    onClick={() => expandLeft(w.name)}
                                />
                                <Icon
                                    style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                                    icon="caret-right"
                                    onClick={() => shrinkLeft(w.name)}
                                />
                            </div>

                            <div
                                className="resize-button-group resize-right-buttons"
                                style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                            >
                                <Icon
                                    style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                                    icon="caret-left"
                                    onClick={() => shrinkRight(w.name)}
                                />
                                <Icon
                                    style={{ pointerEvents: isDown ? 'none' : 'auto' }}
                                    icon="caret-right"
                                    onClick={() => expandRight(w.name)}
                                />
                            </div>
                        </div>
                    ))}

                {freeFields.row1.map((n, i) => (
                    <div
                        className="free-field"
                        key={Math.random()}
                        style={{
                            background: isDarkTheme ? '#333' : '#bbb',
                            gridColumn: `${n} / span 1`,
                            gridRow: `1 / span 1`,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {' '}
                        <span style={{ pointerEvents: 'none', userSelect: 'none' }}>FREE</span>{' '}
                    </div>
                ))}

                {freeFields.row2.map((n, i) => (
                    <div
                        className="free-field"
                        key={Math.random()}
                        style={{
                            background: isDarkTheme ? '#333' : '#bbb',
                            gridColumn: `${n} / span 1`,
                            gridRow: `2 / span 1`,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {' '}
                        <span style={{ pointerEvents: 'none', userSelect: 'none' }}>FREE</span>{' '}
                    </div>
                ))}

                {freeFields.row3.map((n, i) => (
                    <div
                        className="free-field"
                        key={Math.random()}
                        style={{
                            background: isDarkTheme ? '#333' : '#bbb',
                            gridColumn: `${n} / span 1`,
                            gridRow: `3 / span 1`,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {' '}
                        <span style={{ pointerEvents: 'none', userSelect: 'none' }}>FREE</span>{' '}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ManageWidgetsScreen;


*/
