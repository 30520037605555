import './design/fonts.css';
import './design/variables.scss';
import { isPlatform, getPlatforms } from '@ionic/react';

console.log('platform: ' + getPlatforms());
if (isPlatform('desktop')) {
    require('./web/indexWeb');
} else if (isPlatform('mobile')) {
    require('./web/indexWeb');
}
