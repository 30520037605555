import React, { useEffect, useState } from 'react';

function AlertButton({ navbarChange }) {
    const [active, setActive] = useState(0);

    const alertButtonHandler = (v) => {
        setActive(1);
        navbarChange(v.value);
    };

    //Temporary, used for automatically exit alert mode
    useEffect(() => {
        if (active === 1) {
            setTimeout(() => {
                setActive(0);
                navbarChange(0);
            }, 3000);
        }
        // eslint-disable-next-line
    }, [active]);

    return (
        <div>
            <button
                className="bp3-button bp3-intent-danger floor-navbar-button-danger"
                value={999}
                onClick={(e) => alertButtonHandler(e.target)}
            >
                <span className="floor-icon-alarm floor-pointer-none" />
                Report an EMERGENCY !
            </button>
        </div>
    );
}

export default AlertButton;
