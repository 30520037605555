import React, { useState, useEffect, useCallback, useRef } from 'react';
import { createRequestTransformer } from 'amazon-location-helpers';
import config from '../../config/amplify_config';

import ReactMapGL, { NavigationControl, Source, Layer, Popup } from 'react-map-gl';
import mapboxToken from '../../config/mapbox_token';

import { Spinner } from '@blueprintjs/core';

import useMapEventHandler from '../../hooks/UseMapEventHandler';
import { useStore } from '../../../floor-app/store/useStore';
import MapPopup from './MapPopup';

const Map = (props) => {
    const [cursor, setCursor] = useState('auto');
    const [transformRequest, setRequestTransformer] = useState();
    const [viewport, setViewport] = useState({});

    const mapRef = useRef();

    const { fetchData, popupContent, setPopupContent } = useMapEventHandler();

    const globalStore = useStore()[0];

    const busGeoJson = globalStore.busGeoJson;
    const scootersGeoJson = globalStore.scootersGeoJson;
    const restaurantsGeoJson = globalStore.restaurantsGeoJson;
    const busLayerStyle = globalStore.layerStyles.busLayerStyle;
    const scootersLayerStyle = globalStore.layerStyles.scootersLayerStyle;
    const restaurantsLayerStyle = globalStore.layerStyles.restaurantsLayerStyle;

    const onMouseEnterHandler = () => setCursor('pointer');
    const onMouseLeaveHandler = () => setCursor('auto');

    useEffect(() => {
        const makeRequestTransformer = async () => {
            try {
                const tr = await createRequestTransformer({
                    region: config.locationService.REGION,
                    identityPoolId: config.locationService.IDENTITY_POOL_ID,
                });
                setRequestTransformer(() => tr);
            } catch (error) {
                console.log(error);
            }
        };
        if (props.init_viewport != null) {
            setViewport({
                longitude: props.init_viewport.longitude,
                latitude: props.init_viewport.latitude,
                zoom: 17,
            });
            makeRequestTransformer();
        }
    }, [props.init_viewport]);

    const busLayer = useCallback(
        function () {
            return (
                <Source id="bus" type="geojson" data={busGeoJson}>
                    <Layer {...busLayerStyle} />
                </Source>
            );
        },
        [busGeoJson, busLayerStyle],
    );

    const scootersLayer = useCallback(
        function () {
            return (
                <Source id="scooters" type="geojson" data={scootersGeoJson}>
                    <Layer {...scootersLayerStyle} />
                </Source>
            );
        },
        [scootersGeoJson, scootersLayerStyle],
    );

    const restaurantsLayer = useCallback(
        function () {
            return (
                <Source id="restaurants" type="geojson" data={restaurantsGeoJson}>
                    <Layer {...restaurantsLayerStyle} />
                </Source>
            );
        },
        [restaurantsGeoJson, restaurantsLayerStyle],
    );

    const onClickHandler = function (e) {
        if (e.features[0]) {
            mapRef.current?.flyTo({ center: [...e.features[0]?.geometry.coordinates], duration: 1500 });
        }
        fetchData(e.features);
    };

    return (
        <>
            {transformRequest ? (
                <ReactMapGL
                    ref={mapRef}
                    initialViewState={viewport}
                    transformRequest={transformRequest}
                    mapStyle={
                        !props.isDarkTheme
                            ? 'mapbox://styles/nefray/cl77hjjwl000c14nzm1ll2tqq'
                            : 'mapbox://styles/nefray/cl70c9r3h000f14qc9zh001pg'
                    }
                    mapboxAccessToken={mapboxToken}
                    maxZoom={18}
                    cursor={cursor}
                    interactiveLayerIds={globalStore.layers}
                    onViewportChange={setViewport}
                    onClick={onClickHandler}
                    onMouseEnter={onMouseEnterHandler}
                    onMouseLeave={onMouseLeaveHandler}
                >
                    {popupContent?.contentInfo && (
                        <Popup
                            longitude={popupContent.lngLat[0]}
                            latitude={popupContent.lngLat[1]}
                            offset={(popupContent.id === 'bus') | (popupContent.id === 'restaurants') ? 10 : 40}
                            onClose={() => setPopupContent(null)}
                            focusAfterOpen={false}
                        >
                            <MapPopup popupContent={popupContent} />
                        </Popup>
                    )}
                    {busGeoJson?.toggled && busLayer()}
                    {scootersGeoJson?.toggled && scootersLayer()}
                    {restaurantsGeoJson?.toggled && restaurantsLayer()}

                    <div>
                        <NavigationControl position="top-left" showCompass={false} style={{ left: 10, top: 10 }} />
                    </div>
                </ReactMapGL>
            ) : (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Spinner />
                </div>
            )}
        </>
    );
};

export default Map;
