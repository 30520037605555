import React, { useState, useEffect, useContext } from 'react';
import UserSettingsContext from '../../../context/userSettingsContext';
import { Button, Collapse, Dialog, Icon } from '@blueprintjs/core';
import './ManageBackgroundScreen.scss';
import { useStore } from '../../store/useStore';
import img1 from '../../assets/images/BratislavaAtNight.jpg';
import img2 from '../../assets/images/BratislavaKamzikSunset.jpg';



const ManageBackgroundScreen = ({ isDarkTheme }) => {
    //ENDPOINTS
    const imageUploadEndpoints = {
        getSignedUrl: `https://1b6assto53.execute-api.eu-west-1.amazonaws.com/dev/getsignedurl`,
        saveBackground: `https://1b6assto53.execute-api.eu-west-1.amazonaws.com/dev/savebackground`,
        getBackgrounds: `https://1b6assto53.execute-api.eu-west-1.amazonaws.com/dev/getbackgrounds`,
        deleteBackground: `https://1b6assto53.execute-api.eu-west-1.amazonaws.com/dev/deletebackground`,
    };

    const userSettingsEndpoints = {
        getUserSettings: `https://25uhu3mgnf.execute-api.eu-west-1.amazonaws.com/dev/getusersettings`,
    };



    //GLOBAL STATE
    const isGlobalUser = useStore()[0].userSettings.isGlobal;
    const { userSettings, changeUserSettings } = useContext(UserSettingsContext);
    const { activeBackgrounds, backgroundRotationTime, email } = userSettings;



    //GET ALL BACKGROUNDS
    const [backgrounds, setBackgrounds] = useState(null);

    useEffect(() => {
        isGlobalUser
        ?
        fetch(imageUploadEndpoints.getBackgrounds, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({user: email})
        })
            .then((res) => res.json())
            .then((data) => {
                if (data && data.error) {
                    console.log('Fetching backgrounds failed: ', data.error);
                    setBackgrounds(null);
                } else {
                    setBackgrounds([...data.backgrounds]);

                    //run this in case sm1 deleted bgs that were in userSettings.activeBackgrounds...
                    let activeBgs = [...activeBackgrounds];
                    let allBgs = [...data.backgrounds];
                    let resultBgs = [];
                    allBgs.forEach(item => {
                        activeBgs.forEach(activeBg => {
                            if (activeBg.id === item.id) resultBgs.push(activeBg);
                        })
                    });
                    changeUserSettings({activeBackgrounds: resultBgs})
                }
            })
            .catch((err) => {
                console.log(err);
            })
        :
        setBackgrounds([{imageUrl: img1, id: 'img1'}, {imageUrl: img2, id: 'img2'}]);
    }, [email]);



    //ADD BACKGROUND
    const [formDisabled, setFormDisabled] = useState(false);
    const [uploadMessage, setUploadMessage] = useState('');

    const readFile = () => {
        //disable button
        if (formDisabled) return;
        setFormDisabled(true);

        //get imageInput html el.
        let imageInput = document.getElementById('imageInput');
        if (!imageInput.files.length) return;

        //read input file
        let reader = new FileReader();
        reader.onload = (e) => {
            //verify if file is an image
            if (!e.target.result.includes('data:image/')) {
                setUploadMessage('Please upload an image file');
                setFormDisabled(false);
                setTimeout(() => {
                    setUploadMessage('');
                }, 2000);
                return;
            }

            //trigger file upload
            uploadImage(e.target.result);
        };

        reader.readAsDataURL(imageInput.files[0]);
    };

    const uploadImage = (image) => {
        setUploadMessage('Uploading image...');
        if (!email) {
            setUploadMessage('Only authorized user can upload backgrounds');
            setTimeout(() => {
                setFormDisabled(false);
                setUploadMessage('');
            }, 2000);
            return;
        }

        //get pre-signed url to aws s3 bucket
        fetch(imageUploadEndpoints.getSignedUrl, { method: 'GET' })
            .then((response) => response.json())
            .then((data) => {
                if (!data || !data.url) {
                    setUploadMessage('Getting pre-signed link failed :(');
                    setTimeout(() => {
                        setFormDisabled(false);
                        setUploadMessage('');
                    }, 2000);
                    return;
                }
                //do some convert-image-to-base64 magic
                setUploadMessage('Converting image...');
                let binary = atob(image.split(',')[1]);
                let array = [];
                for (var i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i));
                }
                let blobData = new Blob([new Uint8Array(array)], { type: 'image/png' });
                let objectUrl = data.url.split('?')[0];

                //use the pre-signed aws s3 bucket url to save the image
                fetch(data.url, { method: 'PUT', body: blobData })
                    .then((response) => {
                        //save imageUrl to db
                        setUploadMessage('Saving image...');
                        fetch(imageUploadEndpoints.saveBackground, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ user: email, imageUrl: objectUrl }),
                        })
                            .then((response) => {
                                return response.json();
                            })
                            .then((data) => {
                                if (data.error) {
                                    console.log(data.error);
                                    setUploadMessage(JSON.stringify(data.error));
                                    setTimeout(() => {
                                        setFormDisabled(false);
                                        setUploadMessage('');
                                    }, 2000);
                                }
                                setUploadMessage('Image uploaded');
                                setTimeout(() => {
                                    setFormDisabled(false);
                                    setUploadMessage('');
                                }, 2000);
                                setBackgrounds([...backgrounds, data]);
                            })
                            .catch((error) => {
                                console.log(error);
                                setUploadMessage('Image upload failed :(');
                                setTimeout(() => {
                                    setFormDisabled(false);
                                    setUploadMessage('');
                                }, 2000);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        setUploadMessage(JSON.stringify(error));
                        setTimeout(() => {
                            setFormDisabled(false);
                            setUploadMessage('');
                        }, 2000);
                    });
            })
            .catch((error) => {
                console.log(error);
                setUploadMessage(JSON.stringify(error));
                setTimeout(() => {
                    setFormDisabled(false);
                    setUploadMessage('');
                }, 2000);
            });
    };



    //(DE)ACTIVATE BG
    const [updateMessage, setUpdateMessage] = useState('');
    const [updateDisabled, setUpdateDisabled] = useState(false);
    
    const isBgActive = bgId => {
        let bgIdx = activeBackgrounds.findIndex(item => item.id === bgId);
        if (bgIdx === -1) return false;
        return true
    }

    const changeIsActive = (background) => {
        if (updateDisabled) return;
        setUpdateDisabled(true);
        setUpdateMessage('Updating status...');

        let activeBgsBefore = [...activeBackgrounds];
        let bgIdx = activeBgsBefore.findIndex(item => item.id === background.id);
        bgIdx === -1 ? activeBgsBefore.push(background) : activeBgsBefore.splice(bgIdx, 1);
        changeUserSettings({activeBackgrounds: activeBgsBefore});

        setUpdateDisabled(false);
        setUpdateMessage('');
    };



    //DELETE BACKGROUND
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [toDeleteBgId, setToDeleteBgId] = useState(null);
    const [deleteMessage, setDeleteMessage] = useState('');

    const deleteBackground = async (backgroundId) => {
        if (updateDisabled) return;
        setUpdateDisabled(true);
        setToDeleteBgId(null);
        setDeleteConfirmOpen(false);
        setDeleteMessage('Deleting background...');

        let res = await fetch(imageUploadEndpoints.deleteBackground, {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({id: backgroundId, user: email})
        })
        if (!res.ok) {
            setDeleteMessage('Deletion failed');
            setTimeout(() => {setDeleteMessage('')}, 2000)
        } else {
            setDeleteMessage('Background deleted');
            setTimeout(() => {setDeleteMessage('')}, 2000);
            let activeBgsBefore = [...activeBackgrounds];
            activeBgsBefore = activeBgsBefore.filter(item => item.id !== backgroundId);
            changeUserSettings({activeBackgrounds: activeBgsBefore});

            let bgsBefore = [...backgrounds];
            bgsBefore = bgsBefore.filter(item => item.id !== backgroundId);
            setBackgrounds(bgsBefore);
        }
        setUpdateDisabled(false);
    };

    

    //RENDERERS
    const renderBgListItem = (item) => {
        return (
            <div style={{ margin: '1rem', display: 'flex', position: 'relative' }} key={item.id}>
                <img
                    src={item.imageUrl}
                    width={260}
                    height={150}
                    style={{
                        borderRadius: '5px',
                        marginRight: '0.5rem',
                        boxShadow: isDarkTheme ? '0 0 10px silver' : '0 0 10px #777',
                    }}
                    alt="Image deleted"
                />

                <div
                    className="background-info-layover"
                    style={{
                        position: 'absolute',
                        width: '260px',
                        height: '100%',
                        background: 'rgba(0, 0, 0, 0.7)',
                        borderRadius: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <p style={{ border: 0, padding: 0, color: 'white' }}>
                        {' '}
                        <b>{isBgActive(item.id) ? 'Active' : 'Inactive'}</b>{' '}
                    </p>

                    <div>
                        <Button
                            style={{ margin: '5px' }}
                            text={isBgActive(item.id) ? 'Deactivate' : 'Activate'}
                            onClick={() => changeIsActive(item)}
                        ></Button>
                        {isGlobalUser && <Button
                            style={{ margin: '5px' }}
                            text="Delete"
                            onClick={() => {
                                setToDeleteBgId(item.id);
                                setDeleteConfirmOpen(true);
                            }}
                        ></Button>}
                    </div>
                </div>

                <p
                    className="background-image-status-indicator"
                    style={{ border: 0, padding: 0, color: 'white', position: 'absolute', top: '0px', right: '10px' }}
                >
                    {' '}
                    {isBgActive(item.id) ? (
                        <Icon icon="tick" color="green" />
                    ) : (
                        <Icon icon="cross" color="red" />
                    )}{' '}
                </p>
            </div>
        );
    };


    
    //BACKGROUND ROTATION TIME CONTROLS
    const [collapseOpen, setCollapseOpen] = useState(false);
    const [rotationTimeInput, setRotationTimeInput] = useState(backgroundRotationTime);

    const setBackgroundrotationTimeInDB = (newTime) => {
        isGlobalUser &&
            fetch(userSettingsEndpoints.setUserSettings, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ...userSettings, backgroundRotationTime: newTime }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (!data?.id) console.log(`User Settings update in DB failed`);
                })
                .catch((error) => {
                    console.log(`setting backgroundRotationTime error: ${error}`);
                });
    };

    const handleChange = (e) => {
        if (typeof +e.target.value !== 'number') setRotationTimeInput(backgroundRotationTime);
        else if (+e.target.value < 5) setRotationTimeInput(5);
        else setRotationTimeInput(+e.target.value);
    };

    const saveNewRotationTime = (newRotationTime) => {
        changeUserSettings({ backgroundRotationTime: +newRotationTime });
        setBackgroundrotationTimeInDB(+newRotationTime);
        setRotationTimeInput(newRotationTime);
        setCollapseOpen(false);
    };

    //RENDER
    return (
        <div style={{ minHeight: '100%', width: '100%' }}>
            <h2 style={{ padding: 0, margin: 0 }}>BACKGROUND</h2> <br />
            <div style={{ margin: '1rem 0 0 1rem' }}>
                {email && backgroundRotationTime && (
                    <React.Fragment>
                        <Button
                            style={{ width: '260px', marginBottom: '0.5rem' }}
                            onClick={() => setCollapseOpen(!collapseOpen)}
                            text="Change Rotation Time"
                        />

                        <Collapse isOpen={collapseOpen}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '260px',
                                    margin: '0.5rem 0 2rem 0',
                                }}
                            >
                                <p style={{ padding: 0, margin: 0 }}>
                                    {' '}
                                    <b>Rotation Time: </b>{' '}
                                </p>
                                <div>
                                    <input
                                        style={{ textAlign: 'left', border: 'none', width: '6ch' }}
                                        type="number"
                                        min={'5'}
                                        value={rotationTimeInput}
                                        onChange={handleChange}
                                    />{' '}
                                    <span>seconds</span>
                                </div>
                                <div style={{ height: '0.25rem' }} />
                                <Button
                                    text="Save"
                                    onClick={() => {
                                        saveNewRotationTime(rotationTimeInput);
                                    }}
                                />
                            </div>
                        </Collapse>

                        {isGlobalUser && <Button style={{ width: '260px' }}>
                            <label className="bp4-file-input .modifier" htmlFor="imageInput">
                                <input
                                    disabled={formDisabled}
                                    type="file"
                                    id="imageInput"
                                    name="imageInput"
                                    onChange={readFile}
                                    hidden
                                />
                                <span className="bp4-file-upload-input">Upload a new background...</span>
                            </label>
                        </Button>}
                    </React.Fragment>
                )}
                {uploadMessage && <span style={{ marginLeft: '1rem', color: '#db3737' }}>{uploadMessage}</span>}
            </div>
            <div style={{ height: '2rem' }} />
            <div>
                <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                    {backgrounds ? (
                        backgrounds.map((item) => renderBgListItem(item))
                    ) : (
                        <p style={{ marginTop: '1rem' }}>No backgrounds</p>
                    )}
                </div>

                {updateMessage && <p style={{ marginLeft: '1rem', color: '#db3737' }}>{updateMessage}</p>}
                {deleteMessage && <span style={{ marginLeft: '1rem', color: '#db3737' }}>{deleteMessage}</span>}
            </div>
            <Dialog
                isOpen={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
                style={{ background: '#db3737', color: 'white' }}
            >
                <div
                    style={{
                        marginTop: '1.25rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <p style={{ textAlign: 'center', padding: 0, margin: 0 }}>
                        Are you sure you want to delete this background?
                    </p>
                    <div style={{ marginTop: '1rem' }}>
                        <span
                            onClick={() => deleteBackground(toDeleteBgId)}
                            style={{ margin: '1rem 1rem 1rem 0rem', cursor: 'pointer' }}
                        >
                            DELETE
                        </span>
                        <span
                            onClick={() => {
                                setToDeleteBgId(null);
                                setDeleteConfirmOpen(false);
                            }}
                            style={{ margin: '1rem 1rem 1rem 0rem', cursor: 'pointer' }}
                        >
                            CANCEL
                        </span>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ManageBackgroundScreen;
