import React, { useEffect } from 'react';
import './App.scss';

const IndexAdmin = ({ children }) => {
    useEffect(() => {
        const body = document.getElementsByTagName('body');
        body[0].style.margin = '0';
        body[0].style.backgroundColor = '#f2f2f2';
        body[0].style.fontFamily = 'GT-Walsheim-Pro-Regular, Arial';
        body[0].style.fontSize = '14px';
        body[0].style.lineHeight = '22px';
    }, []);

    return <div className="adminAppRoot">{children}</div>;
};

export default IndexAdmin;
