import { Card, Elevation } from '@blueprintjs/core';
import React from 'react';

function FullCenterView(props) {
    return (
        <div className="floor-full-center-view">
            <Card className="floor-full-center-view-card" elevation={Elevation.THREE}>
                {props.children}
            </Card>
        </div>
    );
}

export default FullCenterView;
