import { useState } from 'react';
import useDateTime from '../../floor-app/components/hooks/useDateTime';

const useMapEventHandler = function () {
    const [popupContent, setPopupContent] = useState(null);

    let dateTime = useDateTime();

    const calcDiff = function (start, end) {
        start = start.split(':');
        end = end.split(':');
        let startDate = new Date(0, 0, 0, start[0], start[1], 0);
        let endDate = new Date(0, 0, 0, end[0], end[1], 0);
        let diff = endDate.getTime() - startDate.getTime();
        let hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        let minutes = Math.floor(diff / 1000 / 60);

        if (hours < 0) hours = hours + 24;

        return minutes;
    };

    const fetchData = async function (e) {
        if (e[0]?.layer.id === 'bus') {
            setPopupContent(null);
            const response = await fetch(
                `https://transit.land/api/v2/rest/stops/${e[0]?.properties['onestop_id']}/departures?apikey=imBBSqKPA8WUqbU8z9I6B82OMhP0Bb0w`,
            );
            if (!response.ok) return console.log('error');
            const data = await response.json();

            // We have to set departures with data.stops.length - 1 because in an event of data containing 2 objects only the second one will have valid deparures.
            const departures = data.stops[data.stops.length - 1]?.departures.map((departure) => {
                return {
                    departureId: departure.trip.route['route_short_name'],
                    departureTime: calcDiff(dateTime.time, departure['departure_time']),
                    headedTo: departure.trip['trip_headsign'],
                };
            });

            setPopupContent({
                contentInfo: departures,
                lngLat: [...e[0]?.geometry.coordinates],
                id: e[0].layer.id,
            });
        }

        if (e[0]?.layer.id === 'scooters') {
            setPopupContent(null);
            const contentInfo = {
                contentInfo: { ...e[0].properties },
                lngLat: [...e[0]?.geometry.coordinates],
                id: e[0].layer.id,
            };
            setPopupContent(contentInfo);
        }

        if (e[0]?.layer.id === 'restaurants') {
            setPopupContent(null);
            const response = await fetch(
                `https://api.foursquare.com/v3/places/${e[0].properties.id}?fields=hours%2Crating%2Cdescription%2Ctel%2Cemail%2Cwebsite`,
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: 'fsq333oTJYg//ip1yO+Sm0kQ8MzY++cX8eF4uHlNeWvPEc8=',
                    },
                },
            );
            const data = await response.json();

            const contentInfo = {
                contentInfo: { ...e[0].properties, ...data },
                lngLat: [...e[0]?.geometry.coordinates],
                id: e[0].layer.id,
            };
            setPopupContent(contentInfo);
        }
    };
    return { fetchData, popupContent, setPopupContent };
};

export default useMapEventHandler;
