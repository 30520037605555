import React, { useContext, useEffect, useState } from 'react';
import UserSettingsContext from '../../../context/userSettingsContext';
import { Card, Elevation  } from '@blueprintjs/core';
import renderHTML from 'react-render-html';



const NoteWidget = (props) => {
    //SOME STUFF THAT MAKES WIDGET WORK
    let widgetStyle = { backgroundColor: 'var(--app-black-transparent)', color: 'white' };
    if (Object.keys(props).length > 1) {
        widgetStyle = {
            gridColumnStart: props.positionx,
            gridColumnEnd: props.positionx + props.sizex,
            gridRowStart: props.positiony,
            gridRowEnd: props.positiony + props.sizey,
            overflowY: 'scroll',
            flexDirection: 'column',
        };
    } else {
        widgetStyle = {
            visibility: 'hidden',
        };
    }



    //GET ACTIVE NOTE
    const { userSettings } = useContext(UserSettingsContext);
    const { activeNote : note } = userSettings;
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (!note?.id) {
            setMessage('No note is active')
        } else {
            setMessage('');
        }
    }, [note])



    //RENDER
    return (
        <Card className="floor-widget" elevation={Elevation.THREE} style={widgetStyle}>
            <div style={{width: '100%', height: '100%', position: 'relative'}}>
                {message && <p style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>{message}</p>}
                {
                    note && !message
                    &&
                    <React.Fragment>
                        <h1 style={{textAlign: 'center', color: 'var(--dxc-purple2)', padding: '0', margin: '0 0 1rem 0'}}>{note.title}</h1>
                        <section className="react-quill-wrapper" style={{textAlign: 'left', paddingBottom: '0.25rem'}}>
                            {renderHTML(note.body)}
                        </section>
                    </React.Fragment>
                }
            </div>
        </Card>
    )
}

export default NoteWidget