import React, { useContext, useState, useEffect } from 'react';
import UserSettingsContext from '../../context/userSettingsContext';
import Widget from './widgets/Widget';
import WidgetRSS from './widgets/WidgetRSS';
import WidgetPerformance from './widgets/WidgetPerformance';
import NoteWidget from './widgets/NoteWidget';
import SampleWidget1 from './widgets/SampleWidget1';
import SampleWidget2 from './widgets/SampleWidget2';
import SampleWidget3 from './widgets/SampleWidget3';

const widgetGridStyle = {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto auto auto',
    gridTemplateRows: 'auto auto auto',
    gridGap: '20px',
    paddingTop: '15px',
};

function WidgetGrid() {
    //WIDGETS
    const { userSettings } = useContext(UserSettingsContext);
    const { widgets } = userSettings;
    const [displayedWidgets, setDisplayedWidgets] = useState([]);

    useEffect(() => {
        //push jsx component with position & size props into array of active widgets that comes from ManageWidgetsScreen.js [{name: "Sample Widget 1", positionX: 6, positionY: 1, sizeX: 1, sizeY: 1}, ...]
        let activeWidgets = {};
        if (widgets) {
            widgets.forEach((widget) => {
                activeWidgets[widget.name] = { ...widget };
            });
        }
        Object.keys(activeWidgets).forEach((key) => {
            if (key === 'Performance Widget')
                activeWidgets['Performance Widget'].component = (
                    <WidgetPerformance
                        text={activeWidgets['Performance Widget'].name}
                        positionx={activeWidgets['Performance Widget'].positionX}
                        positiony={activeWidgets['Performance Widget'].positionY}
                        sizex={activeWidgets['Performance Widget'].sizeX}
                        sizey={activeWidgets['Performance Widget'].sizeY}
                        key={`widget ${activeWidgets[key].name}`}
                    />
                );
            if (key === 'News Widget')
                activeWidgets['News Widget'].component = (
                    <WidgetRSS
                        text={activeWidgets['News Widget'].name}
                        positionx={activeWidgets['News Widget'].positionX}
                        positiony={activeWidgets['News Widget'].positionY}
                        sizex={activeWidgets['News Widget'].sizeX}
                        sizey={activeWidgets['News Widget'].sizeY}
                        key={`widget ${activeWidgets[key].name}`}
                    />
                );
            if (key === 'Note Widget')
                activeWidgets['Note Widget'].component = (
                    <NoteWidget
                        text={activeWidgets['Note Widget'].name}
                        positionx={activeWidgets['Note Widget'].positionX}
                        positiony={activeWidgets['Note Widget'].positionY}
                        sizex={activeWidgets['Note Widget'].sizeX}
                        sizey={activeWidgets['Note Widget'].sizeY}
                        key={`widget ${activeWidgets[key].name}`}
                    />
                );
            if (key === 'Sample Widget 1')
                activeWidgets['Sample Widget 1'].component = (
                    <SampleWidget1
                        text={activeWidgets['Sample Widget 1'].name}
                        positionx={activeWidgets['Sample Widget 1'].positionX}
                        positiony={activeWidgets['Sample Widget 1'].positionY}
                        sizex={activeWidgets['Sample Widget 1'].sizeX}
                        sizey={activeWidgets['Sample Widget 1'].sizeY}
                        key={`widget ${activeWidgets[key].name}`}
                    />
                );
            if (key === 'Sample Widget 2')
                activeWidgets['Sample Widget 2'].component = (
                    <SampleWidget2
                        text={activeWidgets['Sample Widget 2'].name}
                        positionx={activeWidgets['Sample Widget 2'].positionX}
                        positiony={activeWidgets['Sample Widget 2'].positionY}
                        sizex={activeWidgets['Sample Widget 2'].sizeX}
                        sizey={activeWidgets['Sample Widget 2'].sizeY}
                        key={`widget ${activeWidgets[key].name}`}
                    />
                );
            if (key === 'Sample Widget 3')
                activeWidgets['Sample Widget 3'].component = (
                    <SampleWidget3
                        text={activeWidgets['Sample Widget 3'].name}
                        positionx={activeWidgets['Sample Widget 3'].positionX}
                        positiony={activeWidgets['Sample Widget 3'].positionY}
                        sizex={activeWidgets['Sample Widget 3'].sizeX}
                        sizey={activeWidgets['Sample Widget 3'].sizeY}
                        key={`widget ${activeWidgets[key].name}`}
                    />
                );
        });
        let newDisplayedWidgets = [];
        Object.keys(activeWidgets).forEach((key) => {
            newDisplayedWidgets.push(activeWidgets[key]);
        });
        setDisplayedWidgets([...newDisplayedWidgets]);
    }, [widgets]);

    //RENDER
    return (
        <div className="floor-widget-grid" style={widgetGridStyle}>
            {displayedWidgets && displayedWidgets.map((item) => item.component)}

            {/*PLACEHOLDERS*/}
            <Widget text="placeholder 1" positionx={1} positiony={3} sizex={1} sizey={1} />
            <Widget text="placeholder 2" positionx={2} positiony={3} sizex={1} sizey={1} />
            <Widget text="placeholder 3" positionx={3} positiony={3} sizex={1} sizey={1} />
            <Widget text="placeholder 4" positionx={4} positiony={3} sizex={1} sizey={1} />
            <Widget text="placeholder 5" positionx={5} positiony={3} sizex={1} sizey={1} />
            <Widget text="placeholder 6" positionx={6} positiony={1} sizex={1} sizey={1} />
            <Widget text="placeholder 7" positionx={6} positiony={2} sizex={1} sizey={1} />
            <Widget text="placeholder 8" positionx={6} positiony={3} sizex={1} sizey={1} />
        </div>
    );
}

export default WidgetGrid;
