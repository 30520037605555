import { Pre } from '@blueprintjs/core';
import React, { useEffect, useState, useContext, useRef } from 'react';
import FullCenterView from '../components/card-styles/FullCenterView';
import FullCenterViewLeftSide from '../components/card-styles/FullCenterViewLeftSide';
import FullCenterViewRightSide from '../components/card-styles/FullCenterViewRightSide';
import { AreaContext } from '../indexFloor';
import WeatherIcon from '../components/WeatherIcon';
import './Weather.css';






const Weather = ({ isDarkTheme }) => {
    //VALUES
    const [forecast, setForecast] = useState(null);
    const [selectedForecast, setSelectedForecast] = useState(null);
    const [message, setMessage] = useState('No weather data loaded');
    const { areaInfo, setArea } = useContext(AreaContext);
    const getWeatherInterval = useRef();
    const daysAscendingly = useRef();



    //GROUP FORECAST BY DAY
    const groupListByDay = (list) => {
        //parse all dates to weekday string
        let listWithWeekdays = [...list].map(item => {
            let date = new Date(item.dt_txt);
            let weekDay = date.toLocaleDateString('en', { weekday: 'long'});
            return {...item, weekday: weekDay}
        })
    
        //make a list of days and populate it
        let listByDays = {};
        listWithWeekdays.forEach(item => {
            listByDays[`${item.weekday}`] = listByDays[`${item.weekday}`] ? [...listByDays[`${item.weekday}`], item] : [item]
        })

        //return list grouped by days {Monday: [{forecast1}, {forecas2}, Tuesday: [{forecast1}, {forecast2}]]}
        return listByDays  
    }



    //SORT WEEKDAYS ASCENDINGLY
    const sortDaysAscendingly = listByDays => {
        let daysAscendingly = [];
        Object.keys(listByDays).forEach(key => {
            daysAscendingly.push({dt: listByDays[key][0].dt, weekday: listByDays[key][0].weekday})
        })
        daysAscendingly.sort((a, b) => a.dt - b.dt)
        return daysAscendingly;
    }
    


    //GET FORECAST CALL
    const openweathermapApiKey = `c0e5e41b74fb9a8a3210ada8fa62a914`;
    const getForecast = (lat, lon) => {
        console.log('Getting 3-hour weather forecast')
        return fetch(`https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lon}&units=metric&appid=${openweathermapApiKey}`)
            .then(res => {
                return res.json()
            })
            .then((data) => {
                if (data && data.list) {
                    let forecastGroupedByDay = groupListByDay(data.list);
                    daysAscendingly.current = sortDaysAscendingly(forecastGroupedByDay);
                    setForecast(forecastGroupedByDay);

                    //pre-select first forcast item
                    setSelectedForecast(data.list[0]);
                    setMessage('');
                } else {
                    setMessage('Something went wrong. Weather forecast call failed');
                    setForecast(null);
                }
            })
            .catch(error => {
                console.log(error);
                return {error: JSON.stringify(error) || 'Something went wrong. Weather forecast call failed'}
            });
    }

    useEffect(() => {
        if (areaInfo && areaInfo.coordinates) {
            setMessage('Getting forecast...');
            //get forcast data
            getForecast(areaInfo.coordinates[0], areaInfo.coordinates[1]);

            //refresh forecast data every 3 hours
            getWeatherInterval.current = setInterval(() => {
                getForecast(areaInfo.coordinates[0], areaInfo.coordinates[1]);
            }, 3*3600000);
        }

        return () => {
            if (getWeatherInterval.current) clearInterval(getWeatherInterval.current);
        }
    }, [areaInfo]);



    //DATE PARSER
    const parseDate = (dateString) => {
        const date = new Date(dateString);
        const weekDay = date.toLocaleDateString('en', { weekday: 'long' });
        const hour = date.getHours();
        return `${weekDay} ${hour}:00`;
    };



    //RENDERERS
    const weekdayWeatherCard = weekday => {
        if (!forecast) return console.log('forecast not populated. Rendering stopped.');
        if (!forecast[weekday]) return console.log(`${weekday} not found in forecast. Rendering stopped.`);

        return (
            <div className='weekday-weather-box'>
                <h3 className='three-hour-weather-day-name'>{weekday}</h3>
                {
                    forecast[weekday].map(item => (
                        <div className='three-hour-weather-box' key={item.dt_txt} onClick={(e) => setSelectedForecast(item)} style={{backgroundColor: isDarkTheme ? 'rgba(255, 255, 255, 0.125)' : 'rgba(0, 0, 0, 0.125)'}}>
                            <p className='three-hour-weather-time'>{parseDate(item.dt_txt).split(' ')[1]}</p>
                            <h2 className='three-hour-weather-icon'> <WeatherIcon iconCode={item.weather[0].icon} /> </h2>
                            <p className='three-hour-weather-temp'>{item.main.temp.toFixed(0)}°C</p>
                        </div>
                    ))
                }
            </div>
        )
    }

    const selectedWeatherCard = (weatherItem) => {
        return (
            <main className="selected-weather-item-card">
                <h3 className="selected-weather-item-city">BRATISLAVA</h3>
                <p className="selected-weather-item-date">{parseDate(weatherItem.dt_txt)}</p>
                <div className="selected-weather-item-hero">
                    <h2 className='selected-weather-item-icon'> <WeatherIcon iconCode={weatherItem.weather[0].icon} /> </h2>
                    <div className="selected-weather-item-hero-text">
                        <p className="selected-weather-item-temp">{weatherItem.main.temp.toFixed(0)}°C</p>
                        <p className="selected-weather-item-desc">{weatherItem.weather[0].description}</p>
                    </div>
                </div>
                <div className="selected-weather-item-details">
                    <div>
                        {' '}
                        <b>Feels like: </b> <span>{weatherItem.main.feels_like.toFixed(0)}°C</span>
                    </div>
                    <div>
                        {' '}
                        <b>Min:</b> <span>{weatherItem.main.temp_min.toFixed(0)}°C</span>
                    </div>
                    <div>
                        {' '}
                        <b>Max:</b> <span>{weatherItem.main.temp_max.toFixed(0)}°C</span>
                    </div>
                    <div>
                        {' '}
                        <b>Humidity:</b> <span>{weatherItem.main.humidity}%</span>
                    </div>
                    <div>
                        {' '}
                        <b>Wind:</b> <span>{weatherItem.wind.speed} km/h</span>
                    </div>
                </div>
            </main>
        );
    };



    //RENDER
    return (
        <div className="floor-weather">
            {!forecast ? (
                <FullCenterView>
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <h2>{message}</h2>
                    </div>
                </FullCenterView>
            ) : (
                <div style={isDarkTheme ? {opacity: 0.6} : {opacity: 0.7}}>
                    <FullCenterView>
                        <FullCenterViewLeftSide>
                            {selectedForecast && selectedWeatherCard(selectedForecast)}
                        </FullCenterViewLeftSide>

                        <FullCenterViewRightSide>
                            <div className='day-boxes-container' style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%', overflowY: 'auto'}}>
                                {daysAscendingly.current && weekdayWeatherCard(daysAscendingly.current[0].weekday)}                                
                                {daysAscendingly.current && weekdayWeatherCard(daysAscendingly.current[1].weekday)}                                
                                {daysAscendingly.current && weekdayWeatherCard(daysAscendingly.current[2].weekday)}                                                                                                                         
                            </div>
                        </FullCenterViewRightSide>
                    </FullCenterView>
                </div>
            )}
        </div>
    );
};

export default Weather;



/*
import { Pre } from '@blueprintjs/core';
import React, { useEffect, useState, useContext, useRef } from 'react';
import FullCenterView from '../components/card-styles/FullCenterView';
import FullCenterViewLeftSide from '../components/card-styles/FullCenterViewLeftSide';
import FullCenterViewRightSide from '../components/card-styles/FullCenterViewRightSide';
import { AreaContext } from '../indexFloor';
import WeatherIcon from '../components/WeatherIcon';
import './Weather.css';






const Weather = ({ isDarkTheme }) => {
    //VALUES
    const [forecast, setForecast] = useState(null);
    const [selectedForecast, setSelectedForecast] = useState(null);
    const [message, setMessage] = useState('No weather data loaded');
    const { areaInfo, setArea } = useContext(AreaContext);
    const getWeatherInterval = useRef();
    


    //GET FORECAST CALL
    const openweathermapApiKey = `c0e5e41b74fb9a8a3210ada8fa62a914`;
    const getForecast = (lat, lon) => {
        console.log('Getting 3-hour weather forecast')
        return fetch(`https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lon}&units=metric&appid=${openweathermapApiKey}`)
            .then(res => {
                return res.json()
            })
            .then((data) => {
                if (data && data.list) {
                    setForecast(data.list);
                    //pre-select first forcast item
                    setSelectedForecast(data.list[0]);
                    setMessage('');
                } else {
                    setMessage('Something went wrong. Weather forecast call failed');
                    setForecast(null);
                }
            })
            .catch(error => {
                console.log(error);
                return {error: JSON.stringify(error) || 'Something went wrong. Weather forecast call failed'}
            });
    }

    useEffect(() => {
        if (areaInfo && areaInfo.coordinates) {
            setMessage('Getting forecast...');
            //get forcast data
            getForecast(areaInfo.coordinates[0], areaInfo.coordinates[1]);

            //refresh forecast data every 3 hours
            getWeatherInterval.current = setInterval(() => {
                getForecast(areaInfo.coordinates[0], areaInfo.coordinates[1]);
            }, 3*3600000);
        }

        return () => {
            if (getWeatherInterval.current) clearInterval(getWeatherInterval.current);
        }
    }, [areaInfo]);



    //DATE PARSER
    const parseDate = (dateString) => {
        const date = new Date(dateString);
        const weekDay = date.toLocaleDateString('en', { weekday: 'long' });
        const hour = date.getHours();
        return `${weekDay} ${hour}:00`;
    };



    //RENDERERS
    const weatherPreviewCard = (weatherItem) => {
        return (
            <div style={isDarkTheme ? {background: '#212121'} : {background: '#fff'}} className="weather-item" key={weatherItem.dt_txt} onClick={() => setSelectedForecast(weatherItem)}>
                <p>{parseDate(weatherItem.dt_txt)}</p>
                <div>
                    <h2 className='weather-item-icon'> <WeatherIcon iconCode={weatherItem.weather[0].icon} /> </h2>
                </div>
                <p>{weatherItem.main.temp.toFixed(0)}°C</p>
                <p>{weatherItem.weather[0].description}</p>
            </div>
        );
    };

    const selectedWeatherCard = (weatherItem) => {
        return (
            <main className="selected-weather-item-card">
                <h3 className="selected-weather-item-city">BRATISLAVA</h3>
                <p className="selected-weather-item-date">{parseDate(weatherItem.dt_txt)}</p>
                <div className="selected-weather-item-hero">
                    <h2 className='selected-weather-item-icon'> <WeatherIcon iconCode={weatherItem.weather[0].icon} /> </h2>
                    <div className="selected-weather-item-hero-text">
                        <p className="selected-weather-item-temp">{weatherItem.main.temp.toFixed(0)}°C</p>
                        <p className="selected-weather-item-desc">{weatherItem.weather[0].description}</p>
                    </div>
                </div>
                <div className="selected-weather-item-details">
                    <div>
                        {' '}
                        <b>Feels like: </b> <span>{weatherItem.main.feels_like.toFixed(0)}°C</span>
                    </div>
                    <div>
                        {' '}
                        <b>Min:</b> <span>{weatherItem.main.temp_min.toFixed(0)}°C</span>
                    </div>
                    <div>
                        {' '}
                        <b>Max:</b> <span>{weatherItem.main.temp_max.toFixed(0)}°C</span>
                    </div>
                    <div>
                        {' '}
                        <b>Humidity:</b> <span>{weatherItem.main.humidity}%</span>
                    </div>
                    <div>
                        {' '}
                        <b>Wind:</b> <span>{weatherItem.wind.speed} km/h</span>
                    </div>
                </div>
            </main>
        );
    };



    //RENDER
    return (
        <div className="floor-weather">
            {!forecast ? (
                <FullCenterView>
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <h2>{message}</h2>
                    </div>
                </FullCenterView>
            ) : (
                <div style={isDarkTheme ? {opacity: 0.6} : {opacity: 0.7}}>
                    <FullCenterView>
                        <FullCenterViewLeftSide>
                            {selectedForecast && selectedWeatherCard(selectedForecast)}
                        </FullCenterViewLeftSide>

                        <FullCenterViewRightSide>
                            <h2>3-HOUR FORECAST:</h2>
                            <small>Click item to view details</small>
                            <section className="weather-list">
                                {forecast.map((weatherItem) => weatherPreviewCard(weatherItem))}
                            </section>
                        </FullCenterViewRightSide>
                    </FullCenterView>
                </div>
            )}
        </div>
    );
};

export default Weather;
*/