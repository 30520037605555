import { Card, Elevation } from '@blueprintjs/core';
import ProgressBar from 'progressbar.js';
import { useEffect } from 'react';
import './WidgetPerformance.scss';

const getRandomNumber = function (min, max) {
    return Math.random() * (max - min) + min;
};

const WidgetPerformance = function (props) {
    let widgetStyle = {};
    if (Object.keys(props).length > 1) {
        widgetStyle = {
            gridColumnStart: props.positionx,
            gridColumnEnd: props.positionx + props.sizex,
            gridRowStart: props.positiony,
            gridRowEnd: props.positiony + props.sizey,
            flexDirection: 'column',
        };
    } else {
        widgetStyle = {
            visibility: 'hidden',
        };
    }

    useEffect(() => {
        const circleBarOne = new ProgressBar.Circle('#circle-container-one', {
            strokeWidth: 2,
            trailWidth: 25,
            trailColor: 'transparent',
            easing: 'linear',
            from: { color: '#000000', width: 1 },
            to: { color: '#7a53a4', width: 25 },
            text: {
                value: '0',
                className: 'progress-text',
                style: {
                    color: 'inherit',
                    position: 'relative',
                    top: '-65%',
                    padding: 0,
                    margin: '0 auto',
                    transform: null,
                },
            },
            step: (state, shape) => {
                shape.path.setAttribute('stroke', state.color);
                shape.path.setAttribute('stroke-width', state.width);
                shape.setText(Math.round(shape.value() * 100) + '%');
            },
        });
        const circleBarTwo = new ProgressBar.Circle('#circle-container-two', {
            strokeWidth: 2,
            trailWidth: 25,
            trailColor: 'transparent',
            easing: 'linear',
            from: { color: '#000000', width: 1 },
            to: { color: '#7a53a4', width: 25 },
            text: {
                value: '0',
                className: 'progress-text',
                style: {
                    color: 'inherit',
                    position: 'relative',
                    top: '-65%',
                    padding: 0,
                    margin: '0 auto',
                    transform: null,
                },
            },
            step: (state, shape) => {
                shape.path.setAttribute('stroke', state.color);
                shape.path.setAttribute('stroke-width', state.width);
                shape.setText(Math.round(shape.value() * 100) + '%');
            },
        });
        const semiBarOne = new ProgressBar.SemiCircle('#semi-container-one', {
            strokeWidth: 2,
            trailWidth: 15,
            trailColor: 'transparent',
            easing: 'bounce',
            from: { color: '#000000', width: 2 },
            to: { color: '#7a53a4', width: 3 },
            text: {
                value: '0',
                className: 'progress-text',
                style: {
                    color: 'inherit',
                    position: 'absolute',
                    left: '50%',
                    padding: 0,
                    margin: '0 auto',
                    transform: null,
                },
            },
            step: (state, shape) => {
                shape.path.setAttribute('stroke', state.color);
                shape.path.setAttribute('stroke-width', state.width);
                shape.setText(Math.round(shape.value() * 100) + '%');
            },
        });
        const semiBarTwo = new ProgressBar.SemiCircle('#semi-container-two', {
            strokeWidth: 2,
            trailWidth: 15,
            trailColor: 'transparent',
            easing: 'bounce',
            from: { color: '#000000', width: 2 },
            to: { color: '#7a53a4', width: 3 },
            text: {
                value: '0',
                className: 'progress-text',
                style: {
                    color: 'inherit',
                    position: 'absolute',
                    left: '50%',
                    padding: 0,
                    margin: '0 auto',
                    transform: null,
                },
            },
            step: (state, shape) => {
                shape.path.setAttribute('stroke', state.color);
                shape.path.setAttribute('stroke-width', state.width);
                shape.setText(Math.round(shape.value() * 100) + '%');
            },
        });

        const animateInterval = setInterval(() => {
            circleBarOne.animate(getRandomNumber(0.65, 0.5), {
                duration: 1000,
            });
            circleBarTwo.animate(getRandomNumber(0.75, 0.6), {
                duration: 1000,
            });
            semiBarOne.animate(getRandomNumber(0.6, 0.5), {
                duration: 800,
            });
            semiBarTwo.animate(getRandomNumber(0.7, 0.65), {
                duration: 800,
            });
        }, 1000);

        return () => clearInterval(animateInterval);
    }, []);

    return (
        <Card className="floor-widget" elevation={Elevation.THREE} style={widgetStyle}>
            <div className="performance-block">
                <b style={{ marginBottom: '30px' }}>Floor: 2 Servers Performance</b>
                <div className="performance-server">
                    <div className="performance-item">
                        <div>Server 1 CPU:</div>
                        <div id="circle-container-one" />
                    </div>
                    <div className="performance-item">
                        <div>Server 1 RAM:</div>
                        <div id="semi-container-one" />
                    </div>
                </div>
                <div className="performance-server">
                    <div className="performance-item">
                        <div>Server 2 CPU:</div>
                        <div id="circle-container-two" />
                    </div>
                    <div className="performance-item">
                        <div>Server 2 RAM:</div>
                        <div id="semi-container-two" />
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default WidgetPerformance;
