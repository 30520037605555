import { Auth } from 'aws-amplify';

export async function getUserData() {
    return new Promise(function (resolve, reject) {
        Auth.currentAuthenticatedUser()
            .then(async (data) => {
                //This checks if user is signed in.
                if (data) {
                    resolve(data);
                }
            })
            .catch((e) => {
                reject('NOT_SIGNED_IN');
            });
    });
}

export async function signInUser(username, password) {
    return new Promise(function (resolve, reject) {
        Auth.signIn(username, password)
            .then((user) => {
                resolve(user);
            })
            .catch((e) => {
                reject(e);
            });
    });
}

export async function signOutUser() {
    return new Promise(function (resolve, reject) {
        Auth.signOut()
            .then(() => {
                resolve();
            })
            .catch((e) => {
                reject(e);
            });
    });
}

export function isAdmin(user) {
    let userGroup = user.signInUserSession.accessToken.payload['cognito:groups'];
    return userGroup != null && userGroup.length > 0 && userGroup[0] === 'admins';
}

export function isGod(user) {
    let userGroup = user.signInUserSession.accessToken.payload['cognito:groups'];
    return userGroup != null && userGroup.length > 0 && userGroup[0] === 'god';
}

export function isFloor(user) {
    let userGroup = user.signInUserSession.accessToken.payload['cognito:groups'];
    return userGroup != null && userGroup.length > 0 && userGroup[0] === 'smartscreens';
}
