import React from 'react';
import PanelCard from './PanelCard';




const UsersPanel = () => {
  return (
    <PanelCard>
        <p style={{textAlign: 'center'}}>User Panel</p>

    </PanelCard>
  )
}

export default UsersPanel