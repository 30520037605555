import React from 'react';
import './style.css';
import { InputGroup } from '@blueprintjs/core';

const TextInput = (props) => {
    let className = props.has_error
        ? `styled-text-input incorrect-input ${props.className ? props.className : ''}`
        : `styled-text-input ${props.className ? props.className : ''}`;
    return (
        <>
            {props.has_error ? <div className="input-error-text">{props.error_msg ? props.error_msg : ''}</div> : null}
            <InputGroup {...props} className={className} />
        </>
    );
};

export default TextInput;
